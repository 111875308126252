import React, { useState, useEffect } from 'react';
import ReadOnlyRating from '../../components/general/ReadOnlyRating';
import { Link } from 'react-router-dom';
import useWeb3 from '../../hooks/useWeb3';
import { MdVerified } from 'react-icons/md';
import { BsPatchCheckFill } from 'react-icons/bs';
import { appSettings } from '../../helpers/settings';
import Popup from '../../components/general/Popup';
import VerifyForm from './VerifyForm';
import UpdateDocsForm from './UpdateDocsForm';
import useApp from '../../hooks/useApp';
import { useTranslation } from 'react-i18next';
import useShop from '../../hooks/useShop';
import { toast } from 'react-toastify';
import Web3 from 'web3';
import { useContractWrite } from 'wagmi';
import { waitForTransaction } from '@wagmi/core';
import Integration from '../../integration/TokenAbi.json';

function ShopBanner({
    shopTitle,
    shopId,
    shopOwner,
    shopCover,
    shopCategory,
    shopDescription,
    shopSlug,
    shopRating,
    isVerified,
    shopDocs,
    vip,
}) {
    const { account } = useWeb3();
    const { themeMode, setTransactionLoading } = useApp();
    const [approvedTx, setApprovedTx] = useState(null);
    const { stToken, membershipAbi, membershipContract, loadAllShops, loadUserShops, shopContract, shopVerifyCost } =
        useShop();
    const [verifyRequested, setVerifyRequested] = useState(false);
    const [editRequested, setEditRequested] = useState(false);
    const { t } = useTranslation();

    /* --------------------------------------------- 
              GET GOLD MEMBERSHIP
     --------------------------------------------- */
    const { write: web3GoldMembership } = useContractWrite({
        address: membershipContract?.address,
        abi: membershipAbi,
        functionName: 'addGoldCheckToShop',
        args: [Number(shopId)],
        onSuccess() {
            setTimeout(() => {
                setTransactionLoading(false);
                loadAllShops(shopContract);
                loadUserShops(shopContract, account);
                toast.success(`${t('shopVerifyToast')}`);
            }, 5000);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error(`${t('somethingWentWrong')}`);
        },
    });

    /* --------------------------------------------- 
              APPROVE SENDING TOKEN
     --------------------------------------------- */
    const { write: web3ApproveTransfer, data: txData } = useContractWrite({
        address: stToken?.address,
        abi: Integration,
        functionName: 'approve',

        onSuccess() {
            setTransactionLoading(true);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error(`${t('somethingWentWrong')}`);
        },
    });

    useEffect(() => {
        if (txData) {
            async function getTx() {
                const waitFrTx = await waitForTransaction({
                    hash: txData?.hash,
                });
                setApprovedTx(waitFrTx);
            }

            getTx();
        }
    }, [txData]);

    useEffect(() => {
        if (approvedTx) {
            web3GoldMembership();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [approvedTx]);

    function vipHandler() {
        web3ApproveTransfer({
            recklesslySetUnpreparedArgs: [
                membershipContract.address,
                Web3.utils.toWei(shopVerifyCost.toString(), 'ether'),
            ],
        });
    }

    /* --------------------------------------------- 
          UPDATE PAGE TITLE
    --------------------------------------------- */
    useEffect(() => {
        document.title = `${appSettings.brandName} | ${shopTitle}`;
    }, [shopTitle]);

    return (
        <>
            <header className='page-banner with-bg pb-5' style={{ background: `url(${shopCover})` }}>
                <div className='container page-banner-content'>
                    <div className='row'>
                        <div className='col-lg-7'>
                            <div className='badge bg-success mb-3'>{shopCategory}</div>
                            <h1 className='text-xl'>
                                {shopTitle}
                                {isVerified && !vip && (
                                    <MdVerified className='ms-2 mb-2' size={20} style={{ color: '#649bff' }} />
                                )}
                                {isVerified && vip && <BsPatchCheckFill className='ms-2 mb-2 text-gold' size={20} />}
                            </h1>
                            <p className='text-muted'>{shopDescription}</p>

                            {!isVerified && shopDocs?.certificate && (
                                <div className='bg-danger mb-4 mt-2 mb-3 px-3 py-2 rounded text-white small d-inline-block'>
                                    {t('verificationDocsSubmitted')}
                                    <button
                                        className='btn btn-link p-0 shadow-0 ms-2 text-white text-sm'
                                        onClick={() => setEditRequested(true)}
                                    >
                                        {t('editDocs')}
                                    </button>
                                </div>
                            )}

                            <div className='mb-3'>
                                <ReadOnlyRating rating={shopRating} />
                            </div>
                            {account === shopOwner && (
                                <ul className='list-inline mb-0'>
                                    <li className='list-inline-item m-1'>
                                        <Link to={`/shops/update/${shopSlug}`} className='btn btn-primary mb-1'>
                                            {t('updateYourDocs')}
                                        </Link>
                                    </li>
                                    <li className='list-inline-item m-1'>
                                        <Link
                                            to={`/products/create/${shopSlug}`}
                                            className={`btn ${
                                                themeMode === 'dark' ? 'btn-light bg-white text-dark' : 'btn-dark'
                                            }   mb-1`}
                                        >
                                            {t('uploadProduct')}
                                        </Link>
                                    </li>
                                    {!isVerified && shopDocs?.length === 0 && (
                                        <li className='list-inline-item m-1'>
                                            <button
                                                type='button'
                                                className='btn btn-primary mb-1'
                                                onClick={() => setVerifyRequested(true)}
                                            >
                                                <MdVerified className='me-2 mb-1' size={20} />
                                                {t('verifyYourShop')}
                                            </button>
                                        </li>
                                    )}
                                    {isVerified && !vip && (
                                        <li className='list-inline-item m-1'>
                                            <button type='button' className='btn btn-primary mb-1' onClick={vipHandler}>
                                                <MdVerified className='me-2 mb-1' size={20} />
                                                {t('getGoldMembership')}
                                            </button>
                                        </li>
                                    )}
                                </ul>
                            )}
                        </div>
                    </div>
                </div>
            </header>

            {verifyRequested && (
                <Popup closeModal={setVerifyRequested} containerClass='col-lg-6'>
                    <VerifyForm
                        shopId={shopId}
                        shopTitle={shopTitle}
                        shopCategory={shopCategory}
                        closeModal={() => setVerifyRequested(false)}
                    />
                </Popup>
            )}

            {editRequested && (
                <Popup closeModal={setEditRequested} containerClass='col-lg-6'>
                    <UpdateDocsForm
                        shopId={shopId}
                        shopTitle={shopTitle}
                        docs={shopDocs}
                        closeModal={() => setEditRequested(false)}
                    />
                </Popup>
            )}
        </>
    );
}

export default ShopBanner;
