import React, { useState, useEffect } from 'react';
import PageBanner from '../../components/general/PageBanner';
import InfoGrid from './InfoGrid';
import UpdateInfo from './UpdateInfo';
import { useTranslation } from 'react-i18next';
import useUser from '../../hooks/useUser';
import useShop from '../../hooks/useShop';
import useApp from '../../hooks/useApp';
import { toast } from 'react-toastify';
import { useContractWrite } from 'wagmi';
import { waitForTransaction } from '@wagmi/core';
import Integration from '../../integration/TokenAbi.json';
import Web3 from 'web3';
import useWeb3 from '../../hooks/useWeb3';

function UserProfilePage() {
    const [updateVisible, setUpdateVisible] = useState(false);
    const [approvedTx, setApprovedTx] = useState(null);
    const { account } = useWeb3();
    const { setTransactionLoading } = useApp();
    const { userInfo, userContract, loadUserInfo, loadUsersList } = useUser();
    const { membershipContract, membershipAbi, goldCheckCost, stToken } = useShop();
    const { t } = useTranslation();

    /* --------------------------------------------- 
              GET VIP SUBSCRIPTION
     --------------------------------------------- */
    const { write: web3SetVipMembership } = useContractWrite({
        address: membershipContract?.address,
        abi: membershipAbi,
        functionName: 'addGoldCheckToUser',
        onSuccess() {
            setTimeout(() => {
                setTransactionLoading(false);
                toast.success('Great! you have been successfully subscribed');
                loadUserInfo(userContract, account);
                loadUsersList(userContract);
            }, 5000);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error('Oops! Something went error');
        },
    });

    /* --------------------------------------------- 
              APPROVE SENDING TOKEN
     --------------------------------------------- */
    const { write: web3ApproveTransfer, data: txData } = useContractWrite({
        address: stToken?.address,
        abi: Integration,
        functionName: 'approve',

        onSuccess() {
            setTransactionLoading(true);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error('Oops, Something went wrong!');
        },
    });

    useEffect(() => {
        if (txData) {
            async function getTx() {
                const waitFrTx = await waitForTransaction({
                    hash: txData?.hash,
                });
                setApprovedTx(waitFrTx);
            }

            getTx();
        }
    }, [txData]);

    useEffect(() => {
        if (approvedTx) {
            web3SetVipMembership();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [approvedTx]);

    function vipHandler() {
        web3ApproveTransfer({
            recklesslySetUnpreparedArgs: [
                membershipContract.address,
                Web3.utils.toWei(goldCheckCost.toString(), 'ether'),
            ],
        });
    }

    return (
        <>
            <PageBanner heading={t('myProfile')} isVip={userInfo?.vip}>
                {!userInfo?.vip && (
                    <>
                        <button className='btn btn-primary' onClick={vipHandler}>
                            Get VIP Membership
                        </button>
                        <p className='text-center small mt-2'>
                            Get monthly VIP subscription for{' '}
                            <span className='text-primary'>
                                {goldCheckCost} {stToken?.symbol}
                            </span>
                        </p>
                    </>
                )}
            </PageBanner>
            <section className='pb-5'>
                <div className='container pb-5'>
                    <div className='row'>
                        <div className='col-lg-10 mx-auto'>
                            <div className='row g-4'>
                                {updateVisible ? (
                                    <UpdateInfo finishUpdate={() => setUpdateVisible(false)} />
                                ) : (
                                    <InfoGrid requestUpdate={() => setUpdateVisible(true)} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default UserProfilePage;
