import React, { useMemo } from 'react';
import useShop from '../../hooks/useShop';
import ProductCard from '../../components/general/ProductCard';
import { useTranslation } from 'react-i18next';

function SimilarProducts({ productCategory }) {
    const { allProducts } = useShop();
    const { t } = useTranslation();

    const similarProducts = useMemo(() => {
        return allProducts.filter((product) => product?.productCategory !== productCategory);
    }, [productCategory, allProducts]);

    return (
        <>
            {similarProducts?.length > 0 && (
                <>
                    <h2 className='mb-4'>{t('youMightAlsoLike')}</h2>
                    <div className='row g-2'>
                        {similarProducts?.slice(0, 4).map((product, index) => {
                            return (
                                <div className='col-lg-3 col-md-4 col-sm-6' key={index}>
                                    <ProductCard {...product} />
                                </div>
                            );
                        })}
                    </div>
                </>
            )}
        </>
    );
}

export default SimilarProducts;
