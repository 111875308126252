import React from 'react';
import { truncate } from '../../helpers/utils';
import ReadOnlyRating from '../../components/general/ReadOnlyRating';

function UserBlock({ profile_picture, email_address, address, full_name, isPrivate, hasRating, rating, minimal }) {
    return (
        <>
            <div className='d-flex align-items-center'>
                <div className='user-avatar-pic-outer'>
                    <div className='user-avatar-pic' style={{ backgroundImage: `url(${profile_picture})` }}></div>
                </div>
                {!minimal && (
                    <div className='ms-2 text-start'>
                        <h6 className='mb-0'>{full_name || 'App Visitor'}</h6>

                        {isPrivate ? (
                            <p className='mb-0 small text-muted fw-normal'>{truncate(address, 20)}</p>
                        ) : (
                            <p className='mb-0 small text-muted fw-normal'>{email_address || 'Not registered yet'}</p>
                        )}
                        {hasRating && <ReadOnlyRating rating={rating} />}
                    </div>
                )}
            </div>
        </>
    );
}

export default UserBlock;
