import React, { useState, useMemo } from 'react';
import { RiShoppingBagFill } from 'react-icons/ri';
import { BsFillCircleFill } from 'react-icons/bs';
import { formatDate, truncateStart } from '../../helpers/utils';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';

// HOOKS
import useOrder from '../../hooks/useOrder';
import { appSettings } from '../../helpers/settings';
import useShop from '../../hooks/useShop';
import ResolveDisputeModal from './ResolveDisputeModal';
import { t } from 'i18next';

function AllOpenDisputes() {
    const { allDisputes } = useOrder();
    const { allProducts } = useShop();
    const [resolveRequested, setResolveRequested] = useState(false);
    const [disputeInfo, setDisputeInfo] = useState({});

    /* --------------------------------------------- 
          GET A DISPUTE FROM A SPECEIFIC ORDER
    --------------------------------------------- */
    function getDisputeProduct(id) {
        return allProducts?.filter((product) => product?.productId === id)[0];
    }

    /* --------------------------------------------- 
          REQUESTING DISPUTE RESOLVING
    --------------------------------------------- */
    function handleRequestResolving(
        pendingAmount,
        disputeId,
        disputeOpenerSlug,
        disputeOpenerProfile,
        disputeOpenerName,
        disputeOpenerEmail,
        disputeDefendantSlug,
        disputeDefendantProfile,
        disputeDefendantName,
        disputeDefendantEmail,
        disputeOpenerAddress,
        disputeDefendantAddress
    ) {
        setResolveRequested(true);
        setDisputeInfo({
            pendingAmount: pendingAmount,
            disputeId: disputeId,
            disputeOpenerSlug: disputeOpenerSlug,
            disputeOpenerProfile: disputeOpenerProfile,
            disputeOpenerAddress: disputeOpenerAddress,
            disputeOpenerName: disputeOpenerName,
            disputeOpenerEmail: disputeOpenerEmail,
            disputeDefendantSlug: disputeDefendantSlug,
            disputeDefendantProfile: disputeDefendantProfile,
            disputeDefendantAddress: disputeDefendantAddress,
            disputeDefendantName: disputeDefendantName,
            disputeDefendantEmail: disputeDefendantEmail,
        });
    }

    /* --------------------------------------------- 
          CLOSE DISPUTE MODAL
    --------------------------------------------- */
    function handleCloseDisputeModal() {
        setResolveRequested(false);
        setDisputeInfo({});
    }

    const openDisputes = useMemo(() => {
        return allDisputes?.filter((dispute) => dispute?.disputeStatus === 'open');
    }, [allDisputes]);

    /*** ------------------------------------------------ */
    //      ALL PENDING VIDEOS TABLE COLUMNS
    /*** ------------------------------------------------ */
    const columns = [
        {
            name: t('tbProducts'),
            selector: (row) => row?.productId,
            minWidth: '300px',
            cell: (row) => (
                <div row={row}>
                    <Link className='text-reset' to={`/products/${getDisputeProduct(row?.productId)?.productSlug}`}>
                        <div className='d-flex align-items-center'>
                            <div
                                className='flex-shrink-0 bg-cover bg-center'
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    background: `url(${getDisputeProduct(row?.productId)?.productGallery[0]})`,
                                    borderRadius: '0.5rem',
                                }}
                            ></div>
                            <div className='ms-3'>
                                <h6 className='mb-1'>
                                    {truncateStart(getDisputeProduct(row?.productId)?.productName, 30)}
                                </h6>
                                <p className='text-muted small mb-0'>
                                    {getDisputeProduct(row?.productId)?.unitPrice} {appSettings.currency}
                                </p>
                            </div>
                        </div>
                    </Link>
                </div>
            ),
        },
        {
            name: t('complainantUser'),
            selector: (row) => row?.disputeId,
            minWidth: '300px',
            cell: (row) => (
                <div row={row}>
                    <Link to={`/users/${row?.disputeOpenerSlug}`} className='text-reset'>
                        <div className='d-flex align-items-center'>
                            <div
                                className='flex-shrink-0 bg-cover bg-center'
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    backgroundImage: `url(${row?.disputeOpenerProfile})`,
                                    borderRadius: '0.5rem',
                                }}
                            ></div>
                            <div className='ms-3'>
                                <h6 className='mb-1' style={{ fontSize: '0.9rem' }}>
                                    {row?.disputeOpenerName}
                                </h6>
                                <p className='text-muted small mb-0'>{row?.disputeOpenerEmail}</p>
                            </div>
                        </div>
                    </Link>
                </div>
            ),
        },
        {
            name: t('defendantUser'),
            selector: (row) => row?.disputeId,
            minWidth: '300px',
            cell: (row) => (
                <div row={row}>
                    <Link to={`/users/${row?.disputeDefendantSlug}`} className='text-reset'>
                        <div className='d-flex align-items-center'>
                            <div
                                className='flex-shrink-0 bg-cover bg-center'
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    backgroundImage: `url(${row?.disputeDefendantProfile})`,
                                    borderRadius: '0.5rem',
                                }}
                            ></div>
                            <div className='ms-3'>
                                <h6 className='mb-1' style={{ fontSize: '0.9rem' }}>
                                    {row?.disputeDefendantName}
                                </h6>
                                <p className='text-muted small mb-0'>{row?.disputeDefendantEmail}</p>
                            </div>
                        </div>
                    </Link>
                </div>
            ),
        },
        {
            name: t('disputeReason'),
            selector: (row) => row?.disputeId,
            minWidth: '300px',
            cell: (row) => <div row={row}>{row?.disputeReason}</div>,
        },
        {
            name: t('disputeDescription'),
            selector: (row) => row?.disputeId,
            minWidth: '300px',
            cell: (row) => <div row={row}>{truncateStart(row?.disputeDescription, 20)}</div>,
        },
        {
            name: t('holdingAmount'),
            selector: (row) => row?.disputeId,
            minWidth: '300px',
            cell: (row) => (
                <div row={row}>
                    {row?.pendingAmount} {appSettings.currency}
                </div>
            ),
        },
        {
            name: t('createdAt'),
            selector: (row) => row?.disputeId,
            minWidth: '300px',
            cell: (row) => <div row={row}>{formatDate(row?.createdAt)}</div>,
        },
        {
            name: t('status'),
            selector: (row) => row?.disputeId,
            minWidth: '200px',
            cell: (row) => (
                <div row={row}>
                    <span className={`order-status ${row?.disputeStatus === 'open' ? 'pending' : 'delivered'}`}>
                        <BsFillCircleFill className='me-2 mb-1' size={10} />
                        {row?.disputeStatus}
                    </span>
                </div>
            ),
        },
        {
            name: t('resolve'),
            selector: (row) => row?.disputeId,
            minWidth: '200px',
            cell: (row) => (
                <div row={row}>
                    <button
                        className='btn btn-primary btn-sm'
                        onClick={() => {
                            handleRequestResolving(
                                row?.pendingAmount,
                                row?.disputeId,
                                row?.disputeOpenerSlug,
                                row?.disputeOpenerProfile,
                                row?.disputeOpenerName,
                                row?.disputeOpenerEmail,
                                row?.disputeDefendantSlug,
                                row?.disputeDefendantProfile,
                                row?.disputeDefendantName,
                                row?.disputeDefendantEmail,
                                row?.disputeOpenerAddress,
                                row?.disputeDefendantAddress
                            );
                        }}
                    >
                        {t('resolve')}
                    </button>
                </div>
            ),
        },
    ];

    return (
        <>
            <div className='card shadow-lg mb-0' data-aos='fade-up' data-aos-delay='200'>
                <div className='card-body p-lg-5'>
                    <div className='d-flex mb-5'>
                        <div className='stats-icon solid-cyan'>
                            <RiShoppingBagFill size='1.4rem' />
                        </div>
                        <div className='ms-3'>
                            <h2 className='mb-0 h4'>{t('openDisputes')}</h2>
                        </div>
                    </div>

                    {openDisputes && openDisputes?.length > 0 ? (
                        <DataTable
                            columns={columns}
                            data={openDisputes}
                            pagination={openDisputes.length >= 1 && true}
                            responsive
                            theme='solarized'
                        />
                    ) : (
                        <p className='mb-0'>{t('noDisputesRecords')}</p>
                    )}
                </div>
            </div>

            <ResolveDisputeModal
                resolveRequested={resolveRequested}
                setResolveRequested={handleCloseDisputeModal}
                disputeInfo={disputeInfo}
            />
        </>
    );
}

export default AllOpenDisputes;
