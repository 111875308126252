import React, { useState } from 'react';
import { FiPercent } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { useContractWrite } from 'wagmi';

// HOOKS
import useApp from '../../hooks/useApp';
import { useTranslation } from 'react-i18next';
import useShop from '../../hooks/useShop';
import Web3 from 'web3';

function SetGoldCheckCost() {
    const { goldCheckCost: cost, membershipContract, membershipAbi, stToken, loadGoldCheckCost } = useShop();
    const { setTransactionLoading } = useApp();
    const [goldCheckCost, setGoldCheckCost] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const { t } = useTranslation();

    /* --------------------------------------------- 
              TRANSFER OWNERSHIP FUNCTION
     --------------------------------------------- */
    const { write: web3SetGoldCheckCost } = useContractWrite({
        address: membershipContract?.address,
        abi: membershipAbi,
        functionName: 'setGoldCheckCost',
        onSuccess() {
            setTimeout(() => {
                setTransactionLoading(false);
                setSubmitted(false);
                setGoldCheckCost(0);
                loadGoldCheckCost(membershipContract, membershipAbi);
                toast.success('Great! shop creation price has been set');
            }, 5000);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error(`${t('toastError')}`);
        },
    });

    function setGoldCheckCostHandler(e) {
        e.preventDefault();
        setSubmitted(true);
        if (goldCheckCost !== '' && goldCheckCost) {
            const formattedPrice = Web3.utils.toWei(goldCheckCost.toString(), 'ether');
            web3SetGoldCheckCost({
                recklesslySetUnpreparedArgs: [formattedPrice],
            });
        }
    }

    return (
        <div className='card shadow-lg mb-0' data-aos='fade-up' data-aos-delay='200'>
            <div className='card-body p-lg-5'>
                <div className='d-flex mb-5'>
                    <div className='stats-icon solid-turquoise'>
                        <FiPercent size='1.4rem' />
                    </div>
                    <div className='ms-3'>
                        <h2 className='mb-0 h4'>{t('setGoldMembership')}</h2>
                        <p className='text-muted fw-normal mb-0'>
                            {t('currentPriceIs')}{' '}
                            <strong className='fw-bold text-primary'>
                                {cost} {stToken?.symbol}
                            </strong>
                        </p>
                    </div>
                </div>

                <form onSubmit={setGoldCheckCostHandler}>
                    <div className='row'>
                        <div className='col-lg-7'>
                            <div className='d-flex align-items-end'>
                                <div className='me-2 w-100'>
                                    <label className='form-label' htmlFor='openShopCost'>
                                        {t('goldMembershipPrice')}
                                    </label>
                                    <input
                                        type='number'
                                        id='openShopCost'
                                        className={`form-control ${
                                            submitted && goldCheckCost === '' ? 'is-invalid' : ''
                                        }`}
                                        placeholder='Add Gold Membership price'
                                        value={goldCheckCost}
                                        onChange={(e) => setGoldCheckCost(e.target.value)}
                                    />
                                </div>
                                <button className='btn btn-primary flex-shrink-0' type='submit'>
                                    {t('setGoldMembership')}
                                </button>
                            </div>
                            {submitted && goldCheckCost === '' && (
                                <div className='invalid-feedback d-block'>{t('goldMembershipErrMsg')}</div>
                            )}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default SetGoldCheckCost;
