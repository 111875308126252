import React from 'react';
import ReactDOM from 'react-dom';
import './scss/app.scss';
import App from './App';
import Web3Provider from './providers/Web3Provider';
import ShopProvider from './providers/ShopProvider';
import UserProvider from './providers/UserProvider';
import AppProvider from './providers/AppProvider';
import OrderProvider from './providers/OrderProvider';
import DigitalFilesProvider from './providers/DigitalFilesProvider';
import './helpers/localization.js';

ReactDOM.render(
    // <React.StrictMode>
    <Web3Provider>
        <AppProvider>
            <UserProvider>
                <ShopProvider>
                    <OrderProvider>
                        <DigitalFilesProvider>
                            <App />
                        </DigitalFilesProvider>
                    </OrderProvider>
                </ShopProvider>
            </UserProvider>
        </AppProvider>
    </Web3Provider>,
    // </React.StrictMode>,
    document.getElementById('root')
);
