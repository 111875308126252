import React from 'react';
import ReadOnlyRating from './ReadOnlyRating';
import ShopBlock from './ShopBlock';
import { Link } from 'react-router-dom';
import { HiShoppingBag } from 'react-icons/hi';
import { AiFillEdit } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import useApp from '../../hooks/useApp';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Thumbs } from 'swiper';
import useWeb3 from '../../hooks/useWeb3';

function ProductPreview() {
    const { t } = useTranslation();
    const { themeMode, productViewSrc, setProductView } = useApp();
    const { account } = useWeb3();

    return (
        <>
            <div className='w-100'>
                <div className='row align-items-center g-5'>
                    <div className='col-xl-6'>
                        <ul className='list-inline' data-aos='fade-right'>
                            <li className='list-inline-item'>
                                <Link
                                    className='badge bg-primary fw-normal rounded-0 text-gray-100'
                                    to={`/${productViewSrc?.isDigital ? 'digital-products' : 'products'}/category/${
                                        productViewSrc?.productCategory
                                    }`}
                                >
                                    {productViewSrc?.productCategory}
                                </Link>
                            </li>
                        </ul>
                        <h2 className='h1 text-shadow' data-aos='fade-right' data-aos-delay='100'>
                            {productViewSrc?.productName}
                        </h2>

                        <div className='mb-4'>
                            <ReadOnlyRating rating={productViewSrc?.productRating} />
                        </div>

                        <p className={`${themeMode === 'dark' ? 'text-gray-500' : 'text-dark'} fw-light mb-4`}>
                            {productViewSrc?.productDescription}
                        </p>
                        <ul className='list-inline mb-4'>
                            <li className='list-inline-item me-4'>
                                <p className='mb-0 text-muted h6 text-sm text-uppercase'>{t('productPrice')}</p>
                                <p className='h1 mb-0'>
                                    {productViewSrc?.productPrice}{' '}
                                    <span className='text-sm fw-normal text-primary'>USD</span>
                                </p>
                            </li>
                        </ul>
                        {account === productViewSrc?.productOwner ? (
                            <div className='mb-2'>
                                <div className='d-inline-block' onClick={() => setProductView(false)}>
                                    <Link
                                        className='btn btn-primary px-4'
                                        to={`/${productViewSrc?.isDigital ? 'digital-products' : 'products'}/update/${
                                            productViewSrc?.productSlug
                                        }`}
                                    >
                                        <AiFillEdit className='mb-1 me-2' size={20} />
                                        {t('updateYourProduct')}
                                    </Link>
                                </div>
                            </div>
                        ) : (
                            <div className='mb-2'>
                                <div className='d-inline-block' onClick={() => setProductView(false)}>
                                    <Link
                                        className='btn btn-primary px-4'
                                        to={`/${productViewSrc?.isDigital ? 'digital-products' : 'products'}/${
                                            productViewSrc?.productSlug
                                        }`}
                                    >
                                        <HiShoppingBag className='mb-1 me-2' size={20} />
                                        {t('purchase')}
                                    </Link>
                                </div>
                            </div>
                        )}

                        <div data-aos='fade-right' data-aos-delay='400' className='mb-4'>
                            <div className='d-inline-block bg-opac-secondary rounded p-3 mt-4'>
                                <ShopBlock
                                    shopId={productViewSrc?.productShopId}
                                    productRating={productViewSrc?.productRating}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='hero-gallery' data-aos='fade-left'>
                            <Swiper spaceBetween={0} slidesPerView={1} modules={[Navigation, Thumbs]} navigation>
                                {productViewSrc?.productGallery?.map((img, index) => {
                                    return (
                                        <SwiperSlide key={index}>
                                            <div
                                                className='product-gallery-slide cursor-pointer'
                                                key={index}
                                                style={{ background: `url(${img})` }}
                                            ></div>
                                        </SwiperSlide>
                                    );
                                })}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProductPreview;
