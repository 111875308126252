import React from 'react';
import { Link } from 'react-router-dom';
import { truncateStart } from '../../helpers/utils';
import { RiShoppingBag3Fill } from 'react-icons/ri';
import { HiBadgeCheck } from 'react-icons/hi';
import ReadOnlyRating from './ReadOnlyRating';
import { BiCategoryAlt } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { BsPatchCheckFill } from 'react-icons/bs';

function ShopCard({ shopTitle, shopSlug, shopDescription, shopCategory, isVerified, shopProducts, shopRating, vip }) {
    const { t } = useTranslation();
    return (
        <>
            <div className='card mb-0 shop-card'>
                <div className='card-body'>
                    <h2 className='h3'>
                        <Link to={`/shops/${shopSlug}`} className='reset-anchor'>
                            {shopTitle}
                            {isVerified && !vip && (
                                <HiBadgeCheck className='ms-2' size={20} style={{ color: '#649bff' }} />
                            )}
                            {isVerified && vip && <BsPatchCheckFill className='ms-2 text-gold' size={20} />}
                        </Link>
                    </h2>
                    <p className='small'>{truncateStart(shopDescription, 100)}</p>
                    <div className='mt-2 mb-4'>
                        <ReadOnlyRating rating={shopRating} />
                    </div>
                    <ul
                        className='d-flex align-items-center list-inline mb-0 text-sm text-muted p-3 bg-gray-850'
                        style={{ borderRadius: '0.3rem' }}
                    >
                        <li className='d-flex align-items-center me-3'>
                            <RiShoppingBag3Fill className='text-primary me-1' size='1.2rem' />
                            <strong className='fw-bold text-white me-1'>{shopProducts}</strong> {t('products')}
                        </li>
                        <li className='me-3'>|</li>
                        <li className='d-flex align-items-center me-3 text-nowrap'>
                            <BiCategoryAlt className='text-primary me-1' size={20} />
                            <strong className='fw-bold text-white me-1'>{truncateStart(shopCategory, 12)}</strong>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}

export default ShopCard;
