import React from 'react';

const ShopContext = React.createContext({
    shopContract: null,
    shopContractAbi: null,
    membershipContract: null,
    membershipAbi: null,
    paymentTokenContract: null,
    paymentToken: null,
    stToken: null,
    tokenBalance: null,
    allShops: [],
    allProducts: [],
    digitalFiles: [],
    pendingProducts: [],
    productReviews: [],
    totalProducts: [],
    userShops: [],
    fetchingAllShops: true,
    fetchingAllProducts: true,
    tokenInUSD: 0,
    usdToken: 0,
    promotionPrice: 0,
    shopCreationCost: 0,
    goldCheckCost: 0,
    shopVerifyCost: 0,
    loadShopContract: () => {},
    getShopContractAbi: () => {},
    loadAllShops: () => {},
    loadUserShops: () => {},
    loadAllProducts: () => {},
    loadUserProducts: () => {},
    loadProductReviews: () => {},
    loadPaymentTokenContract: () => {},
    loadPaymentTokenAddress: () => {},
    loadPaymentTokenBalance: () => {},
    loadPaymentTokenPriceInUSD: () => {},
    loadPromotionPrice: () => {},
    loadStToken: () => {},
    loadMembershipContract: () => {},
    getMemberShopContractAbi: () => {},
    loadShopCreationCost: () => {},
    loadGoldCheckCost: () => {},
    loadShopVerifyCost: () => {},
    loadDigitalFiles: () => {},
});

export default ShopContext;
