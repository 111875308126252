import React from 'react';
import { HiPresentationChartBar } from 'react-icons/hi';
import { configEtherScanUrl, formatDate } from '../../helpers/utils';
import DataTable from 'react-data-table-component';

// HOOKS
import useApp from '../../hooks/useApp';
import useWeb3 from '../../hooks/useWeb3';
import UserBlock from '../../components/general/UserBlock';
import { useTranslation } from 'react-i18next';

function UserApprovedVideosTable() {
    const { activities } = useApp();
    const { networkId } = useWeb3();
    const { t } = useTranslation();

    /*** ------------------------------------------------- */
    //      ACTIVITIES TABLE COLUMNS
    /*** ------------------------------------------------- */
    const columns = [
        {
            name: t('tableUser'),
            selector: (row) => row?.userAddress,
            minWidth: '300px',
            cell: (row) => (
                <div row={row}>
                    <a
                        href={configEtherScanUrl(networkId, row?.userAddress)}
                        rel='noopener noreferrer'
                        className='text-reset'
                        target='_blank'
                    >
                        <UserBlock
                            isPrivate={true}
                            profile_picture={row?.userProfile}
                            address={row?.userAddress}
                            full_name={row?.userName}
                        />
                    </a>
                </div>
            ),
        },
        {
            name: t('tableTime'),
            minWidth: '200px',
            selector: (row) => row?.time,
            cell: (row) => (
                <div row={row}>
                    <small>{formatDate(row?.time)}</small>
                </div>
            ),
        },
        {
            name: t('tableAction'),
            selector: (row) => row?.action,
            cell: (row) => (
                <div row={row}>
                    {row?.action === 'The user has ordered a product' ? (
                        <span className='small fw-light'>{t('orderAProduct')}</span>
                    ) : row?.action === 'The user canceled the request' ? (
                        <span className='small fw-light'>{t('requestCanceled')}</span>
                    ) : row?.action === 'A new product has been created' ? (
                        <span className='small fw-light'>{t('newProductCreated')}</span>
                    ) : row?.action === 'Product information has been updated' ? (
                        <span className='small fw-light'>{t('productUpdated')}</span>
                    ) : row?.action === 'The product has been approved' ? (
                        <span className='small fw-light'>{t('productApproved')}</span>
                    ) : row?.action === 'The admin has blocked the product' ? (
                        <span className='small fw-light'>{t('productBlocked')}</span>
                    ) : row?.action === 'The admin has blocked the shop' ? (
                        <span className='small fw-light'>{t('shopBlocked')}</span>
                    ) : row?.action === 'The user advertised his product' ? (
                        <span className='small fw-light'>{t('productPromoted')}</span>
                    ) : row?.action === 'A new review has been added' ? (
                        <span className='small fw-light'>{t('newRate')}</span>
                    ) : row?.action === 'A new shop has been created' ? (
                        <span className='small fw-light'>{t('newShop')}</span>
                    ) : row?.action === 'Shop information has been updated' ? (
                        <span className='small fw-light'>{t('shopUpdated')}</span>
                    ) : row?.action === 'A new user has been registered' ? (
                        <span className='small fw-light'>{t('newUser')}</span>
                    ) : row?.action === 'User information has been updated' ? (
                        <span className='small fw-light'>{t('userUpdated')}</span>
                    ) : row?.action === 'User account verified' ? (
                        <span className='small fw-light'>{t('accountVerified')}</span>
                    ) : (
                        <span className='small'>{row?.action}</span>
                    )}
                </div>
            ),
        },
    ];

    return (
        <div className='card shadow-lg mb-0' data-aos='fade-up' data-aos-delay='200'>
            <div className='card-body p-lg-5'>
                <div className='d-flex a;ign-items-center mb-5'>
                    <div className='stats-icon solid-cyan'>
                        <HiPresentationChartBar size='1.4rem' />
                    </div>
                    <div className='ms-3'>
                        <h2 className='mb-0 h4'>{t('tableActivityHeading')}</h2>
                        <p className='text-muted fw-normal mb-0'></p>
                    </div>
                </div>

                {activities.length > 0 ? (
                    <DataTable
                        columns={columns}
                        data={activities
                            ?.filter(
                                (act) =>
                                    act?.action !== 'A new shop has been created' &&
                                    act?.action !== 'Shop information has been updated'
                            )
                            ?.sort((a, b) => b?.time - a?.time)}
                        pagination={activities.length >= 1 && true}
                        responsive
                        theme='solarized'
                    />
                ) : (
                    <p className='mb-0'>{t('tableNoRecords')}</p>
                )}
            </div>
        </div>
    );
}

export default UserApprovedVideosTable;
