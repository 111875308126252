import React, { useEffect, useState } from 'react';
import useShop from '../../hooks/useShop';
import useApp from '../../hooks/useApp';
import { toast } from 'react-toastify';
import { useContractWrite } from 'wagmi';
import { waitForTransaction } from '@wagmi/core';
import TokenAbi from '../../integration/TokenAbi.json';
import Web3 from 'web3';
import { useTranslation } from 'react-i18next';

function ProductPromote({ isPromoted, productOwner, account, productId, prmotionEndTime }) {
    const {
        shopContract,
        shopContractAbi,
        promotionPrice,
        membershipContract,
        membershipAbi,
        stToken,
        loadAllProducts,
    } = useShop();
    const { t } = useTranslation();
    const { setTransactionLoading } = useApp();
    const [approvedPromoteTx, setApprovedPromoteTx] = useState(null);

    /* --------------------------------------------- 
              APPROVE PROMOTING FILE
     --------------------------------------------- */
    const { write: web3ApprovePromoting, data: txPromotingData } = useContractWrite({
        address: stToken?.address,
        abi: TokenAbi,
        functionName: 'approve',

        onSuccess() {
            setTransactionLoading(true);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error(`${t('toastError')}`);
        },
    });

    /* --------------------------------------------- 
              APPROVE PROMOTING FILE
     --------------------------------------------- */
    const { write: web3PromoteFile } = useContractWrite({
        address: membershipContract?.address,
        abi: membershipAbi,
        functionName: 'promote',
        args: [Number(productId), Web3.utils.toWei(Number(promotionPrice).toString(), 'ether')],
        onSuccess() {
            setTimeout(() => {
                setTransactionLoading(false);
                loadAllProducts(shopContract, shopContractAbi);
                toast.success(`${t('productPromotedToast')}`);
            }, 5000);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error(`${t('toastError')}`);
        },
    });

    useEffect(() => {
        if (txPromotingData) {
            async function getPromtionTx() {
                const waitFrTx = await waitForTransaction({
                    hash: txPromotingData?.hash,
                });
                setApprovedPromoteTx(waitFrTx);
            }

            getPromtionTx();
        }
    }, [txPromotingData]);

    useEffect(() => {
        if (approvedPromoteTx) {
            web3PromoteFile();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [approvedPromoteTx]);

    /* --------------------------------------------- 
              PROMOTE NFT FUNCTION
     --------------------------------------------- */
    function promoteFileHandler() {
        web3ApprovePromoting({
            recklesslySetUnpreparedArgs: [
                membershipContract.address,
                Web3.utils.toWei(Number(promotionPrice).toString(), 'ether'),
            ],
        });
    }

    return (
        <>
            {!isPromoted ? (
                account === productOwner && (
                    <>
                        <button className='btn btn-success btn-sm px-4' type='button' onClick={promoteFileHandler}>
                            Promote Product
                        </button>
                        <div className='mt-2'>
                            Promote this product for{' '}
                            <span className='text-success'>
                                {promotionPrice} {stToken?.symbol}
                            </span>
                        </div>
                    </>
                )
            ) : (
                <div className='mb-2'>
                    <span className='badge bg-primary me-2'>Promoted</span>{' '}
                    <span className='text-xs'>Ends at {prmotionEndTime}</span>
                </div>
            )}
        </>
    );
}

export default ProductPromote;
