import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import 'aos/dist/aos.css';
import HomePage from './pages/home';
import Layout from './pages/Layout';
import AdminPage from './pages/admin';
import FAQsPage from './pages/faqs';
import NotFound from './components/NotFound';
import UnAuthorized from './components/UnAuthorized';

import * as bootstrap from 'bootstrap';
import useWeb3 from './hooks/useWeb3';
import SignUpPage from './pages/signup';
import useApp from './hooks/useApp';
import UserProfilePage from './pages/user-profile';
import useUser from './hooks/useUser';
import UserAccountPage from './pages/user-account';
import CreateShopPage from './pages/shop-create';
import ShopSinglePage from './pages/shop-single';
import UpdateShopPage from './pages/shop-update';
import ActivitesPage from './pages/activity';
import SellersPage from './pages/sellers';
import CreateProductPage from './pages/product-create';
import UpdateProductPage from './pages/product-update';
import ProductSinglePage from './pages/product-single';
import ProductsPage from './pages/products';
import ShopsPage from './pages/shops';
import SellerSinglePage from './pages/seller-single';
import UserSinglePage from './pages/user-single';
import TermsPage from './pages/terms';
import AboutUsPage from './pages/about';
import ContactUsPage from './pages/contact';
import ProductsCategoryPage from './pages/product-category';
import UserAccountTabPage from './pages/user-account/UserAccountTabPage';
import DigitalProductSinglePage from './pages/digital-product-single';
import DigitalProductsCategoryPage from './pages/digital-product-category';
import DigitalProductsPage from './pages/digital-products';
import UpdateDigitalProductPage from './pages/digital-product-update';

window.bootstrap = bootstrap;

function App() {
    const { owner } = useApp();
    const { userInfo } = useUser();
    const { account } = useWeb3();

    return (
        <BrowserRouter>
            <Routes>
                <Route element={<Layout />}>
                    <Route exact path='/' element={<HomePage />} />
                    <Route path='/faqs' element={<FAQsPage />} />

                    {/* ACTIVITIES */}
                    <Route path='/activities' element={<ActivitesPage />} />

                    {/* SHOPS */}
                    <Route path='/shops/:slug' element={<ShopSinglePage />} />
                    <Route path='/shops/update/:slug' element={<UpdateShopPage />} />
                    <Route path='/shops' element={<ShopsPage />} />

                    {/* PRODUCTS */}
                    <Route path='/products/create/:slug' element={<CreateProductPage />} />
                    <Route path='/products/update/:slug' element={<UpdateProductPage />} />
                    <Route path='/digital-products/update/:slug' element={<UpdateDigitalProductPage />} />
                    <Route path='/products/:slug' element={<ProductSinglePage />} />
                    <Route path='/digital-products/:slug' element={<DigitalProductSinglePage />} />
                    <Route path='/products' element={<ProductsPage />} />
                    <Route path='/digital-products' element={<DigitalProductsPage />} />
                    <Route path='/products/category/:category' element={<ProductsCategoryPage />} />
                    <Route path='/digital-products/category/:category' element={<DigitalProductsCategoryPage />} />

                    {/* VENDOR PAGES */}
                    {userInfo?.is_vendor && <Route path='/shops/create' element={<CreateShopPage />} />}

                    {/* ADMIN PAGES */}
                    {account === owner ? (
                        <Route path='/admin' element={<AdminPage />} />
                    ) : (
                        <Route path='/admin' element={<UnAuthorized />} />
                    )}

                    {/* USER PAGES */}
                    {userInfo?.full_name && <Route path='/profile' element={<UserProfilePage />} />}
                    {userInfo?.full_name && <Route path='/account/' element={<UserAccountPage />} />}
                    {userInfo?.full_name && <Route path='/account/:tab' element={<UserAccountTabPage />} />}
                    <Route path='/sellers' element={<SellersPage />} />
                    <Route path='/sellers/:slug' element={<SellerSinglePage />} />
                    <Route path='/users/:slug' element={<UserSinglePage />} />
                    <Route path='/terms' element={<TermsPage />} />
                    <Route path='/about' element={<AboutUsPage />} />
                    <Route path='/contact' element={<ContactUsPage />} />

                    {/* AUTHENTICATION */}
                    <Route path='signup' element={<SignUpPage />} />

                    {/* 404 */}
                    <Route path='/*' element={<NotFound />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
