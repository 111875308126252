import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import ShopCard from '../../components/general/ShopCard';
import useShop from '../../hooks/useShop';
import { BiRightArrowAlt, BiLeftArrowAlt } from 'react-icons/bi';
import { AiFillShop } from 'react-icons/ai';
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';
import useWeb3 from '../../hooks/useWeb3';

function UserShops() {
    const { allShops } = useShop();
    const { account } = useWeb3();

    const userShops = useMemo(() => {
        return allShops?.filter((shop) => shop?.shopOwner === account);
    }, [allShops, account]);

    // PAGINATION VARIABLES ---------------------------------
    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = 16;
    const endOffset = itemOffset + itemsPerPage;
    const pageCount = Math.ceil(userShops?.length / itemsPerPage);
    const { t } = useTranslation();

    /* --------------------------------------------- 
          MOVE BETWEEN PAGES
    --------------------------------------------- */
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % userShops?.length;
        setItemOffset(newOffset);
    };

    return (
        <>
            {userShops?.length > 0 ? (
                <div className='row g-3'>
                    <div className='col-12 mb-3'>
                        <Link className='btn btn-primary btn-sm px-4' data-aos='fade-up' to='/shops/create'>
                            <AiFillShop className='mb-1 me-2' size={20} />
                            {t('createNewShop')}
                        </Link>
                    </div>
                    {userShops
                        ?.filter((shop) => !shop?.shopIsBlocked)
                        ?.slice(itemOffset, endOffset)
                        ?.map((shop, index) => {
                            return (
                                <div
                                    className='col-xxl-4 col-xl-6'
                                    data-aos='fade-up'
                                    data-aos-delay={index * 50}
                                    key={index}
                                >
                                    <ShopCard {...shop} />
                                </div>
                            );
                        })}

                    {/* PAGINATION */}
                    {userShops?.length > 0 && (
                        <div className='col-12'>
                            <div className='react-pagination mt-4 justify-content-start'>
                                <ReactPaginate
                                    breakLabel='...'
                                    nextLabel={<BiRightArrowAlt />}
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    pageCount={pageCount}
                                    previousLabel={<BiLeftArrowAlt />}
                                    renderOnZeroPageCount={null}
                                />
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <p>
                    {t('youHaveNoShops')} <Link to='/shops/create'>{t('createNewOne')}</Link>
                </p>
            )}
        </>
    );
}

export default UserShops;
