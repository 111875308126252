import React, { useEffect } from 'react';
import useApp from '../../hooks/useApp';

function ToggleThemeHandler() {
    const { themeMode, switchMode } = useApp();

    /*** ------------------------------------------------------ */
    //      SWITCH LIGHT/DARK THEME MODE BUTTON
    /*** ------------------------------------------------------ */
    function switchModeHandler(e) {
        if (e.target.checked) {
            switchMode('dark');
            document.querySelector('html').setAttribute('data-theme', 'dark');
        } else {
            switchMode('light');
            document.querySelector('html').setAttribute('data-theme', 'light');
        }
    }

    useEffect(() => {
        if (themeMode === 'light') {
            document.querySelector('html').setAttribute('data-theme', 'light');
        } else {
            document.querySelector('html').setAttribute('data-theme', 'dark');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='toggleWrapper'>
            <input
                type='checkbox'
                className='dn'
                id='dn'
                onChange={(e) => switchModeHandler(e)}
                checked={Boolean(themeMode === 'dark')}
            />
            <label htmlFor='dn' className='toggle'>
                <span className='toggle__handler'>
                    <span className='crater crater--1'></span>
                    <span className='crater crater--2'></span>
                    <span className='crater crater--3'></span>
                </span>
                <span className='star star--1'></span>
                <span className='star star--2'></span>
                <span className='star star--3'></span>
                <span className='star star--4'></span>
                <span className='star star--5'></span>
                <span className='star star--6'></span>
            </label>
        </div>
    );
}

export default ToggleThemeHandler;
