import React from 'react';
import PageBanner from '../../components/general/PageBanner';
import CreateShopForm from './CreateShopForm';
import { useTranslation } from 'react-i18next';

function CreateShopPage() {
    const { t } = useTranslation();
    return (
        <>
            <PageBanner heading={t('createNewShop')} />

            <section className='pb-5'>
                <div className='container pb-5'>
                    <div className='row'>
                        <div className='col-lg-8 mx-auto'>
                            <div className='card'>
                                <div className='card-body p-lg-5'>
                                    <CreateShopForm />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CreateShopPage;
