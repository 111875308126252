import React, { useMemo } from 'react';
import useUser from '../../hooks/useUser';
import useShop from '../../hooks/useShop';
import PageBanner from '../../components/general/PageBanner';
import TopSellers from './TopSellers';
import AllSellers from './AllSellers';
import { calculateSellerRating } from '../../helpers/utils';
import { useTranslation } from 'react-i18next';

function SellersPage() {
    const { usersList } = useUser();
    const { allShops } = useShop();
    const { t } = useTranslation();

    const vendors = useMemo(() => {
        return usersList
            ?.filter((user) => user?.is_vendor)
            ?.map((user) => ({
                ...user,
                shops: allShops?.filter((shop) => user?.address === shop.shopOwner),
                rating: calculateSellerRating(
                    allShops
                        ?.filter((shop) => shop.shopRating !== 0)
                        ?.filter((shop) => user?.address === shop.shopOwner)
                ),
            }));
    }, [usersList, allShops]);

    const topRatedSellers = useMemo(() => {
        return vendors?.filter((seller) => seller?.rating >= 4.5)?.slice(0, 3);
    }, [vendors]);

    return (
        <>
            <PageBanner heading={t('allSellers')} />

            {vendors && (
                <>
                    {topRatedSellers && topRatedSellers?.length > 0 && <TopSellers sellers={topRatedSellers} />}

                    <AllSellers sellers={vendors} />
                </>
            )}
        </>
    );
}

export default SellersPage;
