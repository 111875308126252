import React, { useState } from 'react';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import { countries } from '../../helpers/constants';
import { appSettings } from '../../helpers/settings';
import { Tooltip } from 'react-tippy';
import { toast } from 'react-toastify';
import { MdInfo } from 'react-icons/md';
import useWeb3 from '../../hooks/useWeb3';
import ConnectWalletHander from '../../components/general/ConnectWalletHandler';
import useApp from '../../hooks/useApp';
import useUser from '../../hooks/useUser';

import { useContractWrite } from 'wagmi';
import { useTranslation } from 'react-i18next';

// IPFS CREATE HOST
const auth = 'Basic ' + Buffer.from(appSettings.IPFSProjectID + ':' + appSettings.IPFSSecret).toString('base64');
const ipfsClient = require('ipfs-http-client');
const ipfs = ipfsClient.create({
    host: 'ipfs.infura.io',
    port: 5001,
    protocol: 'https',
    headers: {
        authorization: auth,
    },
});

function SignUpForm() {
    const { account } = useWeb3();
    const { t } = useTranslation();

    const { setTransactionLoading } = useApp();
    const { userContract, usersList, userContractAbi, loadUserInfo, loadUsersList } = useUser();
    const [submit, setSubmit] = useState(false);
    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const { write: web3RegisterHandler } = useContractWrite({
        address: userContract?.address,
        abi: userContractAbi,
        functionName: 'createUser',
        onMutate() {
            setTransactionLoading(true);
        },
        onSuccess() {
            setTimeout(() => {
                setTransactionLoading(false);
                loadUserInfo(userContract, account);
                loadUsersList(userContract);
                toast.success(`${t('successRegister')}`);
                setSubmit(true);
            }, 5000);
        },
        onError() {
            setSubmit(false);
            setTransactionLoading(false);
            toast.error(`${t('toastError')}`);
        },
    });

    /* --------------------------------------------- 
          HANDLE REGISTER FORM SUBMIT
    --------------------------------------------- */
    async function handleFormSubmit(data) {
        setTransactionLoading(true);

        const ipfsProfile = await ipfs.add(data?.profile[0]);
        setSubmit(true);
        if (ipfsProfile) {
            web3RegisterHandler({
                recklesslySetUnpreparedArgs: [
                    [
                        data?.full_name,
                        data?.email,
                        data?.location?.label,
                        `https://${appSettings.IPFSGatewaySubdomain}.infura-ipfs.io/ipfs/${ipfsProfile.path}`,
                        data?.mobile,
                    ],
                    data?.isVendor ? 0 : 1,
                ],
            });
        }
    }

    /* --------------------------------------------- 
          VALIDATE PROFILE IMAGE FILE TYPE
    --------------------------------------------- */
    const validateImageFileType = (file) => {
        const validImageTypes = ['image/png', 'image/jpeg', 'image/jpg'];
        if (!validImageTypes.includes(file[0].type)) {
            return t('productGalleryPatternMsg');
        }
    };

    /* --------------------------------------------- 
          VALIDATE UNIQUE EMAIL ADDRESS
    --------------------------------------------- */
    const validateUniqueEmail = (email) => {
        if (usersList?.map((user) => user?.email_address)?.includes(email)) {
            return t('userUniqueMail');
        }
    };

    return (
        <>
            {!submit && (
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <div className='row g-4'>
                        {/* FULL NAME */}
                        <div className='col-lg-6'>
                            <label className='form-label' htmlFor='fullName'>
                                {t('fullName')}
                            </label>
                            <input
                                type='text'
                                className={`form-control ${errors.full_name ? 'is-invalid' : ''}`}
                                id='fullName'
                                placeholder={t('fullNamePlaceholder')}
                                name='full_name'
                                {...register('full_name', {
                                    required: {
                                        value: true,
                                        message: t('fullNameErrMsg'),
                                    },
                                    minLength: {
                                        value: 8,
                                        message: t('fullNameMinLength'),
                                    },
                                })}
                            />
                            {errors.full_name && <span className='invalid-feedback'>{errors.full_name?.message}</span>}
                        </div>

                        {/* EMAIL ADDRESS */}
                        <div className='col-lg-6'>
                            <label className='form-label' htmlFor='email'>
                                {t('userMail')}
                            </label>
                            <input
                                type='email'
                                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                id='email'
                                placeholder={t('userMailPlaceholder')}
                                name='email'
                                {...register('email', {
                                    required: {
                                        value: true,
                                        message: t('userMailErrMsg'),
                                    },
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: t('userMailPattern'),
                                    },
                                    validate: validateUniqueEmail,
                                })}
                            />
                            {errors.email && <span className='invalid-feedback'>{errors.email?.message}</span>}
                        </div>

                        {/* PHONE NUMBER */}
                        <div className='col-lg-6'>
                            <label className='form-label' htmlFor='phoneNumber'>
                                {t('phoneNumber')}
                            </label>
                            <input
                                type='tel'
                                className={`form-control ${errors.mobile ? 'is-invalid' : ''}`}
                                id='phoneNumber'
                                placeholder={t('verifiyPhoneNumberPlaceholder')}
                                name='mobile'
                                {...register('mobile', {
                                    required: {
                                        value: true,
                                        message: t('verifyPhoneNumberErrMsg'),
                                    },
                                    pattern: {
                                        value: /^\+(?:[0-9] ?){6,14}[0-9]$/,
                                        message: t('verifyPhoneNumberPattern'),
                                    },
                                })}
                            />
                            {errors.mobile && <span className='invalid-feedback'>{errors.mobile?.message}</span>}
                        </div>

                        {/* LOCATION */}
                        <div className='col-lg-6'>
                            <label className='form-label' htmlFor='location'>
                                {t('location')}
                            </label>
                            <Controller
                                name='location'
                                control={control}
                                rules={{ required: t('productCategoryErrMsg') }}
                                render={({ field }) => (
                                    <>
                                        <Select
                                            options={countries}
                                            id='location'
                                            className={`border-0 shadow-sm ${errors.location ? 'is-invalid' : ''}`}
                                            classNamePrefix='select'
                                            placeholder={t('select')}
                                            isSearchable={true}
                                            {...field}
                                        />
                                        {errors.location && (
                                            <span className='invalid-feedback'>{errors.location?.message}</span>
                                        )}
                                    </>
                                )}
                            />
                        </div>
                        <div className='col-lg-12'>
                            <label className='form-label' htmlFor='profile'>
                                {t('profilePic')}
                            </label>
                            <input
                                type='file'
                                className={`form-control ${errors.profile ? 'is-invalid' : ''}`}
                                id='profile'
                                accept='.jpg, .png, .jpeg'
                                name='profile'
                                {...register('profile', {
                                    required: {
                                        value: true,
                                        message: t('profilePicErrMsg'),
                                    },
                                    validate: validateImageFileType,
                                })}
                            />
                            {errors.profile && <span className='invalid-feedback'>{errors.profile?.message}</span>}
                        </div>
                        <div className='col-12 d-flex align-items-center'>
                            <div className='form-check d-flex align-items-center'>
                                <input
                                    id='isVendor'
                                    type='checkbox'
                                    className='form-check-input'
                                    name='isVendor'
                                    {...register('isVendor', {
                                        required: false,
                                    })}
                                />
                                <label htmlFor='isVendor' className='form-check-label ms-2'>
                                    {t('signAsVendor')}
                                </label>
                            </div>
                            <Tooltip title={t('signAsVendorPopover')} position='top' trigger='click'>
                                <MdInfo className='ms-2 cursor-pointer text-muted' size={18} />
                            </Tooltip>
                        </div>
                        <div className='col-12'>
                            {account ? (
                                <button className='btn btn-primary' type='submit'>
                                    {t('signupNow')}
                                </button>
                            ) : (
                                <ConnectWalletHander />
                            )}
                        </div>
                    </div>
                </form>
            )}
        </>
    );
}

export default SignUpForm;
