import React, { useMemo } from 'react';
import useShop from '../../hooks/useShop';
import ShopCardLg from '../../components/general/ShopCardLg';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import useApp from '../../hooks/useApp';

function BestSellers() {
    const { allShops } = useShop();
    const { themeMode } = useApp();
    const { t } = useTranslation();

    const topRated = useMemo(() => {
        return allShops?.filter((shop) => shop?.shopRating > 4)?.slice(0, 6);
    }, [allShops]);

    return (
        <>
            {topRated && topRated?.length > 0 && (
                <section className={`py-5 ${themeMode === 'light' ? 'bg-light' : 'bg-gray-950'}`}>
                    <div className='container py-5'>
                        <header className='text-center mb-3'>
                            <h2 className='text-start' data-aos='fade-up'>
                                {t('topRatedShops')}
                            </h2>
                            <p className='text-muted' data-aos='fade-up' data-aos-delay='100'></p>
                        </header>
                        <div className='swiper-wrapper-padding'>
                            <Swiper
                                breakpoints={{
                                    560: {
                                        slidesPerView: 1,
                                        spaceBetween: 0,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                        spaceBetween: 0,
                                    },
                                }}
                                modules={[Pagination]}
                                pagination={{ clickable: true }}
                            >
                                {topRated?.map((shop, index) => {
                                    return (
                                        <SwiperSlide key={index}>
                                            <div className='px-3' data-aos='fade-up' data-aos-delay={index * 50}>
                                                <ShopCardLg {...shop} />
                                            </div>
                                        </SwiperSlide>
                                    );
                                })}
                            </Swiper>
                        </div>
                    </div>
                </section>
            )}
        </>
    );
}

export default BestSellers;
