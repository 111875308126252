import React, { useEffect } from 'react';
import { appSettings } from '../../helpers/settings';

// HOOKS
import useShop from '../../hooks/useShop';

// COMPONENTS
import HeroSlider from './HeroSlider';
import NewArrivals from './NewArrivals';
import TopRatedProducts from './TopRatedProducts';
import PromotedProducts from './PromotedProducts';
import Features from './Features';
import TopShops from './TopShops';
import BestSellers from './BestSellers';
import DigitalProducts from './DigitalProducts';

function HomePage() {
    const { allProducts } = useShop();

    useEffect(() => {
        document.title = `${appSettings.brandName} | ${appSettings.brandDescription}`;
    }, []);

    return (
        <>
            <section>
                <HeroSlider data={allProducts} />
            </section>

            <Features />

            <PromotedProducts />

            <TopShops />

            <NewArrivals />

            <BestSellers />

            <DigitalProducts />

            <TopRatedProducts />
        </>
    );
}

export default HomePage;
