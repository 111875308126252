import React, { useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { appSettings } from '../../helpers/settings';
import { configEtherScanUrl, fixNavbarToTop } from '../../helpers/utils';
import { HiUserCircle } from 'react-icons/hi';
import UserBlock from './UserBlock';
import { Web3Button } from '@web3modal/react';
import { useTranslation } from 'react-i18next';

// HOOKS
import useWeb3 from '../../hooks/useWeb3';
import useUser from '../../hooks/useUser';
import useApp from '../../hooks/useApp';

// COMPONENTS
import ConnectWalletHander from './ConnectWalletHandler';
import TopBanner from './TopBanner';
import BottomBanner from './BottomBanner';
import HeaderSearch from './HeaderSearch';

function Navbar() {
    const { account, networkId } = useWeb3();
    const { userInfo } = useUser();
    const { themeMode, owner } = useApp();
    const { t } = useTranslation();

    /*** -------------------------------------------- */
    //      FIXING NAVBAR TO TOP
    /*** -------------------------------------------- */
    useEffect(() => {
        fixNavbarToTop();
    }, []);

    return (
        <header className='main-header'>
            <TopBanner />
            <nav
                className='navbar w-100 navbar-expand-lg px-0 justify-content-between rounded-0 shadow-0'
                style={{ zIndex: '1000' }}
            >
                <div className='container'>
                    <Link className='navbar-brand d-none d-lg-block' to='/'>
                        <img
                            src={themeMode === 'dark' ? appSettings.logo : appSettings.logoLight}
                            alt={appSettings.brandName}
                            width={appSettings.logoWidth}
                            className='h-auto'
                        />
                    </Link>
                    <div className='d-flex d-lg-none align-items-center justify-content-between w-100'>
                        <div
                            className='d-block d-lg-none'
                            style={{ transform: 'scale(0.9)', transformOrigin: 'left center' }}
                        >
                            {account && (
                                <div className='me-3 flex-shrink-0'>
                                    <Web3Button avatar='hide' />
                                </div>
                            )}
                        </div>
                        <Link className='navbar-brand' to='/'>
                            <img
                                src={themeMode === 'dark' ? appSettings.logo : appSettings.logoLight}
                                alt={appSettings.brandName}
                                width={appSettings.logoWidth}
                                className='h-auto'
                            />
                        </Link>

                        <div className='d-block d-lg-none' style={{ transform: 'scale(0.8)' }}>
                            {account ? (
                                userInfo?.full_name ? (
                                    <div className='dropdown'>
                                        <Link
                                            className='px-0 nav-link dropdown-toggle text-capitalize no-caret d-flex align-items-center text-reset'
                                            id='accountDropdown'
                                            to='/'
                                            role='button'
                                            data-bs-toggle='dropdown'
                                            data-bs-target='#userDropdown'
                                            aria-expanded='false'
                                        >
                                            <UserBlock {...userInfo} minimal={true} />
                                        </Link>
                                        <ul className='dropdown-menu dropdown-menu-end' id='userDropdown'>
                                            <li>
                                                <a
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                    href={configEtherScanUrl(networkId, account)}
                                                    className='dropdown-item rounded'
                                                >
                                                    {t('trackTransaction')}
                                                </a>
                                            </li>
                                            <li>
                                                <NavLink
                                                    rel='noopener noreferrer'
                                                    to='/profile'
                                                    className='dropdown-item rounded'
                                                >
                                                    {t('myProfile')}
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    rel='noopener noreferrer'
                                                    to='/account'
                                                    className='dropdown-item rounded'
                                                >
                                                    {t('myAccount')}
                                                </NavLink>
                                            </li>

                                            {owner === account ? (
                                                <li>
                                                    <NavLink
                                                        rel='noopener noreferrer'
                                                        to='/admin'
                                                        className='dropdown-item rounded'
                                                    >
                                                        {t('adminPanel')}
                                                    </NavLink>
                                                </li>
                                            ) : (
                                                <p className='dropdown-item rounded mb-0 disabled d-flex align-items-center'>
                                                    {t('adminPanel')}
                                                    <span className='badge bg-primary text-xxs p-1 fw-normal ms-2'>
                                                        {t('adminOnly')}
                                                    </span>
                                                </p>
                                            )}
                                        </ul>
                                    </div>
                                ) : (
                                    <div className='d-inline-block'>
                                        <Link to='/signup' className='btn btn-primary btn-sm px-3 flex-shrink-0'>
                                            <HiUserCircle className='me-1' />
                                            {t('signup')}
                                        </Link>
                                    </div>
                                )
                            ) : (
                                <ConnectWalletHander />
                            )}
                        </div>
                    </div>

                    <div className='collapse navbar-collapse justify-content-lg-between' id='navbarSupportedContent'>
                        <div></div>
                        <HeaderSearch />

                        <div className='d-flex align-items-lg-center flex-column flex-lg-row'>
                            {account && (
                                <div className='me-3 flex-shrink-0'>
                                    <Web3Button />
                                </div>
                            )}
                            {account ? (
                                userInfo?.full_name ? (
                                    <div className='dropdown'>
                                        <Link
                                            className='px-0 nav-link dropdown-toggle text-capitalize no-caret d-flex align-items-center text-reset'
                                            id='accountDropdown'
                                            to='/'
                                            role='button'
                                            data-bs-toggle='dropdown'
                                            data-bs-target='#userDropdown'
                                            aria-expanded='false'
                                        >
                                            <UserBlock {...userInfo} />
                                        </Link>
                                        <ul className='dropdown-menu dropdown-menu-lg-end' id='userDropdown'>
                                            <li>
                                                <a
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                    href={configEtherScanUrl(networkId, account)}
                                                    className='dropdown-item rounded'
                                                >
                                                    {t('trackTransaction')}
                                                </a>
                                            </li>
                                            <li>
                                                <NavLink
                                                    rel='noopener noreferrer'
                                                    to='/profile'
                                                    className='dropdown-item rounded'
                                                >
                                                    {t('myProfile')}
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    rel='noopener noreferrer'
                                                    to='/account'
                                                    className='dropdown-item rounded'
                                                >
                                                    {t('myAccount')}
                                                </NavLink>
                                            </li>

                                            {owner === account ? (
                                                <li>
                                                    <NavLink
                                                        rel='noopener noreferrer'
                                                        to='/admin'
                                                        className='dropdown-item rounded'
                                                    >
                                                        {t('adminPanel')}
                                                    </NavLink>
                                                </li>
                                            ) : (
                                                <p className='dropdown-item rounded mb-0 disabled d-flex align-items-center'>
                                                    {t('adminPanel')}
                                                    <span className='badge bg-primary text-xxs p-1 fw-normal ms-2'>
                                                        {t('adminOnly')}
                                                    </span>
                                                </p>
                                            )}
                                        </ul>
                                    </div>
                                ) : (
                                    <div className='d-inline-block mt-3 mt-lg-0 flex-shrink-0'>
                                        <Link to='/signup' className='btn btn-primary btn-sm px-4'>
                                            <HiUserCircle className='me-1' />
                                            {t('signup')}
                                        </Link>
                                    </div>
                                )
                            ) : (
                                <ConnectWalletHander />
                            )}
                        </div>
                    </div>
                </div>
            </nav>
            <BottomBanner />
        </header>
    );
}

export default Navbar;
