import React from 'react';
import { Link } from 'react-router-dom';
import ReadOnlyRating from './ReadOnlyRating';
import { appSettings } from '../../helpers/settings';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, EffectFade, Autoplay } from 'swiper';
import { FaShippingFast } from 'react-icons/fa';
import { BiLinkAlt } from 'react-icons/bi';
import { AiFillEdit, AiOutlineEye } from 'react-icons/ai';
import useWeb3 from '../../hooks/useWeb3';
import useApp from '../../hooks/useApp';
import { truncateStart } from '../../helpers/utils';

function ProductCard({
    productName,
    productOwner,
    productSlug,
    productGallery,
    productCategory,
    productPrice,
    productRating,
    productAvailability,
    productTokenPrice,
    productDescription,
    productShopId,
    isDigital,
}) {
    const { account } = useWeb3();
    const { setProductView, setProductViewSrc } = useApp();
    return (
        <>
            <div
                className={`card mb-0 rounded shop-card product-card shadow-0 ${
                    Number(productAvailability) === 0 ? 'sold' : ''
                }`}
            >
                <div className='card-body p-0'>
                    <div
                        className='swiper-sm-nav position-relative'
                        style={{ background: `url(${productGallery[0]})` }}
                    >
                        <Swiper
                            spaceBetween={0}
                            slidesPerView={1}
                            modules={[Navigation, EffectFade, Autoplay]}
                            navigation
                        >
                            {productGallery?.map((img, index) => {
                                return (
                                    <SwiperSlide key={index}>
                                        <div
                                            className='product-gallery-slide rounded-0 cursor-pointer'
                                            key={index}
                                            style={{ background: `url(${img})` }}
                                        ></div>
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>

                        <div className='product-cta'>
                            {account === productOwner ? (
                                <>
                                    <Link
                                        to={`/${isDigital ? 'digital-products' : 'products'}/update/${productSlug}`}
                                        className='cta-btn'
                                    >
                                        <AiFillEdit size='1.3rem' />
                                    </Link>
                                    <Link
                                        to={`/${isDigital ? 'digital-products' : 'products'}/${productSlug}`}
                                        className='cta-btn'
                                    >
                                        <BiLinkAlt size='1.3rem' />
                                    </Link>
                                </>
                            ) : (
                                <Link
                                    to={`/${isDigital ? 'digital-products' : 'products'}/${productSlug}`}
                                    className='cta-btn'
                                >
                                    <FaShippingFast size='1.3rem' />
                                </Link>
                            )}
                            <button
                                className='cta-btn'
                                onClick={() => {
                                    setProductView(true);
                                    setProductViewSrc({
                                        productName,
                                        productOwner,
                                        productSlug,
                                        productGallery,
                                        productCategory,
                                        productPrice,
                                        productRating,
                                        productAvailability,
                                        productTokenPrice,
                                        productDescription,
                                        productShopId,
                                        isDigital,
                                    });
                                }}
                            >
                                <AiOutlineEye size='1.3rem' />
                            </button>
                        </div>
                    </div>
                    <div className='p-3 px-4'>
                        <Link
                            className='badge bg-primary text-gray-100 small'
                            to={`/${isDigital ? 'digital-products' : 'products'}/category/${productCategory}`}
                        >
                            {productCategory}
                        </Link>
                        <h2 className='h6 font-family-base fw-bold mt-1' style={{ minHeight: '39px' }}>
                            <Link
                                to={`/${isDigital ? 'digital-products' : 'products'}/${productSlug}`}
                                className='text-reset'
                            >
                                {truncateStart(productName, 35)}
                            </Link>
                        </h2>
                        <div className='my-2'>
                            <p className='mb-0'>
                                <strong className='fw-bold text-primary'>${productPrice.toFixed(2)}</strong>{' '}
                            </p>
                            <p className='small'>
                                <span className='badge bg-secondary fw-normal'>
                                    {productTokenPrice.toFixed(2)} {appSettings.currency}
                                </span>
                            </p>
                        </div>
                        <div className='mt-2 d-flex'>
                            <ReadOnlyRating rating={productRating} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProductCard;
