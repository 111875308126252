import React, { useState, useMemo } from 'react';
import { RiShoppingBagFill } from 'react-icons/ri';
import { BsFillCircleFill } from 'react-icons/bs';
import { truncateStart } from '../../helpers/utils';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';

// HOOKS
import useOrder from '../../hooks/useOrder';
import useUser from '../../hooks/useUser';
import { appSettings } from '../../helpers/settings';
import OrderDetailsModal from './OrderDetailsModal';
import ShipOrderForm from './ShipOrderForm';
import useWeb3 from '../../hooks/useWeb3';
import useApp from '../../hooks/useApp';
import { toast } from 'react-toastify';
import DisputeFormModal from './DisputeFormModal';
import { useContractWrite } from 'wagmi';
import { useTranslation } from 'react-i18next';

function VendorOrdersTable() {
    const { orderStatsContract, orderStatsContractAbi, allOrders, orderContractAbi, loadAllOrders } = useOrder();
    const { contract, setTransactionLoading, loadActivities } = useApp();
    const { account } = useWeb3();
    const { userInfo, loadUserInfo } = useUser();
    const [infoRequested, setInfoRequested] = useState(false);
    const [orderInfo, setOrderInfo] = useState(null);
    const [orderInfoId, setOrderInfoId] = useState(null);
    const [shippingRequested, setShippingRequested] = useState(false);
    const [orderToBeShipped, setOrderToBeShipped] = useState(null);
    const [disputeRequested, setDisputeRequested] = useState(false);
    const { t } = useTranslation();

    const vendorOrders = useMemo(() => {
        return allOrders?.filter((order) => order?.orderVendor === userInfo?.address);
    }, [userInfo, allOrders]);

    function handleOrderInfo(
        orderProductSlug,
        orderProductGallery,
        orderProductName,
        unitPrice,
        productAmount,
        orderBuyerName,
        orderBuyerSlug,
        orderBuyerProfile,
        orderBuyerEmail,
        orderBuyerPhone,
        orderStatus,
        shippingAddress,
        totalPrice,
        shippingNotes,
        trackingUrl
    ) {
        setInfoRequested(true);
        setOrderInfo({
            orderProductSlug: orderProductSlug,
            orderProductGallery: orderProductGallery,
            orderProductName: orderProductName,
            unitPrice: unitPrice,
            productAmount: productAmount,
            orderBuyerName: orderBuyerName,
            orderBuyerSlug: orderBuyerSlug,
            orderBuyerProfile: orderBuyerProfile,
            orderBuyerEmail: orderBuyerEmail,
            orderBuyerPhone: orderBuyerPhone,
            orderStatus: orderStatus,
            shippingAddress: shippingAddress,
            totalPrice: totalPrice,
            shippingNotes: shippingNotes,
            trackingUrl: trackingUrl,
        });
    }

    function handleResetInfo() {
        setInfoRequested(false);
        setOrderInfo(null);
    }

    function handleShipping(id) {
        setShippingRequested(true);
        setOrderToBeShipped(id);
    }

    /* --------------------------------------------- 
              CANCEL ORDER FUNCTiON
     --------------------------------------------- */
    const { write: web3CancelOrder } = useContractWrite({
        address: contract?.address,
        abi: orderContractAbi,
        functionName: 'cancelOrder',
        onSuccess() {
            setTimeout(() => {
                setTransactionLoading(false);
                toast.success(`${t('orderCanceled')}`);
                loadUserInfo(contract, account);
                loadActivities(orderStatsContract, orderStatsContractAbi);
                loadAllOrders(orderStatsContract);
            }, 5000);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error(`${t('toastError')}`);
        },
    });

    /* --------------------------------------------- 
          CANCEL ORDER HANDLER
    --------------------------------------------- */
    function cancelOrderHandler(id) {
        web3CancelOrder({
            recklesslySetUnpreparedArgs: [id],
        });
    }

    function handleResetDispute() {
        setDisputeRequested(false);
        setOrderInfoId(null);
    }

    /* --------------------------------------------- 
          OPEN DISPUTE FORM
    --------------------------------------------- */
    function requestDisputeHandler(orderId) {
        setDisputeRequested(true);
        setOrderInfoId({
            orderId: orderId,
        });
    }

    /*** ------------------------------------------------ */
    //      ALL PENDING VIDEOS TABLE COLUMNS
    /*** ------------------------------------------------ */
    const columns = [
        {
            name: t('tbProducts'),
            selector: (row) => row?.orderProductId,
            minWidth: '300px',
            cell: (row) => (
                <div row={row}>
                    <Link className='text-reset' to={`/products/${row?.orderProductSlug}`}>
                        <div className='d-flex align-items-center'>
                            <div
                                className='flex-shrink-0 bg-cover bg-center'
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    background: `url(${row?.orderProductGallery[0]})`,
                                    borderRadius: '0.5rem',
                                }}
                            ></div>
                            <div className='ms-3'>
                                <h6 className='mb-1'>{truncateStart(row?.orderProductName, 30)}</h6>
                                <p className='text-muted small mb-0'>{row?.unitPrice} USD</p>
                            </div>
                        </div>
                    </Link>
                </div>
            ),
        },
        {
            name: t('purchasedAmount'),
            selector: (row) => row?.productAmount,
            minWidth: '200px',
            cell: (row) => <div row={row}>{row?.productAmount}</div>,
        },
        {
            name: t('buyer'),
            minWidth: '300px',
            selector: (row) => row?.orderBuyerName,
            cell: (row) => (
                <div row={row}>
                    <Link to={`/users/${row?.orderBuyerSlug}`} className='text-reset'>
                        <div className='d-flex align-items-center'>
                            <div
                                className='flex-shrink-0 bg-cover bg-center'
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    backgroundImage: `url(${row?.orderBuyerProfile})`,
                                    borderRadius: '0.5rem',
                                }}
                            ></div>
                            <div className='ms-3'>
                                <h6 className='mb-1' style={{ fontSize: '0.9rem' }}>
                                    {row?.orderBuyerName}
                                </h6>
                                <p className='text-muted small mb-0'>{row?.orderBuyerEmail}</p>
                            </div>
                        </div>
                    </Link>
                </div>
            ),
        },
        {
            name: t('buyerPhoneNumber'),
            minWidth: '200px',
            selector: (row) => row?.orderBuyerPhone,
            cell: (row) => (
                <div row={row}>
                    <span className='text-sm'>{row?.orderBuyerPhone}</span>
                </div>
            ),
        },
        {
            name: t('orderStatus'),
            minWidth: '120px',
            selector: (row) => row?.orderStatus,
            cell: (row) => (
                <div row={row}>
                    <span className={`order-status ${row?.orderStatus}`}>
                        <BsFillCircleFill className='me-2 mb-1' size={10} />
                        {row?.orderStatus}
                    </span>
                </div>
            ),
        },
        {
            name: t('shippingAddress'),
            minWidth: '300px',
            selector: (row) => row?.shippingAddress,
            cell: (row) => (
                <div row={row}>
                    <div>{row?.shippingAddress}</div>
                </div>
            ),
        },
        {
            name: `${t('totalOrderPriceIs')} ${appSettings?.currency}`,
            minWidth: '200px',
            selector: (row) => row?.totalPrice,
            cell: (row) => (
                <div row={row}>
                    <div>
                        {(Number(row?.totalPrice) + Number(row?.shippingPrice)).toFixed(3)} {appSettings.currency}
                    </div>
                </div>
            ),
        },
        {
            name: t('disputes'),
            minWidth: '200px',
            selector: (row) => row?.orderId,
            cell: (row) => (
                <div row={row}>
                    <div>
                        {row?.orderStatus === 'shipped' ? (
                            <button
                                className='btn btn-primary btn-sm px-3'
                                onClick={() => {
                                    requestDisputeHandler(row?.orderId);
                                }}
                            >
                                {t('makeDispute')}
                            </button>
                        ) : (
                            <button className='btn btn-primary btn-sm px-3' disabled={true} style={{ opacity: '0.2' }}>
                                {t('makeDispute')}
                            </button>
                        )}
                    </div>
                </div>
            ),
        },
        {
            name: 'Actions',
            minWidth: '600px',
            selector: (row) => row?.orderId,
            cell: (row) => (
                <div row={row}>
                    <ul className='list-inline mb-0'>
                        <li className='list-inline-item m-1'>
                            <button
                                className='btn btn-primary btn-sm px-3'
                                onClick={() => {
                                    handleOrderInfo(
                                        row?.orderProductSlug,
                                        row?.orderProductGallery,
                                        row?.orderProductName,
                                        row?.unitPrice,
                                        row?.productAmount,
                                        row?.orderBuyerName,
                                        row?.orderBuyerSlug,
                                        row?.orderBuyerProfile,
                                        row?.orderBuyerEmail,
                                        row?.orderBuyerPhone,
                                        row?.orderStatus,
                                        row?.shippingAddress,
                                        row?.totalPrice,
                                        row?.shippingNotes,
                                        row?.trackingUrl
                                    );
                                }}
                            >
                                {t('viewAllDetails')}
                            </button>
                        </li>
                        {row?.orderStatus === 'pending' && (
                            <li className='list-inline-item m-1'>
                                <button
                                    className='btn btn-info btn-sm px-3'
                                    onClick={() => {
                                        handleShipping(row?.orderId);
                                    }}
                                >
                                    {t('shipOrder')}
                                </button>
                            </li>
                        )}
                        {row?.orderStatus !== 'delivered' && row?.orderStatus !== 'canceled' && (
                            <li className='list-inline-item m-1'>
                                <button
                                    className='btn btn-secondary btn-sm px-3'
                                    onClick={() => {
                                        cancelOrderHandler(row?.orderId);
                                    }}
                                >
                                    {t('cancelOrder')}
                                </button>
                            </li>
                        )}
                    </ul>
                </div>
            ),
        },
    ];

    return (
        <>
            <div className='card shadow-lg mb-0' data-aos='fade-up' data-aos-delay='200'>
                <div className='card-body p-lg-5'>
                    <div className='d-flex mb-5'>
                        <div className='stats-icon solid-cyan'>
                            <RiShoppingBagFill size='1.4rem' />
                        </div>
                        <div className='ms-3'>
                            <h2 className='mb-0 h4'>{t('allOrders')}</h2>
                            <p className='text-muted fw-normal mb-0'></p>
                        </div>
                    </div>

                    {vendorOrders && vendorOrders?.length > 0 ? (
                        <DataTable
                            columns={columns}
                            data={vendorOrders}
                            pagination={vendorOrders.length >= 1 && true}
                            responsive
                            theme='solarized'
                        />
                    ) : (
                        <p className='mb-0'> {t('youDontHaveOrders')}</p>
                    )}
                </div>
            </div>
            <OrderDetailsModal infoRequested={infoRequested} setInfoRequested={handleResetInfo} orderInfo={orderInfo} />
            <ShipOrderForm
                shippingRequested={shippingRequested}
                setShippingRequested={setShippingRequested}
                orderId={orderToBeShipped}
            />
            <DisputeFormModal
                disputeRequested={disputeRequested}
                setDisputeRequested={handleResetDispute}
                orderInfo={orderInfoId}
            />
        </>
    );
}

export default VendorOrdersTable;
