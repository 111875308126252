import React from 'react';
import { IoWallet } from 'react-icons/io5';
import { appSettings } from '../../helpers/settings';
import { toast } from 'react-toastify';
import useApp from '../../hooks/useApp';
import useWeb3 from '../../hooks/useWeb3';
import useUser from '../../hooks/useUser';
import { useContractWrite } from 'wagmi';
import useShop from '../../hooks/useShop';
import { useTranslation } from 'react-i18next';

function UserProfits({ profits }) {
    const { contract, setTransactionLoading } = useApp();
    const { usdToken } = useShop();
    const { userContract, loadUsersList, loadUserInfo, userContractAbi } = useUser();
    const { account } = useWeb3();
    const { userInfo } = useUser();
    const { t } = useTranslation();

    /* --------------------------------------------- 
              CLAIM PROFITS HANDLER
     --------------------------------------------- */
    const { write: web3ClaimProfits } = useContractWrite({
        address: contract?.address,
        abi: userContractAbi,
        functionName: 'claimFunds',
        onSuccess() {
            setTimeout(() => {
                setTransactionLoading(false);
                loadUsersList(userContract);
                loadUserInfo(contract, account);
                toast.success(`${t('earningClaimed')}`);
            }, 5000);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error(`${t('toastError')}`);
        },
    });

    /* --------------------------------------------- 
          CLAIM EARNINGS HANDLER
    --------------------------------------------- */
    function claimEarningsHandler() {
        web3ClaimProfits();
    }

    return (
        <>
            <div className='row'>
                <div className='col-lg-6'>
                    {profits !== '0' ? (
                        <div>
                            {userInfo?.is_vendor ? (
                                <h2>{t('youEarnedMoney')}</h2>
                            ) : (
                                <h2>{t('youHaveReturnedCredit')} </h2>
                            )}
                            <div className='text-muted mb-3'>
                                Your {userInfo?.is_vendor ? 'current earnings' : 'returned credit'} are
                                <p className='h5 text-white mb-0 mx-2'>
                                    {userInfo?.is_vendor ? (
                                        <>{(Number(profits) / usdToken).toFixed(2)} USD</>
                                    ) : (
                                        <>
                                            {Number(profits).toFixed(3)} {appSettings?.currency}
                                        </>
                                    )}
                                </p>
                            </div>
                            <button className='btn btn-primary px-4' type='button' onClick={claimEarningsHandler}>
                                <IoWallet size={20} className='me-2 mb-1' />
                                {userInfo?.is_vendor ? `${t('claim')}` : `${t('withdraw')}`} {t('your')}{' '}
                                {userInfo?.is_vendor ? `${t('earnings')}` : `${t('credit')}`}
                            </button>
                        </div>
                    ) : (
                        <div>
                            {userInfo?.is_vendor ? <h2>{t('tryToSell')}</h2> : <h2>{t('noReturnedCredit')}</h2>}
                            <p className='text-muted'>
                                {t('thereAreNo')} {userInfo?.is_vendor ? 'earnings' : 'returned credit'}{' '}
                                {t('atTheMoment')}.
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default UserProfits;
