import React, { useMemo } from 'react';
import useShop from '../../hooks/useShop';
import ProductCard from '../../components/general/ProductCard';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper';

function NewArrivals() {
    const { allProducts } = useShop();
    const { t } = useTranslation();

    const newArrivals = useMemo(() => {
        return allProducts?.sort((a, b) => b.productId - a.productId)?.slice(0, 10);
    }, [allProducts]);

    return (
        <>
            {newArrivals && newArrivals?.length > 0 && (
                <section className='py-5'>
                    <div className='container py-5'>
                        <header>
                            <h2 className='mb-0' data-aos='fade-up'>
                                {t('newArrivals')}
                            </h2>
                            <p className='text-muted' data-aos='fade-up' data-aos-delay='100'></p>
                        </header>

                        <div className='swiper-wrapper-padding-nav'>
                            <Swiper
                                breakpoints={{
                                    560: {
                                        slidesPerView: 2,
                                        spaceBetween: 10,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                        spaceBetween: 10,
                                    },
                                    1200: {
                                        slidesPerView: 3,
                                        spaceBetween: 10,
                                    },
                                    1400: {
                                        slidesPerView: 5,
                                        spaceBetween: 10,
                                    },
                                }}
                                modules={[Navigation, Autoplay]}
                                speed={1000}
                                autoplay={{
                                    delay: 3000,
                                    disableOnInteraction: false,
                                }}
                                navigation
                            >
                                {newArrivals?.map((product, index) => {
                                    return (
                                        <SwiperSlide key={index}>
                                            <div data-aos='fade-up' data-aos-delay={index * 50}>
                                                <ProductCard {...product} />
                                            </div>
                                        </SwiperSlide>
                                    );
                                })}
                            </Swiper>
                        </div>
                    </div>
                </section>
            )}
        </>
    );
}

export default NewArrivals;
