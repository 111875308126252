import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { appSettings } from '../helpers/settings';
import { ToastContainer, Flip } from 'react-toastify';
import AOS from 'aos';

// 3RD-PARTY STYLES
import 'react-toastify/dist/ReactToastify.css';
import 'react-h5-audio-player/lib/styles.css';
import 'react-tippy/dist/tippy.css';
import 'react-range-slider-input/dist/style.css';

// HOOKS
import useWeb3 from '../hooks/useWeb3';
import useOrder from '../hooks/useOrder';
import useDf from '../hooks/useDf';

// CONTRACT ABIs
import MarketplaceAbi from '../contracts/Marketplace.json';
import TokenAbi from '../integration/TokenAbi.json';
import OrdersStatsAbi from '../contracts/OrdersStatistics.json';
import OrderAbi from '../contracts/OrderContract.json';
import MembershipAbi from '../contracts/MembershipContract.json';
import DigitalFilesAbi from '../contracts/DigitalFilesContract.json';

// COMPONENTS
import Header from '../components/general/Header';
import Footer from '../components/general/Footer';
import ScrollToTop from '../components/general/ScrollToTop';
import MetaMaskLoader from '../components/general/MetaMaskLoader';
import useApp from '../hooks/useApp';
import useShop from '../hooks/useShop';
import useUser from '../hooks/useUser';
import RegisterPopup from '../components/general/RegisterPopup';
import VerifyPopup from '../components/general/EmailVerify';
import MobileNav from '../components/general/MobileNav';

import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/react';
import { configureChains, createClient, WagmiConfig, useNetwork } from 'wagmi';
import { base } from 'wagmi/chains';
import { getAccount, watchAccount, getContract } from '@wagmi/core';
import ViewOnlyAlert from '../components/general/ViewOnlyAlert';
import Popup from '../components/general/Popup';
import ProductPreview from '../components/general/ProductPreview';

const chains = [base];
const projectId = appSettings.wcProjectId;

const { provider, webSocketProvider } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiClient = createClient({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, version: 2, chains }),
    provider,
    webSocketProvider,
});
const ethereumClient = new EthereumClient(wagmiClient, chains);

function Layout() {
    const { account, loadAccount } = useWeb3();
    const {
        loadContract,
        contract,
        transactionLoading,
        loadActivities,
        loadAppOwner,
        getContractAbi,
        abi,
        productView,
        setProductView,
		loadPaymentTokenPriceInUSD: loadPaymentToken2PriceInUSD,
    } = useApp();
    const {
        shopContract,
        paymentToken,
        membershipContract,
        loadStToken,
        getMemberShopContractAbi,
        loadMembershipContract,
        loadShopContract,
        loadAllShops,
        loadAllProducts,
        loadUserShops,
        loadPaymentTokenAddress,
        loadPaymentTokenContract,
        loadPaymentTokenBalance,
        getShopContractAbi,
        loadPaymentTokenPriceInUSD,
        loadPromotionPrice,
        loadShopCreationCost,
        loadGoldCheckCost,
        loadShopVerifyCost,
        loadDigitalFiles,
    } = useShop();
    const {
        orderStatsContract,
        orderStatsContractAbi,
        loadOrderContract,
        loadStatsOrderContract,
        loadAllOrders,
        loadAllDisputes,
        getOrderContractAbi,
        getOrderStatsContractAbi,
        getOrderOnlyContractAbi,
        loadOrderOnlyContract,
    } = useOrder();
    const { dfContract, dfAbi, loadDfContract, loadDfAbi } = useDf();
    const {
        registerIndicator,
        userInfo,
        userContract,
        loadUserContract,
        loadUserInfo,
        loadUsersList,
        getUserContractAbi,
    } = useUser();
    const { chain } = useNetwork();

    /*** ------------------------------------------------ */
    //      LOAD INITIAL APP DATA
    /*** ------------------------------------------------ */
    useEffect(() => {
        if (orderStatsContract) {
            loadActivities(orderStatsContract, orderStatsContractAbi);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderStatsContract]);

    useEffect(() => {
        if (contract) {
            loadAppOwner(contract);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contract]);

    /* ---------------------------------------------
          LOAD USER INFORMATION
    --------------------------------------------- */
    useEffect(() => {
        if (userContract) {
            account && loadUserInfo(userContract, account);
            loadUsersList(userContract);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, userContract]);

    /* ---------------------------------------------
          LOAD DIGITAL FILES
    --------------------------------------------- */
    useEffect(() => {
        if (dfContract) {
            loadDigitalFiles(dfContract, dfAbi);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dfContract]);

    /* ---------------------------------------------
          LOAD SHOP INFORMATION
    --------------------------------------------- */
    useEffect(() => {
        if (shopContract) {
            loadAllShops(shopContract);
            loadAllProducts(shopContract);
            account && loadUserShops(shopContract, account);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, shopContract]);

    /* --------------------------------------------- 
          GET TOKEN PRICE IN USD
    --------------------------------------------- */
	/* --------------------------------------------- 
          GET TOKEN PRICE IN USD
    --------------------------------------------- */
    useEffect(() => {
        loadPaymentTokenPriceInUSD(appSettings?.tokenId);
        loadPaymentToken2PriceInUSD(appSettings?.token2Id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appSettings?.tokenId, appSettings?.token2Id]);
	
    // /* ---------------------------------------------
    //       LOAD ORDER INFORMATION
    // --------------------------------------------- */
    useEffect(() => {
        if (orderStatsContract) {
            loadAllOrders(orderStatsContract);
            loadAllDisputes(orderStatsContract);
            loadPromotionPrice(orderStatsContract, OrdersStatsAbi.abi);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderStatsContract]);

    /*** -------------------------------------------- */
    //      GET PAYMENT TOKEN CONTRACT
    /*** -------------------------------------------- */
    useEffect(() => {
        if (paymentToken && account) {
            loadPaymentTokenContract(paymentToken, TokenAbi);
            loadPaymentTokenBalance(paymentToken, TokenAbi, account);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentToken, account]);

    /*** -------------------------------------------- */
    //      GET PAYMENT TOKEN ADDRESS
    /*** -------------------------------------------- */
    useEffect(() => {
        if (shopContract) {
            loadPaymentTokenAddress(shopContract);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shopContract]);

    /*** -------------------------------------------- */
    //      GET SHOP CREATION COST
    /*** -------------------------------------------- */
    useEffect(() => {
        if (membershipContract) {
            loadShopCreationCost(membershipContract, MembershipAbi.abi);
            loadGoldCheckCost(membershipContract, MembershipAbi.abi);
            loadShopVerifyCost(membershipContract, MembershipAbi.abi);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [membershipContract]);

    /*** -------------------------------------------- */
    //      GET SECONDARY TOKEN ADDRESS
    /*** -------------------------------------------- */
    useEffect(() => {
        if (membershipContract && account) {
            loadStToken(membershipContract, MembershipAbi.abi, account);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [membershipContract, account]);

    /* -------------------------------------------------- */
    //      AOS ANIMATION
    /* -------------------------------------------------- */
    useEffect(() => {
        AOS.init({
            duration: 700,
            once: true,
        });
    }, []);

    /*** -------------------------------------------- */
    //      GET BLOCKCHAIN DATA
    /*** -------------------------------------------- */
    useEffect(() => {
        const calclateInitialSettings = async () => {
            const acc = getAccount();
            loadAccount(acc?.address);
            getContractAbi(MarketplaceAbi.abi);
            getUserContractAbi(MarketplaceAbi.abi);
            getShopContractAbi(MarketplaceAbi.abi);
            getOrderContractAbi(MarketplaceAbi.abi);
            getOrderStatsContractAbi(OrdersStatsAbi.abi);
            getOrderOnlyContractAbi(OrderAbi.abi);
            getMemberShopContractAbi(MembershipAbi.abi);
            loadDfAbi(DigitalFilesAbi.abi);

            const appDeployedNetwork = MarketplaceAbi.networks[appSettings?.networkId];
            const orderStatsDeployedNetwork = OrdersStatsAbi.networks[appSettings?.networkId];
            const orderDeployedNetwork = OrderAbi.networks[appSettings?.networkId];
            const membershipDeployedNetwork = MembershipAbi.networks[appSettings?.networkId];
            const dfDeployedNetwork = DigitalFilesAbi.networks[appSettings?.networkId];

            const mainContract =
                appDeployedNetwork &&
                getContract({
                    address: appDeployedNetwork.address,
                    abi: MarketplaceAbi.abi,
                });

            const statsContract =
                orderStatsDeployedNetwork &&
                getContract({
                    address: orderStatsDeployedNetwork.address,
                    abi: OrdersStatsAbi.abi,
                });

            const orderContract =
                orderDeployedNetwork &&
                getContract({
                    address: orderDeployedNetwork.address,
                    abi: OrderAbi.abi,
                });

            const membershipContract =
                membershipDeployedNetwork &&
                getContract({
                    address: membershipDeployedNetwork.address,
                    abi: MembershipAbi.abi,
                });

            const digitalFilesContract =
                dfDeployedNetwork &&
                getContract({
                    address: dfDeployedNetwork.address,
                    abi: DigitalFilesAbi.abi,
                });

            console.log(digitalFilesContract);

            loadUserContract(mainContract);
            loadShopContract(mainContract);
            loadContract(mainContract);
            loadOrderContract(mainContract);
            loadStatsOrderContract(statsContract);
            loadOrderOnlyContract(orderContract);
            loadMembershipContract(membershipContract);
            loadDfContract(digitalFilesContract);

            // eslint-disable-next-line no-unused-vars
            const unwatch = watchAccount((account) => {
                loadAccount(account?.address);
            });

            if (mainContract && abi) {
                loadAppOwner(mainContract);
            } else {
                return;
            }
        };
        calclateInitialSettings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <WagmiConfig client={wagmiClient}>
                <div className='app pb-0'>
                    <Header />
                    <ScrollToTop />
                    <Outlet />
                    <Footer />
                    <MobileNav />
                    {chain?.id !== appSettings.networkId && <ViewOnlyAlert />}
                </div>
                {userInfo?.full_name && !userInfo?.is_verified && <VerifyPopup />}
                <ToastContainer position='top-center' autoClose={1500} transition={Flip} />
                {registerIndicator && chain?.id === appSettings.networkId && <RegisterPopup />}
                {transactionLoading && <MetaMaskLoader />}
                {productView && (
                    <Popup closeModal={() => setProductView(false)}>
                        <ProductPreview />
                    </Popup>
                )}
            </WagmiConfig>
            <Web3Modal
                projectId={projectId}
                ethereumClient={ethereumClient}
                themeVariables={{
                    '--w3m-z-index': '9999',
                    '--w3m-container-background-color': 'rgba(0,0,0,.7)',
                }}
            />
        </>
    );
}

export default Layout;
