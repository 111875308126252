import React, { useState } from 'react';
import { RiShoppingBagFill } from 'react-icons/ri';
import { BsFillCircleFill } from 'react-icons/bs';
import { truncateStart } from '../../helpers/utils';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';

// HOOKS
import useOrder from '../../hooks/useOrder';
import { appSettings } from '../../helpers/settings';
import OrderDetailsModal from './OrderDetailsModal';
import { useTranslation } from 'react-i18next';

function AllOrdersTable() {
    const { allOrders } = useOrder();
    const [infoRequested, setInfoRequested] = useState(false);
    const [orderInfo, setOrderInfo] = useState(null);
    const { t } = useTranslation();

    function handleOrderInfo(
        orderProductSlug,
        orderProductGallery,
        orderProductName,
        unitPrice,
        productAmount,
        orderBuyerName,
        orderBuyerSlug,
        orderBuyerProfile,
        orderBuyerEmail,
        orderBuyerPhone,
        orderStatus,
        shippingAddress,
        totalPrice,
        shippingNotes,
        trackingUrl
    ) {
        setInfoRequested(true);
        setOrderInfo({
            orderProductSlug: orderProductSlug,
            orderProductGallery: orderProductGallery,
            orderProductName: orderProductName,
            unitPrice: unitPrice,
            productAmount: productAmount,
            orderBuyerName: orderBuyerName,
            orderBuyerSlug: orderBuyerSlug,
            orderBuyerProfile: orderBuyerProfile,
            orderBuyerEmail: orderBuyerEmail,
            orderBuyerPhone: orderBuyerPhone,
            orderStatus: orderStatus,
            shippingAddress: shippingAddress,
            totalPrice: totalPrice,
            shippingNotes: shippingNotes,
            trackingUrl: trackingUrl,
        });
    }

    /* --------------------------------------------- 
          RESET ORDER MODAL INFORMATION ON CLOSE 
    --------------------------------------------- */
    function handleResetInfo() {
        setInfoRequested(false);
        setOrderInfo(null);
    }

    /*** ------------------------------------------------ */
    //      ALL PENDING VIDEOS TABLE COLUMNS
    /*** ------------------------------------------------ */
    const columns = [
        {
            name: t('tbProducts'),
            selector: (row) => row?.orderProductId,
            minWidth: '300px',
            cell: (row) => (
                <div row={row}>
                    <Link className='text-reset' to={`/products/${row?.orderProductSlug}`}>
                        <div className='d-flex align-items-center'>
                            <div
                                className='flex-shrink-0 bg-cover bg-center'
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    background: `url(${row?.orderProductGallery[0]})`,
                                    borderRadius: '0.5rem',
                                }}
                            ></div>
                            <div className='ms-3'>
                                <h6 className='mb-1'>{truncateStart(row?.orderProductName, 30)}</h6>
                                <p className='text-muted small mb-0'>
                                    {row?.unitPrice} {appSettings.currency}
                                </p>
                            </div>
                        </div>
                    </Link>
                </div>
            ),
        },
        {
            name: t('purchasedAmount'),
            selector: (row) => row?.productAmount,
            minWidth: '200px',
            cell: (row) => <div row={row}>{row?.productAmount}</div>,
        },
        {
            name: t('buyer'),
            minWidth: '300px',
            selector: (row) => row?.orderBuyerName,
            cell: (row) => (
                <div row={row}>
                    <Link to={`/users/${row?.orderBuyerSlug}`} className='text-reset'>
                        <div className='d-flex align-items-center'>
                            <div
                                className='flex-shrink-0 bg-cover bg-center'
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    backgroundImage: `url(${row?.orderBuyerProfile})`,
                                    borderRadius: '0.5rem',
                                }}
                            ></div>
                            <div className='ms-3'>
                                <h6 className='mb-1' style={{ fontSize: '0.9rem' }}>
                                    {row?.orderBuyerName}
                                </h6>
                                <p className='text-muted small mb-0'>{row?.orderBuyerEmail}</p>
                            </div>
                        </div>
                    </Link>
                </div>
            ),
        },
        {
            name: t('buyerPhoneNumber'),
            minWidth: '200px',
            selector: (row) => row?.orderBuyerPhone,
            cell: (row) => (
                <div row={row}>
                    <span className='text-sm'>{row?.orderBuyerPhone}</span>
                </div>
            ),
        },
        {
            name: 'Order Status',
            minWidth: '120px',
            selector: (row) => row?.orderStatus,
            cell: (row) => (
                <div row={row}>
                    <span className={`order-status ${row?.orderStatus}`}>
                        <BsFillCircleFill className='me-2 mb-1' size={10} />
                        {row?.orderStatus}
                    </span>
                </div>
            ),
        },
        {
            name: 'Shipping Address',
            minWidth: '300px',
            selector: (row) => row?.shippingAddress,
            cell: (row) => (
                <div row={row}>
                    <div>{row?.shippingAddress}</div>
                </div>
            ),
        },
        {
            name: 'Total Order Price',
            minWidth: '200px',
            selector: (row) => row?.totalPrice,
            cell: (row) => (
                <div row={row}>
                    <div>
                        {row?.totalPrice} {appSettings.currency}
                    </div>
                </div>
            ),
        },
        {
            name: 'Actions',
            minWidth: '200px',
            selector: (row) => row?.orderId,
            cell: (row) => (
                <div row={row}>
                    <button
                        className='btn btn-primary btn-sm px-4'
                        onClick={() => {
                            handleOrderInfo(
                                row?.orderProductSlug,
                                row?.orderProductGallery,
                                row?.orderProductName,
                                row?.unitPrice,
                                row?.productAmount,
                                row?.orderBuyerName,
                                row?.orderBuyerSlug,
                                row?.orderBuyerProfile,
                                row?.orderBuyerEmail,
                                row?.orderBuyerPhone,
                                row?.orderStatus,
                                row?.shippingAddress,
                                row?.totalPrice,
                                row?.shippingNotes,
                                row?.trackingUrl
                            );
                        }}
                    >
                        {t('viewAllDetails')}
                    </button>
                </div>
            ),
        },
    ];

    return (
        <>
            <div className='card shadow-lg mb-0' data-aos='fade-up' data-aos-delay='200'>
                <div className='card-body p-lg-5'>
                    <div className='d-flex mb-5'>
                        <div className='stats-icon solid-cyan'>
                            <RiShoppingBagFill size='1.4rem' />
                        </div>
                        <div className='ms-3'>
                            <h2 className='mb-0 h4'>{t('allOrders')}</h2>
                        </div>
                    </div>

                    <DataTable
                        columns={columns}
                        data={allOrders}
                        pagination={allOrders.length >= 1 && true}
                        responsive
                        theme='solarized'
                    />
                </div>
            </div>
            <OrderDetailsModal infoRequested={infoRequested} setInfoRequested={handleResetInfo} orderInfo={orderInfo} />
        </>
    );
}

export default AllOrdersTable;
