import React from 'react';
import useUser from '../../hooks/useUser';
import { Tooltip } from 'react-tippy';
import { MdVerified } from 'react-icons/md';
import { BsPatchCheckFill } from 'react-icons/bs';
import { TiInfo } from 'react-icons/ti';
import { formatString } from '../../helpers/utils';
import { useTranslation } from 'react-i18next';

function InfoGrid({ requestUpdate }) {
    const { userInfo } = useUser();
    const { t } = useTranslation();
    return (
        <>
            {Object.keys(userInfo)
                ?.filter(
                    (key) =>
                        key !== 'is_verified' &&
                        key !== 'verification_code' &&
                        key !== 'user_slug' &&
                        key !== 'rating' &&
                        key !== 'vip' &&
                        key !== 'profits'
                )
                .map((key, index) => {
                    return (
                        <div
                            className={`col-lg-${key === 'profile_picture' ? '12' : '6'}`}
                            data-aos='fade-up'
                            data-aos-delay={index * 50}
                            key={index}
                        >
                            <div className='card mb-0'>
                                <div
                                    className={`card-body ${
                                        key === 'profile_picture' ? 'd-flex flex-column align-items-center' : ''
                                    }`}
                                >
                                    <h6>
                                        {key === 'is_vendor' ? (
                                            'Registred as a vendor'
                                        ) : key === 'email_address' && userInfo?.vip ? (
                                            <>
                                                {formatString(key)}
                                                <Tooltip title='Verified' position='top' trigger='mouseenter'>
                                                    <BsPatchCheckFill className='ms-2 text-gold' />
                                                </Tooltip>
                                            </>
                                        ) : key === 'email_address' && !userInfo?.vip && userInfo?.is_verified ? (
                                            <>
                                                {formatString(key)}
                                                <Tooltip title='Verified' position='top' trigger='mouseenter'>
                                                    <MdVerified className='ms-2 text-success' />
                                                </Tooltip>
                                            </>
                                        ) : key === 'email_address' && !userInfo?.is_verified ? (
                                            <>
                                                {formatString(key)}
                                                <Tooltip title='Not Verified' position='top' trigger='mouseenter'>
                                                    <TiInfo className='ms-2 text-warning' />
                                                </Tooltip>
                                            </>
                                        ) : (
                                            formatString(key)
                                        )}
                                    </h6>
                                    {key === 'profile_picture' ? (
                                        <div className='user-avatar-pic-outer mt-2'>
                                            <div
                                                className='user-avatar-pic'
                                                style={{ backgroundImage: `url(${userInfo[key]})` }}
                                            ></div>
                                        </div>
                                    ) : key === 'is_vendor' ? (
                                        <p className='mb-0'>{userInfo[key] ? 'Yes' : 'No'}</p>
                                    ) : key === 'address' ? (
                                        <p className='mb-0 text-sm'>{userInfo[key]}</p>
                                    ) : (
                                        <p className='mb-0'>{userInfo[key]}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                })}
            <div className='col-12' data-aos='fade-up' data-aos-delay='500'>
                <button className='btn btn-primary w-100' onClick={requestUpdate}>
                    {t('updateYourInfo')}
                </button>
            </div>
        </>
    );
}

export default InfoGrid;
