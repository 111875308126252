import React from 'react';
import { HiUsers } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { MdVerified } from 'react-icons/md';
import { TiInfo } from 'react-icons/ti';
import { selectInputText } from '../../helpers/utils';
import { useTranslation } from 'react-i18next';

// HOOKS
import useUser from '../../hooks/useUser';

function AllUsersTable() {
    const { usersList } = useUser();
    const { t } = useTranslation();

    /*** ------------------------------------------------ */
    //      ALL PENDING VIDEOS TABLE COLUMNS
    /*** ------------------------------------------------ */
    const columns = [
        {
            name: 'User',
            minWidth: '300px',
            selector: (row) => row?.address,
            cell: (row) => (
                <div row={row}>
                    <Link to={`/users/${row?.user_slug}`} className='text-reset'>
                        <div className='d-flex align-items-center'>
                            <div
                                className='flex-shrink-0 bg-cover bg-center'
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    backgroundImage: `url(${row?.profile_picture})`,
                                    borderRadius: '0.5rem',
                                }}
                            ></div>
                            <div className='ms-3'>
                                <h6 className='mb-1' style={{ fontSize: '0.9rem' }}>
                                    {row?.full_name}
                                </h6>
                                <p className='text-muted small mb-0'>{row?.email_address}</p>
                            </div>
                        </div>
                    </Link>
                </div>
            ),
        },
        {
            name: 'Phone Number',
            minWidth: '200px',
            selector: (row) => row?.phone_number,
            cell: (row) => (
                <div row={row}>
                    <span className='text-sm'>{row?.phone_number}</span>
                </div>
            ),
        },
        {
            name: 'User Type',
            minWidth: '90px',
            selector: (row) => row?.is_vendor,
            cell: (row) => <div row={row}>{row?.is_vendor ? 'Vendor' : 'Buyer'}</div>,
        },
        {
            name: 'Wallet Address',
            minWidth: '400px',
            selector: (row) => row?.address,
            cell: (row) => (
                <div row={row}>
                    <div style={{ fontSize: '0.8rem' }}>{row?.address}</div>
                </div>
            ),
        },
        {
            name: 'Verification Code',
            minWidth: '200px',
            selector: (row) => row?.verification_code,
            cell: (row) => (
                <div row={row}>
                    <div style={{ fontSize: '0.8rem' }}>
                        <input
                            type='text'
                            onFocus={(e) => selectInputText(e)}
                            defaultValue={row?.verification_code}
                            className='form-control form-control-sm'
                            style={{ minWidth: '10rem' }}
                        />
                    </div>
                </div>
            ),
        },
        {
            name: 'Verified',
            minWidth: '200px',
            selector: (row) => row?.is_verified,
            cell: (row) => (
                <div row={row}>
                    {row?.is_verified ? (
                        <div className='verified-badge'>
                            {t('verified')} <MdVerified className='ms-1 mb-1' />
                        </div>
                    ) : (
                        <div className='danger-badge'>
                            {t('notVerified')} <TiInfo className='ms-1 mb-1' />
                        </div>
                    )}
                </div>
            ),
        },
        {
            name: 'VIP',
            minWidth: '200px',
            selector: (row) => row?.vip,
            cell: (row) => (
                <div row={row}>
                    {row?.vip ? (
                        <div className='gold-badge'>
                            VIP Member <MdVerified className='ms-1 mb-1' />
                        </div>
                    ) : (
                        <div className='verified-badge'>Regular Member</div>
                    )}
                </div>
            ),
        },
        {
            name: 'Location',
            minWidth: '200px',
            selector: (row) => row?.location,
            cell: (row) => <div row={row}>{row?.location}</div>,
        },
    ];

    return (
        <div className='card shadow-lg mb-0' data-aos='fade-up' data-aos-delay='200'>
            <div className='card-body p-lg-5'>
                <div className='d-flex mb-5'>
                    <div className='stats-icon solid-red'>
                        <HiUsers size='1.4rem' />
                    </div>
                    <div className='ms-3'>
                        <h2 className='mb-0 h4'>{t('allUsers')}</h2>
                        <p className='text-muted fw-normal mb-0'>
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                        </p>
                    </div>
                </div>

                <DataTable
                    columns={columns}
                    data={usersList}
                    pagination={usersList.length >= 1 && true}
                    responsive
                    theme='solarized'
                />
            </div>
        </div>
    );
}

export default AllUsersTable;
