import React, { useState, useEffect } from 'react';
import { FiPercent } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { useContractWrite } from 'wagmi';

// HOOKS
import useOrder from '../../hooks/useOrder';
import useApp from '../../hooks/useApp';
import { useTranslation } from 'react-i18next';

function SetCommissionPrice() {
    const { orderOnlyContract, orderOnlyContractAbi, commissionPercentage, loadCommissionPercentage } = useOrder();
    const { setTransactionLoading } = useApp();
    const [commission, setCommission] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (orderOnlyContract) {
            loadCommissionPercentage(orderOnlyContract, orderOnlyContractAbi);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderOnlyContract, orderOnlyContractAbi]);

    useEffect(() => {
        if (orderOnlyContract && commissionPercentage) {
            setCommission(commissionPercentage);
        }
    }, [orderOnlyContract, commissionPercentage]);

    /* --------------------------------------------- 
              TRANSFER OWNERSHIP FUNCTION
     --------------------------------------------- */
    const { write: setCommissionPecentage } = useContractWrite({
        address: orderOnlyContract?.address,
        abi: orderOnlyContractAbi,
        functionName: 'setCommission',
        onSuccess() {
            setTimeout(() => {
                setTransactionLoading(false);
                setSubmitted(false);
                setCommission(0);
                loadCommissionPercentage(orderOnlyContract, orderOnlyContractAbi);
                toast.success(`${t('commissionIsSet')}`);
            }, 5000);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error(`${t('toastError')}`);
        },
    });

    function setCommissionPercentageHandler(e) {
        e.preventDefault();
        setSubmitted(true);
        if (commission !== '' && commission) {
            const formattedPrice = Number(commission * 10);
            setCommissionPecentage({
                recklesslySetUnpreparedArgs: [formattedPrice],
            });
        }
    }

    return (
        <div className='card shadow-lg mb-0' data-aos='fade-up' data-aos-delay='200'>
            <div className='card-body p-lg-5'>
                <div className='d-flex mb-5'>
                    <div className='stats-icon solid-turquoise'>
                        <FiPercent size='1.4rem' />
                    </div>
                    <div className='ms-3'>
                        <h2 className='mb-0 h4'>{t('setCommission')}</h2>
                        <p className='text-muted fw-normal mb-0'>
                            {t('commissionPercentageIs')}{' '}
                            <strong className='fw-bold text-primary'>{commissionPercentage} %</strong>
                        </p>
                    </div>
                </div>

                <form onSubmit={setCommissionPercentageHandler}>
                    <div className='row'>
                        <div className='col-lg-7'>
                            <div className='d-flex align-items-end'>
                                <div className='me-2 w-100'>
                                    <label className='form-label' htmlFor='promotionPrice'>
                                        {t('commissionPercentage')}
                                    </label>
                                    <input
                                        type='number'
                                        id='promotionPrice'
                                        className={`form-control ${submitted && commission === '' ? 'is-invalid' : ''}`}
                                        placeholder='Add commission percentage'
                                        value={commission}
                                        max='70'
                                        onChange={(e) => setCommission(e.target.value)}
                                    />
                                </div>
                                <button className='btn btn-primary flex-shrink-0' type='submit'>
                                    Set Commission Price
                                </button>
                            </div>
                            {submitted && commission === '' && (
                                <div className='invalid-feedback d-block'>{t('setCommissionErrMsg')}</div>
                            )}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default SetCommissionPrice;
