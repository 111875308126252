import React from 'react';
import { AiFillShop } from 'react-icons/ai';
import { truncate } from '../../helpers/utils';
import ReadOnlyRating from '../../components/general/ReadOnlyRating';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { MdVerified } from 'react-icons/md';
import { TiInfo } from 'react-icons/ti';
import { useTranslation } from 'react-i18next';

// HOOKS
import useShop from '../../hooks/useShop';

function AllShopsTable({ setIds }) {
    const { allShops } = useShop();
    const { t } = useTranslation();

    /*** ------------------------------------------------ */
    //      ALL PENDING VIDEOS TABLE COLUMNS
    /*** ------------------------------------------------ */
    const columns = [
        {
            name: 'Shop',
            minWidth: '200px',
            selector: (row) => row?.shopId,
            cell: (row) => (
                <div row={row}>
                    <Link to={`/shops/${row?.shopSlug}`} className='text-reset'>
                        <div className='d-flex align-items-center'>
                            <div
                                className='flex-shrink-0 bg-cover bg-center'
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    backgroundImage: `url(${row?.shopLogo})`,
                                    borderRadius: '0.5rem',
                                }}
                            ></div>
                            <div className='ms-3'>
                                <h6 className='mb-1' style={{ fontSize: '0.9rem' }}>
                                    {row?.shopTitle}
                                </h6>
                                <p className='text-muted small mb-0'>
                                    {row?.shopOwner && truncate(row?.shopOwner, 15, '.....')}
                                </p>
                            </div>
                        </div>
                    </Link>
                </div>
            ),
        },

        {
            name: 'Products',
            minWidth: '140px',
            selector: (row) => row?.shopProducts,
            cell: (row) => <div row={row}>{row?.shopProducts}</div>,
        },
        {
            name: 'Rating',
            minWidth: '200px',
            selector: (row) => row?.shopRating,
            cell: (row) => (
                <div row={row}>
                    <ReadOnlyRating rating={row?.shopRating} />
                </div>
            ),
        },
        {
            name: 'Verified',
            minWidth: '200px',
            selector: (row) => row?.isVerified,
            cell: (row) => (
                <div row={row}>
                    {row?.isVerified ? (
                        <div className='verified-badge'>
                            {t('verified')} <MdVerified className='ms-1 mb-1' />
                        </div>
                    ) : (
                        <div className='danger-badge'>
                            {t('notVerified')} <TiInfo className='ms-1 mb-1' />
                        </div>
                    )}
                </div>
            ),
        },
        {
            name: 'Action',
            minWidth: '140px',
            selector: (row) => row?.shopId,
            cell: (row) => (
                <div row={row}>
                    <div className='form-check'>
                        <input
                            type='checkbox'
                            id={`approved_shop_${row?.shopId}`}
                            className='form-check-input'
                            onChange={(e) =>
                                e.target.checked
                                    ? setIds((prev) => [...prev, Number(row.shopId)])
                                    : setIds((prev) => prev.filter((id) => id !== Number(row.shopId)))
                            }
                        />
                        <label htmlFor={`approved_shop_${row?.shopId}`} className='form-check-label'>
                            Select
                        </label>
                    </div>
                </div>
            ),
        },
    ];

    return (
        <div className='card shadow-lg mb-0' data-aos='fade-up' data-aos-delay='200'>
            <div className='card-body p-lg-5'>
                <div className='d-flex mb-5'>
                    <div className='stats-icon solid-turquoise'>
                        <AiFillShop size='1.4rem' />
                    </div>
                    <div className='ms-3'>
                        <h2 className='mb-0 h4'>{t('allShops')}</h2>
                        <p className='text-muted fw-normal mb-0'>
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                        </p>
                    </div>
                </div>

                <DataTable
                    columns={columns}
                    data={allShops}
                    pagination={allShops.length >= 1 && true}
                    responsive
                    theme='solarized'
                />
            </div>
        </div>
    );
}

export default AllShopsTable;
