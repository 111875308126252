import React from 'react';
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';

const Rating = ({ rating, handleHover, handleClick, hover }) => {
    const renderStars = () => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            if (i <= rating || i <= hover) {
                stars.push(
                    <FaStar
                        key={i}
                        className='star cursor-pointer text-warning'
                        onClick={() => handleClick(i)}
                        onMouseEnter={() => handleHover(i)}
                        onMouseLeave={() => handleHover(0)}
                    />
                );
            } else if (
                (i === Math.ceil(rating) && !Number.isInteger(rating)) ||
                (i === Math.ceil(hover) && !Number.isInteger(hover))
            ) {
                stars.push(
                    <FaStarHalfAlt
                        key={i}
                        className='star cursor-pointer text-warning'
                        onClick={() => handleClick(i)}
                        onMouseEnter={() => handleHover(i)}
                        onMouseLeave={() => handleHover(0)}
                    />
                );
            } else {
                stars.push(
                    <FaRegStar
                        key={i}
                        className='star cursor-pointer text-warning'
                        onClick={() => handleClick(i)}
                        onMouseEnter={() => handleHover(i)}
                        onMouseLeave={() => handleHover(0)}
                    />
                );
            }
        }
        return stars;
    };

    return (
        <div>
            <div className='d-flex align-items-center'>
                <div className='stars text-warning me-2'>{renderStars()}</div>
                <p className='mb-0'>{rating}/5</p>
            </div>
        </div>
    );
};

export default Rating;
