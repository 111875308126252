import React from 'react';
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';

const ReadOnlyRating = ({ rating }) => {
    const renderStars = () => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            if (i <= rating) {
                stars.push(<FaStar key={i} className='star' />);
            } else if (i === Math.ceil(rating) && !Number.isInteger(rating)) {
                stars.push(<FaStarHalfAlt key={i} className='star' />);
            } else {
                stars.push(<FaRegStar key={i} className='star text-warning' />);
            }
        }
        return stars;
    };

    return (
        <div className='d-flex align-items-center'>
            <div className='stars text-warning me-2'>{renderStars()}</div>
            <p className='mb-0 small mt-1'>{rating === 0 ? '' : rating?.toFixed(2)}</p>
        </div>
    );
};

export default ReadOnlyRating;
