import { appSettings } from '../../helpers/settings';
import { toast } from 'react-toastify';
import emailjs from '@emailjs/browser';
import { useTranslation } from 'react-i18next';

function useMailer() {
    const { t } = useTranslation();
    const sendEmail = (from, to, code, mail) => {
        emailjs
            .send(
                appSettings.mailJServiceKey,
                appSettings.mailJSTemplateID,
                { to_name: to, from_name: from, verify_code: code, reply_to: mail },
                appSettings.mailJSPublicKey
            )
            .then(
                (result) => {
                    toast.success(`${t('mailSent')}`);
                },
                (error) => {
                    console.log(error);
                    toast.error(`${t('toastError')}`);
                }
            );
    };

    return { sendEmail };
}

export default useMailer;
