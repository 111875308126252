import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Link } from 'react-router-dom';
import { BiHomeSmile, BiLinkAlt } from 'react-icons/bi';
import { TbUsers } from 'react-icons/tb';
import { BsCart3 } from 'react-icons/bs';
import { AiFillShop } from 'react-icons/ai';
import { HiChevronDown } from 'react-icons/hi';
import { appSettings } from '../../helpers/settings';
import { shuffle } from '../../helpers/utils';
import { RiShoppingBasket2Line } from 'react-icons/ri';

function BottomBanner() {
    const { t } = useTranslation();
    return (
        <>
            <nav
                className='navbar nav-colored w-100 navbar-expand-lg px-0 justify-content-between rounded-0 shadow-0'
                style={{ background: '#3396FF', zIndex: '999' }}
            >
                <div className='container py-2'>
                    <div className='brand'></div>
                    <button
                        className='navbar-toggler shadow-0 p-0 border-0'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#navbarBottomContent'
                        aria-controls='navbarBottomContent'
                        aria-expanded='false'
                        aria-label='Toggle navigation'
                    >
                        <span className='navbar-toggler-icon-el'>
                            <span className='btn-mobile--menu-icon'></span>
                        </span>
                    </button>

                    <div className='collapse navbar-collapse justify-content-lg-between' id='navbarBottomContent'>
                        <ul className='navbar-nav mx-auto navbar-nav-custom'>
                            <li className='nav-item'>
                                <NavLink className='nav-link' to='/' end>
                                    <BiHomeSmile className='me-2 mb-1' size='1.4rem' />
                                    {t('home')}
                                </NavLink>
                            </li>
                            <li className='nav-item dropdown-mega position-static'>
                                <a
                                    href='#!'
                                    className='nav-link dropdown-toggle no-caret text-reset'
                                    data-bs-toggle='dropdown'
                                    data-bs-target='#megaMenu'
                                    aria-expanded='false'
                                >
                                    <BsCart3 className='me-2 mb-1' size='1.4rem' />
                                    {t('products')}
                                    <HiChevronDown className='ms-1 mt-1' size='1rem' />
                                </a>
                                <div className='dropdown-menu shadow-none w-100 p-0' id='megaMenu'>
                                    <div
                                        className='mega-content text-dark p-lg-4 p-2'
                                        style={{ background: '#3396FF' }}
                                    >
                                        <div className='row g-0'>
                                            {[
                                                {
                                                    label: 'All',
                                                    value: 'All',
                                                    icon: <RiShoppingBasket2Line size='2rem' className='text-reset' />,
                                                },
                                                ...shuffle(appSettings?.productCategories)?.slice(0, 11),
                                            ]?.map((categ, index) => {
                                                return (
                                                    <div className='col-xl-2 col-lg-4' key={index}>
                                                        <Link
                                                            to={`${
                                                                categ?.value === 'All'
                                                                    ? '/products'
                                                                    : `/products/category/${categ?.value}`
                                                            }`}
                                                            className='nav-link d-flex align-items-center mega-nav-link'
                                                        >
                                                            {categ?.icon}
                                                            <div className='ms-2'>{categ?.label}</div>
                                                        </Link>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className='nav-item dropdown-mega position-static'>
                                <a
                                    href='#!'
                                    className='nav-link dropdown-toggle no-caret text-reset'
                                    data-bs-toggle='dropdown'
                                    data-bs-target='#megaMenu2'
                                    aria-expanded='false'
                                >
                                    <BsCart3 className='me-2 mb-1' size='1.4rem' />
                                    Digital Files
                                    <HiChevronDown className='ms-1 mt-1' size='1rem' />
                                </a>
                                <div className='dropdown-menu shadow-none w-100 p-0' id='megaMenu2'>
                                    <div
                                        className='mega-content text-dark p-lg-4 p-2'
                                        style={{ background: '#3396FF' }}
                                    >
                                        <div className='row g-0'>
                                            {[
                                                {
                                                    label: 'All',
                                                    value: 'All',
                                                    icon: <RiShoppingBasket2Line size='2rem' className='text-reset' />,
                                                },
                                                ...shuffle(appSettings?.categoryOptions)?.slice(0, 11),
                                            ]?.map((categ, index) => {
                                                return (
                                                    <div className='col-xl-2 col-lg-4' key={index}>
                                                        <Link
                                                            to={`${
                                                                categ?.value === 'All'
                                                                    ? '/digital-products'
                                                                    : `/digital-products/category/${categ?.value}`
                                                            }`}
                                                            className='nav-link d-flex align-items-center mega-nav-link'
                                                        >
                                                            {categ?.icon}
                                                            <div className='ms-2'>{categ?.label}</div>
                                                        </Link>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className='nav-item'>
                                <NavLink className='nav-link' to='/shops'>
                                    <AiFillShop className='me-2 mb-1' size='1.4rem' />
                                    {t('shops')}
                                </NavLink>
                            </li>
                            <li className='nav-item'>
                                <NavLink className='nav-link' to='/sellers'>
                                    <TbUsers className='me-2 mb-1' size='1.4rem' />
                                    {t('sellers')}
                                </NavLink>
                            </li>

                            <li className='nav-item'>
                                <div className='dropdown'>
                                    <Link
                                        className='nav-link dropdown-toggle no-caret d-flex align-items-center text-reset'
                                        to='/'
                                        role='button'
                                        data-bs-toggle='dropdown'
                                        data-bs-target='#linksDropdown'
                                        aria-expanded='false'
                                    >
                                        <BiLinkAlt className='me-2 mb-1' size='1.4rem' />
                                        {t('links')}
                                        <HiChevronDown className='ms-1 mt-1' size='1rem' />
                                    </Link>

                                    <ul className='dropdown-menu dropdown-menu-lg-end' id='linksDropdown'>
                                        <li>
                                            <a
                                                rel='noopener noreferrer'
                                                target='_blank'
                                                href='https://app.uniswap.org/#/swap?outputCurrency=0x1fce49e1c5d1f2f477b25744a060e85921968e70'
                                                className='dropdown-item rounded'
                                            >
                                                {t('buy')} DOB @ Uniswap
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                rel='noopener noreferrer'
                                                target='_blank'
                                                href='https://dexscreener.com/base/0x1FCe49E1C5d1f2f477b25744A060e85921968e70'
                                                className='dropdown-item rounded'
                                            >
                                                DOB {t('charts')}
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                rel='noopener noreferrer'
                                                target='_blank'
                                                href='https://basescan.org/token/0x1FCe49E1C5d1f2f477b25744A060e85921968e70'
                                                className='dropdown-item rounded'
                                            >
                                                DOB @ BaseScan
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                rel='noopener noreferrer'
                                                target='_blank'
                                                href='#'
                                                className='dropdown-item rounded'
                                            >
                                                DOB @ CoinmarketCap
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                rel='noopener noreferrer'
                                                target='_blank'
                                                href='#'
                                                className='dropdown-item rounded'
                                            >
                                                DOB @ CoinGecko
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                rel='noopener noreferrer'
                                                target='_blank'
                                                href='https://stake.difi.market'
                                                className='dropdown-item rounded'
                                            >
                                                DOB Staking
                                            </a>
                                        </li>	
                                        <li>
                                            <a
                                                rel='noopener noreferrer'
                                                target='_blank'
                                                href='https://send.difi.market'
                                                className='dropdown-item rounded'
                                            >
                                                DOB MultiSender
                                            </a>
                                        </li>											
                                        <li>
                                            <a
                                                rel='noopener noreferrer'
                                                target='_blank'
                                                href='https://airdrop.difi.market'
                                                className='dropdown-item rounded'
                                            >
                                                DOB Airdrop
                                            </a>
                                        </li>
{' '}

                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default BottomBanner;
