import React from 'react';
import { Tooltip } from 'react-tippy';
import { MdVerified } from 'react-icons/md';
import { TiInfo } from 'react-icons/ti';
import { formatString } from '../../helpers/utils';

function InfoGrid({ targetUser }) {
    return (
        <section className='py-5'>
            <div className='container py-5'>
                <div className='row g-3'>
                    {Object.keys(targetUser)
                        ?.filter(
                            (key) =>
                                key !== 'is_verified' &&
                                key !== 'verification_code' &&
                                key !== 'user_slug' &&
                                key !== 'rating' &&
                                key !== 'profits'
                        )
                        .map((key, index) => {
                            return (
                                <div
                                    className={`col-lg-${key === 'profile_picture' ? '12' : '6'}`}
                                    data-aos='fade-up'
                                    data-aos-delay={index * 50}
                                    key={index}
                                >
                                    <div className='card mb-0'>
                                        <div
                                            className={`card-body ${
                                                key === 'profile_picture' ? 'd-flex flex-column align-items-center' : ''
                                            }`}
                                        >
                                            <h6>
                                                {key === 'is_vendor' ? (
                                                    'Registred as a vendor'
                                                ) : key === 'email_address' && targetUser?.is_verified ? (
                                                    <>
                                                        {formatString(key)}
                                                        <Tooltip title='Verified' position='top' trigger='mouseenter'>
                                                            <MdVerified className='ms-2 text-success' />
                                                        </Tooltip>
                                                    </>
                                                ) : key === 'email_address' && !targetUser?.is_verified ? (
                                                    <>
                                                        {formatString(key)}
                                                        <Tooltip
                                                            title='Not Verified'
                                                            position='top'
                                                            trigger='mouseenter'
                                                        >
                                                            <TiInfo className='ms-2 text-warning' />
                                                        </Tooltip>
                                                    </>
                                                ) : (
                                                    formatString(key)
                                                )}
                                            </h6>
                                            {key === 'profile_picture' ? (
                                                <div className='user-avatar-pic-outer mt-2'>
                                                    <div
                                                        className='user-avatar-pic'
                                                        style={{ backgroundImage: `url(${targetUser[key]})` }}
                                                    ></div>
                                                </div>
                                            ) : key === 'is_vendor' ? (
                                                <p className='mb-0'>{targetUser[key] ? 'Yes' : 'No'}</p>
                                            ) : key === 'address' ? (
                                                <p className='mb-0 text-sm'>{targetUser[key]}</p>
                                            ) : (
                                                <p className='mb-0'>{targetUser[key]}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        </section>
    );
}

export default InfoGrid;
