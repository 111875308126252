import React from 'react';
import { GiMeshNetwork } from 'react-icons/gi';
import { VscShield } from 'react-icons/vsc';
import { HiOutlineReceiptPercent } from 'react-icons/hi2';
import { useTranslation } from 'react-i18next';

function Features() {
    const { t } = useTranslation();

    return (
        <>
            <section className='bg-dark py-4' style={{ color: 'white' }}>
                <div className='container'>
                    <div className='row g-4 align-items-center'>
                        <div className='col-lg-4'>
                            <div className='d-flex align-items-center justify-content-center'>
                                <GiMeshNetwork size={50} className='me-3 mb-1' />
                                <h4 className='fw-normal mb-0'>{t('decentralized')}</h4>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='d-flex align-items-center justify-content-center'>
                                <VscShield size={50} className='me-3 mb-1' />
                                <h4 className='fw-normal mb-0'>{t('hackerFreeHeading')}</h4>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='d-flex align-items-center justify-content-center'>
                                <HiOutlineReceiptPercent size={50} className='me-3 mb-1' />
                                <h4 className='fw-normal mb-0'>{t('lowFeesHeading')}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Features;
