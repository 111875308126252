import React from 'react';

const DigitalFilesContext = React.createContext({
    dfContract: null,
    dfAbi: null,
    loadDfContract: () => {},
    loadDfAbi: () => {},
});

export default DigitalFilesContext;
