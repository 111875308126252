import React, { useState, useMemo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Thumbs } from 'swiper';
import Viewer from 'react-viewer';

function ProductGallery({ gallery }) {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const viewerImgs = useMemo(() => {
        return gallery?.map((img, i) => ({ src: img, id: i }));
    }, [gallery]);
    const [isOpen, setIsOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    return (
        <>
            <Swiper
                spaceBetween={0}
                slidesPerView={1}
                modules={[Navigation, Thumbs]}
                navigation
                thumbs={{ swiper: thumbsSwiper }}
            >
                {gallery?.map((img, index) => {
                    return (
                        <SwiperSlide key={index}>
                            <div
                                className='product-gallery-slide cursor-pointer'
                                key={index}
                                style={{ background: `url(${img})` }}
                                onClick={() => {
                                    setIsOpen(true);
                                    setCurrentIndex(index);
                                }}
                            ></div>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
            <div className='product-swiper-thumbs mt-3'>
                <Swiper
                    modules={[Thumbs]}
                    watchSlidesProgress
                    onSwiper={setThumbsSwiper}
                    slidesPerView={5}
                    spaceBetween={0}
                >
                    {gallery?.map((img, index) => {
                        return (
                            <SwiperSlide className='w-auto p-2' key={index}>
                                <div
                                    className='product-gallery-thumb'
                                    key={index}
                                    style={{ background: `url(${img})` }}
                                ></div>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </div>

            {isOpen && (
                <Viewer
                    visible={isOpen}
                    activeIndex={currentIndex}
                    drag={false}
                    onClose={() => {
                        setIsOpen(false);
                    }}
                    images={viewerImgs}
                />
            )}
        </>
    );
}

export default ProductGallery;
