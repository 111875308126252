import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import { appSettings } from '../../helpers/settings';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import useWeb3 from '../../hooks/useWeb3';
import UploadProgress from '../../components/general/UploadProgress';
import useApp from '../../hooks/useApp';
import useShop from '../../hooks/useShop';
import useUser from '../../hooks/useUser';
import { createSlug } from '../../helpers/utils';
import { useContractWrite } from 'wagmi';
import { waitForTransaction } from '@wagmi/core';
import { useTranslation } from 'react-i18next';
import Integration from '../../integration/TokenAbi.json';
import Web3 from 'web3';

// IPFS CREATE HOST
const auth = 'Basic ' + Buffer.from(appSettings.IPFSProjectID + ':' + appSettings.IPFSSecret).toString('base64');
const ipfsClient = require('ipfs-http-client');
const ipfs = ipfsClient.create({
    host: 'ipfs.infura.io',
    port: 5001,
    protocol: 'https',
    headers: {
        authorization: auth,
    },
});

function CreateShopForm() {
    const { account } = useWeb3();
    const { setTransactionLoading, setUploadingProgress, uploadingProgress } = useApp();
    const {
        shopContract,
        allShops,
        userShops,
        shopCreationCost,
        membershipContract,
        membershipAbi,
        stToken,
        loadUserShops,
        loadAllShops,
    } = useShop();
    const { userInfo } = useUser();
    const { t } = useTranslation();
    const [logoUploadProgress, setLogoUploadProgress] = useState(0);
    const [coverUploadProgress, setCoverUploadProgress] = useState(0);
    const [approvedTx, setApprovedTx] = useState(null);
    const [redirectLink, setRedirectLink] = useState('/');
    const [shopCreationData, setShopCreationData] = useState([]);
    const navigate = useNavigate();
    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const reachedLimit = useMemo(() => {
        return !userInfo?.vip && userShops?.length > 0;
    }, [userInfo, userShops]);

    /* --------------------------------------------- 
              CREATE SHOP FUNCTION
     --------------------------------------------- */
    const { write: web3CreateShop } = useContractWrite({
        address: membershipContract?.address,
        abi: membershipAbi,
        functionName: 'createShop',
        onSuccess() {
            setTimeout(() => {
                setTransactionLoading(false);
                toast.success(`${t('shopCreated')}`);
                loadAllShops(shopContract);
                loadUserShops(shopContract, account);
            }, 5000);
            setTimeout(() => {
                navigate(`/shops/${createSlug(redirectLink.trim())}`);
            }, 6000);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            setUploadingProgress(false);
            toast.error(`${t('toastError')}`);
        },
    });

    /* --------------------------------------------- 
              APPROVE SENDING TOKEN
     --------------------------------------------- */
    const { write: web3ApproveTransfer, data: txData } = useContractWrite({
        address: stToken?.address,
        abi: Integration,
        functionName: 'approve',

        onSuccess() {
            setTransactionLoading(true);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error('Oops, Something went wrong!');
        },
    });

    useEffect(() => {
        if (txData) {
            async function getTx() {
                const waitFrTx = await waitForTransaction({
                    hash: txData?.hash,
                });
                setApprovedTx(waitFrTx);
            }

            getTx();
        }
    }, [txData]);

    useEffect(() => {
        if (approvedTx) {
            web3CreateShop({
                recklesslySetUnpreparedArgs: [...shopCreationData],
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [approvedTx]);

    /* --------------------------------------------- 
          VALIDATE PROFILE IMAGE FILE TYPE
    --------------------------------------------- */
    const validateImageFileType = (file) => {
        const validImageTypes = ['image/png', 'image/jpeg', 'image/jpg'];
        if (!validImageTypes.includes(file[0].type)) {
            return t('shopLogoPattern');
        }
    };

    /* --------------------------------------------- 
          VALIDATE UNIQUE EMAIL ADDRESS
    --------------------------------------------- */
    const validateUniqueTitle = (title) => {
        if (allShops?.map((shop) => shop?.shopTitle)?.includes(title?.trim())) {
            return t('shopUniqueTitle');
        }
    };

    /* --------------------------------------------- 
          CREATE A SHOP FUNCTION
    --------------------------------------------- */
    async function handleCreateShop(data) {
        setUploadingProgress(true);

        const logoProgress = function (progData) {
            let percent = Math.floor((Number(progData) * 100) / data?.logo[0]?.size);
            setLogoUploadProgress((prev) => percent);
        };
        const coverProgress = function (progData) {
            let percent = Math.floor((Number(progData) * 100) / data?.cover[0]?.size);
            setCoverUploadProgress((prev) => percent);
        };

        const ipfsLogo = await ipfs.add(data?.logo[0], { progress: logoProgress });
        const ipfsCover = await ipfs.add(data?.cover[0], { progress: coverProgress });

        if (ipfsLogo && ipfsCover) {
            setUploadingProgress(false);
            setTransactionLoading(true);
            setRedirectLink(data?.shop_title);
            setShopCreationData([
                data?.shop_title.trim(),
                data?.shop_description,
                data?.category?.label,
                data?.shop_email,
                `https://${appSettings.IPFSGatewaySubdomain}.infura-ipfs.io/ipfs/${ipfsCover?.path}`,
                `https://${appSettings.IPFSGatewaySubdomain}.infura-ipfs.io/ipfs/${ipfsLogo?.path}`,
            ]);

            web3ApproveTransfer({
                recklesslySetUnpreparedArgs: [
                    membershipContract.address,
                    Web3.utils.toWei(shopCreationCost.toString(), 'ether'),
                ],
            });
        }
    }

    return (
        <>
            {uploadingProgress && (
                <UploadProgress forShop={true} logo={logoUploadProgress} cover={coverUploadProgress} />
            )}
            <form onSubmit={handleSubmit(handleCreateShop)}>
                <div className='row g-4'>
                    {/* SHOP TITLE */}
                    <div className='col-lg-12'>
                        <label className='form-label' htmlFor='shopTitle'>
                            {t('shopTitle')}
                        </label>
                        <input
                            type='text'
                            className={`form-control ${errors.shop_title ? 'is-invalid' : ''}`}
                            id='shopTitle'
                            placeholder={t('shopTitlePlaceholder')}
                            name='shop_title'
                            {...register('shop_title', {
                                required: {
                                    value: true,
                                    message: t('shopTitleErrMsg'),
                                },
                                minLength: {
                                    value: 3,
                                    message: t('shopTitleMinLength'),
                                },
                                validate: validateUniqueTitle,
                            })}
                        />
                        {errors.shop_title && <span className='invalid-feedback'>{errors.shop_title?.message}</span>}
                    </div>

                    {/* SHOP DESCRIPTION */}
                    <div className='col-lg-12'>
                        <label className='form-label' htmlFor='shopDescription'>
                            {t('shopDescription')}
                        </label>
                        <textarea
                            rows='7'
                            className={`form-control ${errors.shop_description ? 'is-invalid' : ''}`}
                            id='shopDescription'
                            placeholder={t('shopDescriptionPlaceholder')}
                            name='shop_description'
                            {...register('shop_description', {
                                required: {
                                    value: true,
                                    message: t('shopDescriptionErrMsg'),
                                },
                                minLength: {
                                    value: 30,
                                    message: t('shopDescriptionMinLength'),
                                },
                                maxLength: {
                                    value: 300,
                                    message: t('shopDescriptionMaxLength'),
                                },
                            })}
                        />
                        {errors.shop_description && (
                            <span className='invalid-feedback'>{errors.shop_description?.message}</span>
                        )}
                    </div>

                    {/* EMAIL ADDRESS */}
                    <div className='col-lg-6'>
                        <label className='form-label' htmlFor='shopEmail'>
                            {t('shopMail')}
                        </label>
                        <input
                            type='email'
                            className={`form-control ${errors.shop_email ? 'is-invalid' : ''}`}
                            id='shopEmail'
                            placeholder={t('shopMailPlaceholder')}
                            name='shop_email'
                            {...register('shop_email', {
                                required: {
                                    value: true,
                                    message: t('shopMailErrMsg'),
                                },
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: t('shopMailPattern'),
                                },
                            })}
                        />
                        {errors.shop_email && <span className='invalid-feedback'>{errors.shop_email?.message}</span>}
                    </div>

                    {/* CATEGORY */}
                    <div className='col-lg-6'>
                        <label className='form-label' htmlFor='category'>
                            {t('productCategory')}
                        </label>
                        <Controller
                            name='category'
                            control={control}
                            rules={{ required: t('productCategoryErrMsg') }}
                            render={({ field }) => (
                                <>
                                    <Select
                                        options={appSettings?.shopCategories}
                                        id='category'
                                        className={`border-0 shadow-sm ${errors.category ? 'is-invalid' : ''}`}
                                        classNamePrefix='select'
                                        placeholder='Select'
                                        isSearchable={true}
                                        {...field}
                                    />
                                    {errors.category && (
                                        <span className='invalid-feedback'>{errors.category?.message}</span>
                                    )}
                                </>
                            )}
                        />
                    </div>

                    {/* SHOP AVATAR */}
                    <div className='col-lg-12'>
                        <label className='form-label' htmlFor='logo'>
                            {t('shopLogo')}
                        </label>
                        <input
                            type='file'
                            className={`form-control ${errors.logo ? 'is-invalid' : ''}`}
                            id='logo'
                            accept='.jpg, .png, .jpeg'
                            name='logo'
                            {...register('logo', {
                                required: {
                                    value: true,
                                    message: t('shopLogoErrMsg'),
                                },
                                validate: validateImageFileType,
                            })}
                        />
                        {errors.logo && <span className='invalid-feedback'>{errors.logo?.message}</span>}
                    </div>

                    {/* SHOP COVER */}
                    <div className='col-lg-12'>
                        <label className='form-label' htmlFor='cover'>
                            {t('shopCover')}
                        </label>
                        <input
                            type='file'
                            className={`form-control ${errors.cover ? 'is-invalid' : ''}`}
                            id='cover'
                            accept='.jpg, .png, .jpeg'
                            name='cover'
                            {...register('cover', {
                                required: {
                                    value: true,
                                    message: 'Please upload your cover image',
                                },
                                validate: validateImageFileType,
                            })}
                        />
                        {errors.cover && <span className='invalid-feedback'>{errors.cover?.message}</span>}
                    </div>

                    {/* SUBMIT */}
                    {!reachedLimit ? (
                        <div className='col-12'>
                            <button
                                className='btn btn-primary w-100'
                                type='submit'
                                disabled={Number(stToken?.balance) < Number(shopCreationCost)}
                            >
                                {t('createYourShop')}
                            </button>
                            <p className='small mt-2 mb-0 text-muted'>
                                Open a shop for{' '}
                                <span className='text-primary'>
                                    {shopCreationCost} {stToken?.symbol}
                                </span>
                            </p>
                        </div>
                    ) : (
                        <div className='col-12'>
                            <div className='badge bg-danger w-100'>{t('shopCreationLimit')}</div>
                        </div>
                    )}
                </div>
            </form>
        </>
    );
}

export default CreateShopForm;
