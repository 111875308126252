import React, { useReducer } from 'react';
import { readContract } from '@wagmi/core';

import AppContext from './app-context';

const defaultAppState = {
    contract: null,
    abi: null,
    tokenInUSD: 0,
    usdToken: 0,
    owner: '',
    themeMode: localStorage.getItem('difi_mall_theme') || 'light',
    activities: [],
    mintUploadProgress: 0,
    transactionLoading: false,
    uploadingProgress: false,
    productView: false,
    productViewSrc: {},
    lang: JSON.parse(localStorage.getItem('hashbit_ecommerce_lang')) || { code: 'en', key: 'English' },
};

const appReducer = (state, action) => {
    if (action.type === 'SWITCHMODE') {
        return {
            ...state,
            themeMode: action.themeMode,
        };
    }
    if (action.type === 'CONTRACT') {
        return {
            ...state,
            contract: action.contract,
        };
    }
    if (action.type === 'SET_PRODUCT_VIEW') {
        return {
            ...state,
            productView: action.status,
        };
    }
    if (action.type === 'SET_PRODUCT_VIEW_src') {
        return {
            ...state,
            productViewSrc: action.src,
        };
    }
    if (action.type === 'CONTRACT') {
        return {
            ...state,
            contract: action.contract,
        };
    }
    if (action.type === 'GET_ABI') {
        return {
            ...state,
            abi: action.abi,
        };
    }
    if (action.type === 'SWITCH_LANGUAGE') {
        return {
            ...state,
            lang: action.lang,
        };
    }
    if (action.type === 'LOADING') {
        return {
            ...state,
            transactionLoading: action.loading,
        };
    }
    if (action.type === 'UPLOADING_PROGRESS') {
        return {
            ...state,
            uploadingProgress: action.loading,
        };
    }

    if (action.type === 'GET_UPLOAD_PROGRESS') {
        return {
            ...state,
            mintUploadProgress: action.progress,
        };
    }
    if (action.type === 'GET_OWNER') {
        return {
            ...state,
            owner: action.owner,
        };
    }
    if (action.type === 'LOAD_ACTIVITIES') {
        return {
            ...state,
            activities: action.activities.map((el) => {
                return {
                    userAddress: el[0],
                    userName: el[1][0],
                    userProfile: el[1][3],
                    time: Number(el[2]) * 1000,
                    action: el[3],
                };
            }),
        };
    }
    if (action.type === 'GET_TOKEN_IN_USD') {
        return {
            ...state,
            tokenInUSD: action.payload.tokenInUSD,
            usdToken: action.payload.usdToken,
        };
    }

    return defaultAppState;
};

// eslint-disable-next-line no-unused-vars
let usdPrice = 0;
let usdToken = 0;

const AppProvider = (props) => {
    const [appState, dispatchAppAction] = useReducer(appReducer, defaultAppState);

    const setTransactionLoadingHandler = (loading) => {
        dispatchAppAction({ type: 'LOADING', loading: loading });
    };

    const switchModeHandler = (themeMode) => {
        dispatchAppAction({ type: 'SWITCHMODE', themeMode: themeMode });
        localStorage.setItem('difi_mall_theme', themeMode);
    };

    const setUploadingProgressHandler = (loading) => {
        dispatchAppAction({ type: 'UPLOADING_PROGRESS', loading: loading });
    };

    const getContractAbiHandler = (abi) => {
        dispatchAppAction({ type: 'GET_ABI', abi: abi });
    };

    const setProductViewHandler = (status) => {
        dispatchAppAction({ type: 'SET_PRODUCT_VIEW', status: status });
    };

    const setProductViewSrcHandler = (src) => {
        dispatchAppAction({ type: 'SET_PRODUCT_VIEW_src', src: src });
    };

    const loadContractHandler = (contract) => {
        dispatchAppAction({ type: 'CONTRACT', contract: contract });
        return contract;
    };

    const switchLanguageHandler = (lang) => {
        dispatchAppAction({ type: 'SWITCH_LANGUAGE', lang: lang });
        localStorage.setItem('hashbit_ecommerce_lang', JSON.stringify(lang));
        return lang;
    };

    const loadAppOwnerHandler = async (contract) => {
        const owner = await readContract({
            address: contract.address,
            abi: appState.abi,
            functionName: 'owner',
        });
        dispatchAppAction({ type: 'GET_OWNER', owner: owner });
        return owner;
    };

    const loadActivitiesHandler = async (contract, abi) => {
        const activities = await readContract({
            address: contract.address,
            abi: abi,
            functionName: 'actvityLogs',
        });

        console.log(activities);

        dispatchAppAction({ type: 'LOAD_ACTIVITIES', activities: activities });
        return activities;
    };

    const loadMintUploadProgressHandler = (progress) => {
        dispatchAppAction({ type: 'GET_MINT_PROGRESS', progress: progress });
        return progress;
    };

    const loadPaymentTokenPriceInUSDHandler = async (tokenId) => {
        console.log(tokenId);
        try {
            const res =
                await fetch(`https://api.coingecko.com/api/v3/simple/price?ids=${tokenId?.toLowerCase()}&vs_currencies=usd
                `);
            const data = await res.json();

            console.log(data);
            const tokenInUSD = data[`${tokenId?.toLowerCase()}`]?.usd;
            usdPrice = tokenInUSD;
            usdToken = parseFloat((1 / tokenInUSD).toFixed(2));

            dispatchAppAction({
                type: 'GET_TOKEN_IN_USD',
                payload: { tokenInUSD: parseFloat(tokenInUSD), usdToken: usdToken },
            });
            return { tokenInUSD, usdToken };
        } catch (error) {
            console.log(error);
        }
    };

    const appContext = {
        contract: appState.contract,
        abi: appState.abi,
        themeMode: appState.themeMode,
        owner: appState.owner,
        mintUploadProgress: appState.mintUploadProgress,
        transactionLoading: appState.transactionLoading,
        uploadingProgress: appState.uploadingProgress,
        activities: appState.activities,
        lang: appState.lang,
        productView: appState.productView,
        productViewSrc: appState.productViewSrc,
        tokenInUSD: appState.tokenInUSD,
        usdToken: appState.usdToken,
        loadContract: loadContractHandler,
        loadAppOwner: loadAppOwnerHandler,
        setTransactionLoading: setTransactionLoadingHandler,
        loadMintUploadProgress: loadMintUploadProgressHandler,
        setUploadingProgress: setUploadingProgressHandler,
        loadActivities: loadActivitiesHandler,
        switchMode: switchModeHandler,
        getContractAbi: getContractAbiHandler,
        switchLanguage: switchLanguageHandler,
        setProductView: setProductViewHandler,
        setProductViewSrc: setProductViewSrcHandler,
        loadPaymentTokenPriceInUSD: loadPaymentTokenPriceInUSDHandler,
    };

    return <AppContext.Provider value={appContext}>{props.children}</AppContext.Provider>;
};

export default AppProvider;
