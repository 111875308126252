import React, { useState, useMemo } from 'react';
import useShop from '../../hooks/useShop';
import { Link } from 'react-router-dom';
import { BiSearch } from 'react-icons/bi';
import useUser from '../../hooks/useUser';

function HeaderSearch() {
    const { allShops, allProducts } = useShop();
    const { usersList } = useUser();
    const [searchQuery, setSearchQuery] = useState('');

    const searchInData = useMemo(() => {
        return [
            ...usersList
                ?.filter((user) => user?.is_vendor)
                ?.map((user) => ({ title: user?.full_name, type: 'User', url: `/sellers/${user?.user_slug}` })),
            ...allShops?.map((shop) => ({ title: shop?.shopTitle, type: 'Shop', url: `/shops/${shop?.shopSlug}` })),
            ...allProducts?.map((product) => ({
                title: product?.productName,
                type: 'Product',
                url: `/products/${product?.productSlug}`,
            })),
        ];
    }, [allShops, allProducts, usersList]);

    const searchResults = useMemo(() => {
        if (searchQuery !== '') {
            return searchInData?.filter((item) => {
                return item?.title.toLowerCase().includes(searchQuery?.toLowerCase()?.replace(/\s/g, ''));
            });
        } else {
            return [];
        }
    }, [searchQuery, searchInData]);

    return (
        <>
            <div className='dropdown w-100 mx-2' style={{ maxWidth: '40rem' }}>
                <div className='header-search-bar'>
                    <input
                        type='text'
                        className='form-control'
                        placeholder='Search'
                        onChange={(e) => setSearchQuery(e.target.value)}
                        value={searchQuery}
                    />
                    <div className='header-search-btn'>
                        <BiSearch size={20} />
                    </div>
                </div>
                {searchResults?.length > 0 && (
                    <div
                        className='dropdown-menu search-dropdown show w-100'
                        data-bs-popper='static'
                        style={{ zIndex: '999' }}
                    >
                        {searchResults?.map((result, index) => {
                            return (
                                <li key={index} onClick={() => setSearchQuery('')}>
                                    <Link
                                        to={`${result?.url}`}
                                        className='dropdown-item d-flex align-items-center justify-content-between'
                                    >
                                        <span>{result?.title}</span>
                                        <span className='text-sm'>{result?.type}</span>
                                    </Link>
                                </li>
                            );
                        })}
                    </div>
                )}

                {searchResults?.length === 0 && searchQuery !== '' && (
                    <div className='dropdown-menu show w-100' data-bs-popper='static' style={{ zIndex: '999' }}>
                        <li>
                            <span className='dropdown-item d-flex align-items-center justify-content-center text-muted'>
                                <span className='text-sm'>No Data Found</span>
                            </span>
                        </li>
                    </div>
                )}
            </div>
        </>
    );
}

export default HeaderSearch;
