import React from 'react';

const AppContext = React.createContext({
    contract: null,
    owner: '',
    themeMode: localStorage.getItem('difi_mall_theme') || 'light',
    activities: [],
    mintUploadProgress: 0,
    transactionLoading: false,
    uploadingProgress: false,
    abi: null,
    productView: false,
    tokenInUSD: 0,
    usdToken: 0,
    productViewSrc: {},
    lang: JSON.parse(localStorage.getItem('hashbit_ecommerce_lang')) || { code: 'en', key: 'English' },
    getContractAbi: () => {},
    loadContract: () => {},
    loadAppOwner: () => {},
    setTransactionLoading: () => {},
    loadMintUploadProgress: () => {},
    setUploadingProgress: () => {},
    loadActivities: () => {},
    switchMode: () => {},
    switchLanguage: () => {},
    setProductView: () => {},
    setProductViewSrc: () => {},
    loadPaymentTokenPriceInUSD: () => {},
});

export default AppContext;
