import React from 'react';
import Popup from '../../components/general/Popup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { FaShippingFast } from 'react-icons/fa';
import useOrder from '../../hooks/useOrder';
import useApp from '../../hooks/useApp';
import { useContractWrite } from 'wagmi';
import { useTranslation } from 'react-i18next';

function ShipOrderForm({ shippingRequested, setShippingRequested, orderId }) {
    const { setTransactionLoading } = useApp();
    const { loadAllOrders, orderStatsContract, orderStatsContractAbi } = useOrder();
    const { t } = useTranslation();
    const {
        register,
        resetField,
        handleSubmit,
        formState: { errors },
    } = useForm();

    /* --------------------------------------------- 
              SHIP ORDER FUNCTION
     --------------------------------------------- */
    const { write: web3ShipOrder } = useContractWrite({
        address: orderStatsContract?.address,
        abi: orderStatsContractAbi,
        functionName: 'shipping',
        onSuccess() {
            setTimeout(() => {
                setTransactionLoading(false);
                loadAllOrders(orderStatsContract);
                toast.success(`${t('orderStatusChanged')}`);
                setShippingRequested(false);
                resetField('tracking_url');
            }, 5000);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error(`${t('toastError')}`);
        },
    });

    /* --------------------------------------------- 
          HANDLING SHIPPING ORDER
    --------------------------------------------- */
    function handleShippingOrder(data) {
        web3ShipOrder({
            recklesslySetUnpreparedArgs: [orderId, data?.tracking_url],
        });
    }

    return (
        <>
            {shippingRequested && (
                <Popup closeModal={() => setShippingRequested(false)}>
                    <header className='mb-4 text-center'>
                        <h2 className='h1'>{t('shipToBuyer')}</h2>
                        <p className='text-muted'>{t('shipToBuyerSubheading')}</p>
                    </header>
                    <form onSubmit={handleSubmit(handleShippingOrder)}>
                        <div className='row g-4'>
                            <div className='col-12'>
                                <input
                                    type='text'
                                    className={`form-control ${errors.tracking_url ? 'is-invalid' : ''}`}
                                    name='tracking_url'
                                    placeholder={t('trackingUrlPlaceholder')}
                                    {...register('tracking_url', {
                                        required: {
                                            value: true,
                                            message: t('trackingUrlPlaceholder'),
                                        },
                                    })}
                                />
                                {errors.tracking_url && (
                                    <span className='invalid-feedback'>{errors.tracking_url?.message}</span>
                                )}
                            </div>
                            <div className='col-12'>
                                <button className='btn btn-primary w-100' type='submit'>
                                    <FaShippingFast className='me-2 mb-1' size={20} />
                                    {t('shipNow')}
                                </button>
                            </div>
                        </div>
                    </form>
                </Popup>
            )}
        </>
    );
}

export default ShipOrderForm;
