import React, { useEffect, useMemo, useState } from 'react';
import useShop from '../../hooks/useShop';
import useApp from '../../hooks/useApp';
import useWeb3 from '../../hooks/useWeb3';
import { toast } from 'react-toastify';
import Web3 from 'web3';
import { useForm } from 'react-hook-form';
import { BsCreditCard2FrontFill } from 'react-icons/bs';
import BigNumber from 'bignumber.js';
import useOrder from '../../hooks/useOrder';
import useUser from '../../hooks/useUser';
import { useContractWrite } from 'wagmi';
import { waitForTransaction } from '@wagmi/core';
import TokenAbi from '../../integration/TokenAbi.json';
import { humanize } from '../../helpers/utils';
import emailjs from '@emailjs/browser';
import { appSettings } from '../../helpers/settings';
import { useTranslation } from 'react-i18next';

function MakeOrder({
    productSlug,
    productName,
    productId,
    amount,
    shippingPrice,
    unitPrice,
    unitUSDPrice,
    closeModal,
    refreshBuyers,
    resetQuantity,
    productOwner,
}) {
    const { t } = useTranslation();
    const { setTransactionLoading } = useApp();
    const { userContract, loadUserInfo, loadUsersList, userInfo, usersList } = useUser();
    const { shopContract, shopContractAbi, paymentToken, loadAllProducts, loadAllShops } = useShop();
    const { orderStatsContract, loadAllOrders } = useOrder();
    const { account } = useWeb3();
    const [approvedTx, setApprovedTx] = useState(null);
    const [approveTxData, setApproveTxData] = useState([]);
    const [shipPrice, setShipPrice] = useState(0);
    const [shipTokenPrice, setShipTokenPrice] = useState(0);
    const [chosenDest, setChosenDest] = useState('');
    const [buyerInfo, setBuyerInfo] = useState({});

    const productVendor = useMemo(() => {
        return usersList?.filter((user) => user?.address === productOwner)[0]?.email_address;
    }, [usersList, productOwner]);

    useEffect(() => {
        setShipPrice(shippingPrice?.map((opt) => opt?.value)[0]);
        setChosenDest(shippingPrice?.map((opt) => opt?.label)[0]);
        setShipTokenPrice(shippingPrice?.map((opt) => opt?.tokenValue)[0]);
    }, [shippingPrice]);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    /* --------------------------------------------- 
              MAKE ORDER FUNCTION
     --------------------------------------------- */
    const { write: web3MakeOrder } = useContractWrite({
        address: shopContract?.address,
        abi: shopContractAbi,
        functionName: 'makeOrder',
        onSuccess() {
            setTimeout(() => {
                setTransactionLoading(false);
                toast.success(`${t('orderCreated')}`);
                loadAllProducts(shopContract);
                loadAllShops(shopContract);
                loadAllOrders(orderStatsContract);
                loadUserInfo(shopContract, account);
                loadUsersList(userContract);
                closeModal(false);
                refreshBuyers();
                resetQuantity();

                emailjs
                    .send(
                        appSettings.mailJServiceKey,
                        appSettings.mailJSVerifyTemplateID,
                        {
                            reply_to: productVendor,
                            form_name: appSettings?.brandName,
                            buyer_name: buyerInfo?.buyerName,
                            buyer_email: buyerInfo?.buyerEmail,
                            buyer_phone: buyerInfo?.buyerPhone,
                            product_slug: productSlug,
                            product_name: productName,
                            amount,
                            shipping_price: shippingPrice,
                            unit_usd_price: unitUSDPrice,
                        },
                        appSettings.mailJSPublicKey
                    )
                    .then(
                        (result) => {
                            toast.success(`${t('mailSent')}`);
                        },
                        (error) => {
                            console.log(error);
                            toast.error(`${t('toastError')}`);
                        }
                    );
            }, 5000);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error(`${t('toastError')}`);
        },
    });

    /* --------------------------------------------- 
              APPROVE SENDING TOKEN
     --------------------------------------------- */
    const { write: web3ApproveTransfer, data: txData } = useContractWrite({
        address: paymentToken,
        abi: TokenAbi,
        functionName: 'approve',
        onSuccess() {
            setTransactionLoading(true);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error(`${t('toastError')}`);
        },
    });

    useEffect(() => {
        if (txData) {
            async function getTe() {
                const waitFrTx = await waitForTransaction({
                    hash: txData?.hash,
                });
                setApprovedTx(waitFrTx);
            }

            getTe();
        }
    }, [txData]);

    useEffect(() => {
        if (approvedTx) {
            setBuyerInfo({
                buyerName: userInfo?.full_name,
                buyerEmail: userInfo?.email_address,
                buyerPhone: userInfo?.phone_number,
            });
            web3MakeOrder({
                recklesslySetUnpreparedArgs: [...approveTxData],
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [approvedTx, approveTxData]);

    /* --------------------------------------------- 
          BUY PRODUCT FUNCTION
    --------------------------------------------- */
    async function handleMakeOrderForm(data) {
        const totalOrderPrice = new BigNumber(amount).times(new BigNumber(unitPrice));
        const totalPriceWithShipping = totalOrderPrice.plus(new BigNumber(shipTokenPrice));
        const formattedOrderPrice = Web3.utils.toWei(totalOrderPrice.toString(), 'ether');
        const formattedShipPrice = Web3.utils.toWei(shipTokenPrice.toString(), 'ether');
        setApproveTxData([
            productId,
            amount,
            data?.shipping_address,
            data?.shipping_notes,
            formattedOrderPrice,
            formattedShipPrice,
        ]);
        web3ApproveTransfer({
            recklesslySetUnpreparedArgs: [
                shopContract.address,
                Web3.utils.toWei(totalPriceWithShipping.toString(), 'ether'),
            ],
        });
    }

    return (
        <>
            <header className='text-center mb-4'>
                <h3>{t('makeOrder')}</h3>
                <p className='text-muted'>
                    {t('totalOrderValueIs')}{' '}
                    <strong className='fw-bold text-primary'>{amount * unitUSDPrice + shipPrice}</strong> USD
                </p>
            </header>

            <form className='row g-3' onSubmit={handleSubmit(handleMakeOrderForm)}>
                <div className='col-12'>
                    <label className='form-label'>{t('shippingAddress')}</label>
                    <input
                        type='text'
                        placeholder='Add your shipping address'
                        name='shipping_address'
                        className={`form-control ${errors.shipping_address ? 'is-invalid' : ''}`}
                        {...register('shipping_address', {
                            required: {
                                value: true,
                                message: 'Please enter your shipping address',
                            },
                        })}
                    />
                    {errors.shipping_address && (
                        <span className='invalid-feedback'>{errors.shipping_address.message}</span>
                    )}
                </div>

                <div className='col-12'>
                    <label className='form-label'>{t('continentToShip')}</label>
                    <ul className='list-unstyled row g-2'>
                        {shippingPrice
                            ?.filter((opt) => opt?.value !== 0)
                            ?.map((opt, index) => {
                                return (
                                    <li className='col-auto' key={index}>
                                        <div className='form-check text-sm shipping-option position-relative'>
                                            <input
                                                type='radio'
                                                name='shippingOptions'
                                                id={opt?.label}
                                                className='form-check-input'
                                                checked={chosenDest === opt?.label}
                                                onChange={() => {
                                                    setShipPrice(opt?.value);
                                                    setChosenDest(opt?.label);
                                                    setShipTokenPrice(opt?.tokenValue);
                                                }}
                                            />
                                            <label htmlFor={opt?.label} className='form-check-label stretched-link'>
                                                {humanize(`${opt.label}`)}{' '}
                                                <span className='badge bg-dark ms-2 text-xxs'>{opt?.value} USD</span>
                                            </label>
                                        </div>
                                    </li>
                                );
                            })}
                    </ul>
                </div>

                <div className='col-12'>
                    <label className='form-label'>{t('shippingNotes')}</label>
                    <textarea
                        rows='8'
                        placeholder='If you have some notes, let the product owner knows'
                        name='shipping_notes'
                        className={`form-control ${errors.shipping_notes ? 'is-invalid' : ''}`}
                        {...register('shipping_notes', {
                            required: {
                                value: false,
                            },
                        })}
                    ></textarea>
                </div>

                <div className='col-12'>
                    <div className='form-check d-flex align-items-center'>
                        <input
                            id='agree_policy'
                            type='checkbox'
                            className='form-check-input'
                            name='agree_policy'
                            {...register('agree_policy', {
                                required: {
                                    value: true,
                                    message: 'Please accept the return policy terms',
                                },
                            })}
                        />

                        <label htmlFor='agree_policy' className='form-check-label ms-2'>
                            {t('agreeReturnPolicy')}
                        </label>
                    </div>
                    {errors.agree_policy && <span className='invalid-feedback'>{errors.agree_policy.message}</span>}
                </div>

                <div className='col-12'>
                    <button className='btn btn-primary w-100' type='submit'>
                        <BsCreditCard2FrontFill className='me-2 mb-1' size={20} />
                        {t('completeOrder')}
                    </button>
                </div>
            </form>
        </>
    );
}

export default MakeOrder;
