import React from 'react';
import useUser from '../../hooks/useUser';
import { useTranslation } from 'react-i18next';

function SideTabs({ handleActiveTab, activeTab }) {
    const { userInfo } = useUser();
    const { t } = useTranslation();
    return (
        <>
            <div className='card mb-0'>
                <div className='card-body p-3'>
                    {userInfo?.is_vendor ? (
                        <>
                            <button
                                className={`dropdown-item rounded-md ${activeTab === 'shops' ? 'active' : ''}`}
                                id='shops'
                                onClick={handleActiveTab}
                            >
                                {t('myShops')}
                            </button>
                            <button
                                className={`dropdown-item rounded-md ${activeTab === 'products' ? 'active' : ''}`}
                                id='products'
                                onClick={handleActiveTab}
                            >
                                {t('myProducts')}
                            </button>
                            <button
                                className={`dropdown-item rounded-md ${activeTab === 'orders' ? 'active' : ''}`}
                                id='orders'
                                onClick={handleActiveTab}
                            >
                                {t('myOrders')}
                            </button>
                            <button
                                className={`dropdown-item rounded-md ${activeTab === 'profits' ? 'active' : ''}`}
                                id='profits'
                                onClick={handleActiveTab}
                            >
                                {t('myProfits')}
                            </button>
                            <button
                                className={`dropdown-item rounded-md ${activeTab === 'disputes' ? 'active' : ''}`}
                                id='disputes'
                                onClick={handleActiveTab}
                            >
                                {t('disputes')}
                            </button>
                        </>
                    ) : (
                        <>
                            <button
                                className={`dropdown-item rounded-md ${activeTab === 'purchases' ? 'active' : ''}`}
                                id='purchases'
                                onClick={handleActiveTab}
                            >
                                {t('myPurchases')}
                            </button>
                            <button
                                className={`dropdown-item rounded-md ${activeTab === 'orders' ? 'active' : ''}`}
                                id='orders'
                                onClick={handleActiveTab}
                            >
                                {t('orderHistory')}
                            </button>
                            <button
                                className={`dropdown-item rounded-md ${activeTab === 'profits' ? 'active' : ''}`}
                                id='profits'
                                onClick={handleActiveTab}
                            >
                                {t('myHeldCredit')}
                            </button>
                            <button
                                className={`dropdown-item rounded-md ${activeTab === 'disputes' ? 'active' : ''}`}
                                id='disputes'
                                onClick={handleActiveTab}
                            >
                                {t('disputes')}
                            </button>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default SideTabs;
