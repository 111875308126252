import React from 'react';

const OrderContext = React.createContext({
    orderContract: null,
    orderOnlyContract: null,
    orderOnlyContractAbi: null,
    orderStatsContract: null,
    orderContractAbi: null,
    orderStatsContractAbi: null,
    fetchingAllOrders: false,
    allOrders: [],
    allDisputes: [],
    commissionPercentage: 0,
    loadOrderContract: () => {},
    loadOrderOnlyContract: () => {},
    loadStatsOrderContract: () => {},
    loadAllOrders: () => {},
    loadAllDisputes: () => {},
    getOrderContractAbi: () => {},
    getOrderStatsContractAbi: () => {},
    loadCommissionPercentage: () => {},
    getOrderOnlyContractAbi: () => {},
});

export default OrderContext;
