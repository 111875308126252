import React, { useMemo, useState } from 'react';
import PageBanner from '../../components/general/PageBanner';
import { Link, useParams } from 'react-router-dom';
import CreateProductForm from './CreateProductForm';
import useShop from '../../hooks/useShop';
import NotFound from '../../components/NotFound';
import useWeb3 from '../../hooks/useWeb3';
import { useTranslation } from 'react-i18next';
import CreateDigitalProductForm from './CreateDigitalProductForm';

function CreateProductPage() {
    const { t } = useTranslation();
    const { slug } = useParams();
    const { account } = useWeb3();
    const { allShops } = useShop();
    const [productType, setProductType] = useState('physical');
    const targetShop = useMemo(() => {
        return allShops?.filter((shop) => shop?.shopSlug === slug)[0];
    }, [slug, allShops]);

    const isShopOwner = useMemo(() => {
        return Boolean(account === targetShop?.shopOwner);
    }, [account, targetShop]);

    return isShopOwner ? (
        <>
            {targetShop ? (
                <>
                    <PageBanner heading={t('createNewProduct')} />

                    <section className='pb-5'>
                        <div className='container pb-5'>
                            <div className='row'>
                                <div className='col-lg-8 mx-auto'>
                                    <div className='card'>
                                        <div className='card-body p-lg-5'>
                                            <div className='btn-tabs mb-4'>
                                                <button
                                                    className={`btn-tab flex-fill ${
                                                        productType === 'physical' ? 'active' : ''
                                                    }`}
                                                    onClick={() => setProductType('physical')}
                                                >
                                                    Physical
                                                </button>
                                                <button
                                                    className={`btn-tab flex-fill ${
                                                        productType === 'digital' ? 'active' : ''
                                                    }`}
                                                    onClick={() => setProductType('digital')}
                                                >
                                                    Digital
                                                </button>
                                            </div>
                                            {productType === 'physical' ? (
                                                <CreateProductForm targetShop={targetShop?.shopId} />
                                            ) : (
                                                <CreateDigitalProductForm targetShop={targetShop?.shopId} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            ) : (
                <NotFound
                    customMsg={
                        <>
                            <h1 data-aos='fade-up' data-aos-delay='100' className='text-xl'>
                                {t('shopNotFoundHeading')}
                            </h1>
                            <p className='text-muted lead' data-aos='fade-up' data-aos-delay='200'>
                                {t('shopNotFoundMsg')}
                            </p>
                            <Link className='btn btn-primary py-2' to='/account'>
                                {t('returnToAccountPage')}
                            </Link>
                        </>
                    }
                />
            )}
        </>
    ) : (
        <NotFound />
    );
}

export default CreateProductPage;
