import React from 'react';
import Popup from '../../components/general/Popup';
import { BsFillCircleFill } from 'react-icons/bs';
import { truncateStart } from '../../helpers/utils';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function OrderDetailsModal({ infoRequested, setInfoRequested, orderInfo }) {
    const { t } = useTranslation();
    return (
        <>
            {infoRequested && (
                <Popup closeModal={() => setInfoRequested(false)}>
                    <header className='mb-4 text-center'>
                        <h2 className='h1'>{t('orderDetails')}</h2>
                    </header>
                    <div className='row g-2'>
                        <div className='col-lg-6'>
                            <div className='p-3 rounded bg-gray-850'>
                                <h6 className='mb-3'>{t('tbProducts')}</h6>
                                <Link
                                    className='text-reset d-inline-block'
                                    to={`/products/${orderInfo?.orderProductSlug}`}
                                >
                                    <div className='d-flex align-items-center'>
                                        <div
                                            className='flex-shrink-0 bg-cover bg-center'
                                            style={{
                                                width: '40px',
                                                height: '40px',
                                                background: `url(${orderInfo?.orderProductGallery[0]})`,
                                                borderRadius: '0.5rem',
                                            }}
                                        ></div>
                                        <div className='ms-3'>
                                            <h6 className='mb-1'>{truncateStart(orderInfo?.orderProductName, 30)}</h6>
                                            <p className='text-primary small mb-0'>{orderInfo?.unitPrice} USD</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='p-3 rounded bg-gray-850'>
                                <h6 className='mb-3'>{t('buyer')}</h6>
                                <Link to={`/users/${orderInfo?.orderBuyerSlug}`} className='text-reset d-inline-block'>
                                    <div className='d-flex align-items-center'>
                                        <div
                                            className='flex-shrink-0 bg-cover bg-center'
                                            style={{
                                                width: '40px',
                                                height: '40px',
                                                backgroundImage: `url(${orderInfo?.orderBuyerProfile})`,
                                                borderRadius: '0.5rem',
                                            }}
                                        ></div>
                                        <div className='ms-3'>
                                            <h6 className='mb-1'>{orderInfo?.orderBuyerName}</h6>
                                            <p className='text-muted small mb-0'>{orderInfo?.orderBuyerEmail}</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='p-3 rounded bg-gray-850'>
                                <h6 className='mb-3'>{t('buyerPhoneNumber')}</h6>
                                <p className='mb-0 text-sm'>{orderInfo?.orderBuyerPhone}</p>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='p-3 rounded bg-gray-850'>
                                <h6 className='mb-3'>{t('purchasedAmount')}</h6>
                                <p className='mb-0 text-sm'>{orderInfo?.productAmount}</p>
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <div className='p-3 rounded bg-gray-850'>
                                <h6 className='mb-3'>{t('orderStatus')}</h6>
                                <p className='mb-0 text-sm text-capitalize'>
                                    <span className={`order-status ${orderInfo?.orderStatus}`}>
                                        <BsFillCircleFill className='me-2 mb-1' size={10} />
                                        {orderInfo?.orderStatus}
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <div className='p-3 rounded bg-gray-850'>
                                <h6 className='mb-3'>{t('buyerShippingAddress')}</h6>
                                <p className='mb-0 text-sm'>{orderInfo?.shippingAddress}</p>
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <div className='p-3 rounded bg-gray-850'>
                                <h6 className='mb-3'>{t('trackingURL')}</h6>
                                <p className='mb-0 text-sm'>
                                    {orderInfo?.trackingUrl === '' ? (
                                        'No tracking url provided'
                                    ) : (
                                        <a href={`${orderInfo?.trackingUrl}`} target='_blank' rel='noopener noreferrer'>
                                            {orderInfo?.trackingUrl}
                                        </a>
                                    )}
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <div className='p-3 rounded bg-gray-850'>
                                <h6 className='mb-3'>{t('orderShippingNotes')}</h6>
                                <p className='mb-0 text-sm'>
                                    {orderInfo?.shippingNotes === ''
                                        ? `${t('noShippingNotes')}`
                                        : orderInfo?.shippingNotes}
                                </p>
                            </div>
                        </div>
                    </div>
                </Popup>
            )}
        </>
    );
}

export default OrderDetailsModal;
