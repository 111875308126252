import React, { useReducer } from 'react';
import { readContract } from '@wagmi/core';
import Web3 from 'web3';
import { createSlug } from '../helpers/utils';

import OrderContext from './order-context';

const defaultOrderState = {
    orderContract: null,
    orderOnlyContract: null,
    orderOnlyContractAbi: null,
    orderContractAbi: null,
    orderStatsContract: null,
    orderStatsContractAbi: null,
    allOrders: [],
    allDisputes: [],
    fetchingAllOrders: true,
    commissionPercentage: 0,
};

const orderReducer = (state, action) => {
    if (action.type === 'CONTRACT') {
        return {
            ...state,
            orderContract: action.orderContract,
        };
    }
    if (action.type === 'ORDER_CONTRACT') {
        return {
            ...state,
            orderOnlyContract: action.orderOnlyContract,
        };
    }
    if (action.type === 'GET_ABI') {
        return {
            ...state,
            orderContractAbi: action.orderContractAbi,
        };
    }
    if (action.type === 'GET_STATS_ABI') {
        return {
            ...state,
            orderStatsContractAbi: action.orderStatsContractAbi,
        };
    }
    if (action.type === 'GET_ORDER_ABI') {
        return {
            ...state,
            orderOnlyContractAbi: action.orderOnlyContractAbi,
        };
    }
    if (action.type === 'STATS_CONTRACT') {
        return {
            ...state,
            orderStatsContract: action.orderStatsContract,
        };
    }
    if (action.type === 'LOAD_COMMISSION_PERCENTAGE') {
        return {
            ...state,
            commissionPercentage: Number(action.commissionPercentage) / 10,
        };
    }
    if (action.type === 'LOAD_ALL_DISPUTES') {
        return {
            ...state,
            allDisputes: action.allDisputes?.map((dispute) => {
                return {
                    disputeId: Number(dispute[0]) + 1,
                    disputeReason: dispute[1],
                    disputeDescription: dispute[2],
                    orderId: Number(dispute[3]),
                    productId: Number(dispute[4]),
                    disputeStatus: dispute[5] === 0 ? 'open' : 'resolved',
                    pendingAmount: Web3.utils.fromWei(
                        Number(dispute[7]).toLocaleString('fullwide', { useGrouping: false }).toString(),
                        'ether'
                    ),
                    createdAt: Number(dispute[8]) * 1000,
                    disputeOpenerAddress: dispute[6][0],
                    disputeOpenerName: dispute[6][1][0],
                    disputeOpenerSlug: `${createSlug(dispute[6][1][0])}${dispute[6][0].slice(-5)}`,
                    disputeOpenerEmail: dispute[6][1][1],
                    disputeOpenerProfile: dispute[6][1][3],
                    disputeOpenerPhone: dispute[6][1][4],
                    disputeDefendantAddress: dispute[10][0],
                    disputeDefendantName: dispute[10][1][0],
                    disputeDefendantSlug: `${createSlug(dispute[10][1][0])}${dispute[10][0].slice(-5)}`,
                    disputeDefendantEmail: dispute[10][1][1],
                    disputeDefendantProfile: dispute[10][1][3],
                    disputeDefendantPhone: dispute[10][1][4],
                    resolvingReason: dispute[9],
                };
            }),
        };
    }

    if (action.type === 'LOAD_ALL_ORDERS') {
        return {
            ...state,
            allOrders: action.allOrders?.map((order) => {
                return {
                    orderId: Number(order[0]),
                    orderBuyerAddress: order[1][0],
                    orderBuyerName: order[1][1],
                    orderBuyerSlug: createSlug(order[1][1]),
                    orderBuyerEmail: order[1][2],
                    orderBuyerProfile: order[1][3],
                    orderBuyerPhone: order[1][4],
                    productAmount: Number(order[2]),
                    unitPrice: Web3.utils.fromWei(
                        Number(order[3]).toLocaleString('fullwide', { useGrouping: false }).toString(),
                        'ether'
                    ),
                    totalPrice: Web3.utils.fromWei(
                        Number(order[4]).toLocaleString('fullwide', { useGrouping: false }).toString(),
                        'ether'
                    ),
                    orderStatus:
                        order[5] === 0
                            ? 'pending'
                            : order[5] === 1
                            ? 'delivered'
                            : order[5] === 2
                            ? 'canceled'
                            : 'shipped',
                    shippingPrice: Web3.utils.fromWei(
                        Number(order[6][0]).toLocaleString('fullwide', { useGrouping: false }).toString(),
                        'ether'
                    ),
                    shippingAddress: order[6][1],
                    shippingNotes: order[6][2],
                    trackingUrl: order[6][3],
                    createdAt: Number(order[11]) * 1000,
                    cancelTimeLimit: Number(order[11]) * 1000 + 86400000,
                    canCancel: Boolean(Number(order[11]) * 1000 + 86400000 > new Date().getTime()),
                    orderShopName: order[7][0],
                    orderShoSlug: createSlug(order[7][0]),
                    orderShopId: Number(order[7][1]),
                    orderShopLogo: order[7][2],
                    orderVendor: order[8][0],
                    orderVendorName: order[8][1],
                    orderVendorSlug: createSlug(order[8][1]),
                    orderVendorEmail: order[8][2],
                    orderVendorProfile: order[8][3],
                    orderVendorPhone: order[8][4],
                    orderProductId: Number(order[9][0]),
                    orderProductName: order[9][1],
                    orderProductSlug: createSlug(order[9][1]),
                    orderProductGallery: order[9][2],
                    orderProductPrice: Web3.utils.fromWei(
                        Number(order[9][3]).toLocaleString('fullwide', { useGrouping: false }).toString(),
                        'ether'
                    ),
                    hasDispute: order[10],
                };
            }),
            fetchingAllOrders: false,
        };
    }

    return defaultOrderState;
};

const OrderProvider = (props) => {
    const [orderState, dispatchOrderAction] = useReducer(orderReducer, defaultOrderState);

    const loadStatsOrderContractHandler = (orderStatsContract) => {
        dispatchOrderAction({ type: 'STATS_CONTRACT', orderStatsContract: orderStatsContract });
        return orderStatsContract;
    };

    const loadOrderOnlyContractHandler = (orderOnlyContract) => {
        dispatchOrderAction({ type: 'ORDER_CONTRACT', orderOnlyContract: orderOnlyContract });
        return orderOnlyContract;
    };

    const loadOrderContractHandler = (orderContract) => {
        dispatchOrderAction({ type: 'CONTRACT', orderContract: orderContract });
        return orderContract;
    };

    const getOrderContractAbiHandler = (abi) => {
        dispatchOrderAction({ type: 'GET_ABI', orderContractAbi: abi });
    };

    const getOrderOnlyContractAbiHandler = (abi) => {
        dispatchOrderAction({ type: 'GET_ORDER_ABI', orderOnlyContractAbi: abi });
    };

    const getOrderStatsContractAbiHandler = (abi) => {
        dispatchOrderAction({ type: 'GET_STATS_ABI', orderStatsContractAbi: abi });
    };

    const loadAllOrdersHandler = async (contract) => {
        const allOrders = await readContract({
            address: contract.address,
            abi: orderState.orderStatsContractAbi,
            functionName: 'allOrders',
        });

        dispatchOrderAction({ type: 'LOAD_ALL_ORDERS', allOrders: allOrders });

        return allOrders;
    };

    const loadAllDisputesHandler = async (contract) => {
        const allDisputes = await readContract({
            address: contract.address,
            abi: orderState.orderStatsContractAbi,
            functionName: 'allDisputes',
        });

        dispatchOrderAction({ type: 'LOAD_ALL_DISPUTES', allDisputes: allDisputes });
        return allDisputes;
    };

    const loadCommissionPercentageHandler = async (contract, abi) => {
        const commissionPercentage = await readContract({
            address: contract.address,
            abi: abi,
            functionName: 'commission',
        });

        dispatchOrderAction({ type: 'LOAD_COMMISSION_PERCENTAGE', commissionPercentage: commissionPercentage });

        return commissionPercentage;
    };

    const orderContext = {
        orderStatsContract: orderState.orderStatsContract,
        orderContract: orderState.orderContract,
        orderOnlyContractAbi: orderState.orderOnlyContractAbi,
        orderOnlyContract: orderState.orderOnlyContract,
        orderContractAbi: orderState.orderContractAbi,
        orderStatsContractAbi: orderState.orderStatsContractAbi,
        fetchingAllOrders: orderState.fetchingAllOrders,
        allOrders: orderState.allOrders,
        allDisputes: orderState.allDisputes,
        commissionPercentage: orderState.commissionPercentage,
        loadAllOrders: loadAllOrdersHandler,
        getOrderContractAbi: getOrderContractAbiHandler,
        loadOrderOnlyContract: loadOrderOnlyContractHandler,
        getOrderStatsContractAbi: getOrderStatsContractAbiHandler,
        loadAllDisputes: loadAllDisputesHandler,
        loadStatsOrderContract: loadStatsOrderContractHandler,
        loadOrderContract: loadOrderContractHandler,
        loadCommissionPercentage: loadCommissionPercentageHandler,
        getOrderOnlyContractAbi: getOrderOnlyContractAbiHandler,
    };

    return <OrderContext.Provider value={orderContext}>{props.children}</OrderContext.Provider>;
};

export default OrderProvider;
