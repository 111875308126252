import React, { useMemo } from 'react';
import UserBlock from '../../components/general/UserBlock';
import { calculateSellerRating } from '../../helpers/utils';
import { RiMoneyDollarCircleFill } from 'react-icons/ri';
import { AiFillShop } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import useUser from '../../hooks/useUser';
import useShop from '../../hooks/useShop';
import useOrder from '../../hooks/useOrder';
import useApp from '../../hooks/useApp';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { useTranslation } from 'react-i18next';

function BestSellers() {
    const { usersList } = useUser();
    const { allShops } = useShop();
    const { allOrders } = useOrder();
    const { themeMode } = useApp();
    const { t } = useTranslation();

    const vendors = useMemo(() => {
        return usersList
            ?.filter((user) => user?.is_vendor)
            ?.map((user) => ({
                ...user,
                shops: allShops?.filter((shop) => user?.address === shop.shopOwner),
                sales: allOrders?.filter(
                    (order) => order?.orderVendor === user?.address && order?.orderStatus !== 'canceled'
                )?.length,
                rating: calculateSellerRating(
                    allShops
                        ?.filter((shop) => shop.shopRating !== 0)
                        ?.filter((shop) => user?.address === shop.shopOwner)
                ),
            }));
    }, [usersList, allShops, allOrders]);

    const sellers = useMemo(() => {
        return vendors
            ?.filter((seller) => seller?.sales >= 1)
            ?.sort((a, b) => b?.sales - a?.sales)
            ?.slice(0, 3);
    }, [vendors]);

    return (
        <>
            {sellers && sellers?.length > 0 && (
                <section className={`py-5 ${themeMode === 'light' ? 'bg-light' : 'bg-gray-950'}`}>
                    <div className='container py-5'>
                        <header className='mb-3'>
                            <h2 data-aos='fade-up'>{t('ourBestSellers')}</h2>
                            <p className='text-muted' data-aos='fade-up' data-aos-delay='100'></p>
                        </header>
                        <div className='swiper-wrapper-padding'>
                            <Swiper
                                breakpoints={{
                                    560: {
                                        slidesPerView: 1,
                                        spaceBetween: 0,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                        spaceBetween: 0,
                                    },
                                    1200: {
                                        slidesPerView: 3,
                                        spaceBetween: 0,
                                    },
                                }}
                                modules={[Pagination]}
                                pagination={{ clickable: true }}
                            >
                                {sellers?.map((vendor, index) => {
                                    return (
                                        <SwiperSlide key={index}>
                                            <div
                                                className='px-3'
                                                key={index}
                                                data-aos='fade-up'
                                                data-aos-delay={index * 100}
                                            >
                                                <Link className='text-reset' to={`/sellers/${vendor?.user_slug}`}>
                                                    <div className='card card-hover mb-0'>
                                                        <div className='card-body'>
                                                            <UserBlock
                                                                {...vendor}
                                                                isPrivate={true}
                                                                vendorRating={0}
                                                                hasRating={true}
                                                            />
                                                            <div className='p-3 bg-gray-850 rounded mt-3'>
                                                                <ul className='list-inline mb-0 text-muted small'>
                                                                    <li className='list-inline-item'>
                                                                        <RiMoneyDollarCircleFill
                                                                            className='text-primary me-1 mb-1'
                                                                            size={20}
                                                                        />
                                                                        <strong className='fw-bold text-white me-1'>
                                                                            {vendor?.sales}
                                                                        </strong>{' '}
                                                                        {t('sales')}
                                                                    </li>
                                                                    <li className='list-inline-item'> | </li>
                                                                    <li className='list-inline-item'>
                                                                        <AiFillShop
                                                                            className='text-primary me-1 mb-1'
                                                                            size={20}
                                                                        />
                                                                        <strong className='fw-bold text-white me-1'>
                                                                            {vendor?.shops?.length}
                                                                        </strong>{' '}
                                                                        {t('shops')}
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </SwiperSlide>
                                    );
                                })}
                            </Swiper>
                        </div>
                    </div>
                </section>
            )}
        </>
    );
}

export default BestSellers;
