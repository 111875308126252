import React, { useMemo } from 'react';
import useWeb3 from '../../hooks/useWeb3';
import useOrder from '../../hooks/useOrder';
import useShop from '../../hooks/useShop';
import ProductCard from '../../components/general/ProductCard';
import { useTranslation } from 'react-i18next';

function UserPurchases() {
    const { account } = useWeb3();
    const { allOrders } = useOrder();
    const { allProducts } = useShop();
    const { t } = useTranslation();

    const userSuccessfulOrders = useMemo(() => {
        return allOrders?.filter((order) => order?.orderBuyerAddress === account && order?.orderStatus !== 'canceled');
    }, [account, allOrders]);

    const userPurchasedProductIds = useMemo(() => {
        return userSuccessfulOrders?.map((order) => order?.orderProductId);
    }, [userSuccessfulOrders]);

    const extractedProducts = useMemo(() => {
        return allProducts?.filter((product) => userPurchasedProductIds.includes(product?.productId));
    }, [userPurchasedProductIds, allProducts]);

    return (
        <>
            <div className='row g-2'>
                {extractedProducts?.length > 0 ? (
                    extractedProducts?.map((product, index) => {
                        return (
                            <div
                                className='col-xxl-3 col-xl-4 col-md-6'
                                data-aos='fade-up'
                                data-aos-delay={index * 100}
                                key={index}
                            >
                                <ProductCard {...product} />
                            </div>
                        );
                    })
                ) : (
                    <div className='col-lg-6'>
                        <div>
                            <h2>{t('emptyHere')}</h2>
                            <p className='text-muted'>{t('noPurchasesAtTheMoment')}</p>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default UserPurchases;
