import React, { useEffect, useState } from 'react';
import PageBanner from '../../components/general/PageBanner';
import useUser from '../../hooks/useUser';
import SideTabs from './SideTabs';
import UserShops from './UserShops';
import UserProducts from './UserProducts';
import UserPurchases from './UserPurchases';
import UserOrders from './UserOrders';
import UserProfits from './UserProfits';
import UserDisputes from './UserDisputes';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function UserAccountTabPage() {
    const { userInfo } = useUser();
    const [activeTab, setActiveTab] = useState(null);
    const { tab } = useParams();
    const { t } = useTranslation();

    useEffect(() => {
        if (tab !== '') {
            setActiveTab(tab);
        }
    }, [tab]);

    /* --------------------------------------------- 
          CHECK IF CONNECTED USER IS A VENDOR
    --------------------------------------------- */
    useEffect(() => {
        if (userInfo?.is_vendor) {
            setActiveTab('shops');
        } else {
            setActiveTab('purchases');
        }
    }, [userInfo]);

    /* --------------------------------------------- 
          CHANGE RENDERED ACTIVE TAB
    --------------------------------------------- */
    function handleActiveTab(e) {
        setActiveTab(e.target.id);
    }

    return (
        <>
            <PageBanner heading={`${t('welcome')} ${userInfo?.full_name}`} />

            <section className='pb-5'>
                <div className='container pb-5'>
                    <div className='row g-5'>
                        <div className='col-xxl-2 col-xl-3 col-lg-4'>
                            <SideTabs activeTab={activeTab} handleActiveTab={handleActiveTab} />
                        </div>
                        <div className='col-xxl-10 col-xl-9 col-lg-8'>
                            {activeTab === 'shops' && <UserShops />}
                            {activeTab === 'products' && <UserProducts />}
                            {activeTab === 'profits' && <UserProfits profits={userInfo?.profits} />}
                            {activeTab === 'orders' && <UserOrders isVendor={userInfo?.is_vendor} />}
                            {activeTab === 'purchases' && <UserPurchases />}
                            {activeTab === 'disputes' && <UserDisputes />}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default UserAccountTabPage;
