import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import FetchingDataLoader from '../../components/general/FetchingDataLoader';
import NotFound from '../../components/NotFound';
import Popup from '../../components/general/Popup';
import useShop from '../../hooks/useShop';
import ProductGallery from './ProductGallery';
import ProductInfo from './ProductInfo';
import ProductRatingForm from './ProductRatingForm';
// import ProductSpecs from './ProductSpecs';
// import SimilarProducts from './SimilarProducts';
import useUser from '../../hooks/useUser';
import ProductReviews from './ProductReviews';
import { appSettings } from '../../helpers/settings';
import useApp from '../../hooks/useApp';
import { readContract } from '@wagmi/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useDf from '../../hooks/useDf';

function DigitalProductSinglePage() {
    const { t } = useTranslation();
    const { digitalFiles, fetchingAllProducts, productReviews, loadProductReviews } = useShop();
    const { dfContract, dfAbi } = useDf();
    const { userInfo } = useUser();
    const { slug } = useParams();
    const { themeMode, contract, abi } = useApp();
    const [ratingRequested, setRatingRequested] = useState(false);
    const [buyersAddresses, setBuyersAddresses] = useState([]);

    // GET TARGET PRODUCT ------------------------
    const currentProduct = useMemo(() => {
        return digitalFiles?.filter((product) => product?.productSlug === slug)[0];
    }, [slug, digitalFiles]);

    const isBuyer = useMemo(() => {
        return Boolean(buyersAddresses?.includes(userInfo?.address));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buyersAddresses, userInfo, currentProduct]);

    /* --------------------------------------------- 
          CHANGE PAGE TAB TITLE
    --------------------------------------------- */
    useEffect(() => {
        document.title = `${appSettings?.brandName} | ${currentProduct?.productName}`;
    }, [currentProduct]);

    /* --------------------------------------------- 
          GET THE PRODUCT BUYERS
    --------------------------------------------- */
    async function getProductBuers() {
        if (currentProduct) {
            const productBuyers = await readContract({
                address: dfContract.address,
                abi: dfAbi,
                functionName: 'getFileBuyers',
                args: [currentProduct?.productId],
            });

            setBuyersAddresses(productBuyers);
        }
    }

    console.log(buyersAddresses);

    /* --------------------------------------------- 
          GET PRODUCT BUYERS
    --------------------------------------------- */
    useEffect(() => {
        let signal = true;
        signal && getProductBuers();
        return () => (signal = false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProduct]);

    /* --------------------------------------------- 
          GET PRODUCT REVIEWS
    --------------------------------------------- */
    useEffect(() => {
        if (contract && currentProduct) {
            loadProductReviews(contract, currentProduct?.productId, abi, 'getFileRates');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contract, currentProduct]);

    return (
        <>
            {fetchingAllProducts ? (
                <FetchingDataLoader />
            ) : currentProduct ? (
                <>
                    <section className={`bg-light py-4 ${themeMode === 'light' ? 'bg-light' : 'bg-dark'}`}>
                        <div className='container'>
                            <nav aria-label='breadcrumb'>
                                <ol className='breadcrumb mb-0 text-sm'>
                                    <li className='breadcrumb-item'>
                                        <Link to='/'>{t('home')}</Link>
                                    </li>
                                    <li className='breadcrumb-item'>
                                        <Link to='/digital-products'>Digital Products</Link>
                                    </li>
                                    <li className='breadcrumb-item active' aria-current='page'>
                                        {currentProduct?.productName}
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </section>
                    <section className='py-5'>
                        <div className='container py-5'>
                            <div className='row gy-5 mb-5'>
                                {/* PRODUCT GALLERY */}
                                <div className='col-lg-6'>
                                    {currentProduct?.productGallery && (
                                        <div className='product-gallery-holder'>
                                            <ProductGallery gallery={currentProduct?.productGallery} />
                                        </div>
                                    )}
                                </div>

                                {/* PRODUCT INFORMATION */}
                                <div className='col-lg-6'>
                                    {currentProduct && (
                                        <ProductInfo
                                            // refreshBuyers={() => getProductBuers(currentProduct?.productId)}
                                            setRatingRequested={() => setRatingRequested(true)}
                                            isBuyer={isBuyer}
                                            // productReviews={productReviews}
                                            {...currentProduct}
                                        />
                                    )}
                                </div>
                            </div>

                            {/* PRODUCT REVIEWS */}
                            <div className='row'>
                                <div className='col-lg-5'>
                                    {currentProduct && <ProductReviews productReviews={productReviews} />}
                                </div>
                            </div>

                            {/* SIMILAR PRODUCTS */}
                            {/* {currentProduct && (
                                <div className='mt-5'>
                                    <SimilarProducts productCategory={currentProduct?.productCategory} />
                                </div>
                            )} */}

                            {/* RATE PRODUCT FORM */}
                            {currentProduct && ratingRequested && (
                                <Popup closeModal={setRatingRequested} containerClass='col-lg-6'>
                                    <ProductRatingForm
                                        closeModal={() => setRatingRequested(false)}
                                        productId={currentProduct?.productId}
                                    />
                                </Popup>
                            )}
                        </div>
                    </section>
                </>
            ) : (
                <NotFound />
            )}
        </>
    );
}

export default DigitalProductSinglePage;
