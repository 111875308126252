import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import useApp from '../../hooks/useApp';
import { toast } from 'react-toastify';
import { IoDocumentAttachSharp } from 'react-icons/io5';
import { appSettings } from '../../helpers/settings';
import { truncateStart } from '../../helpers/utils';
import emailjs from '@emailjs/browser';
import useShop from '../../hooks/useShop';
import { useContractWrite } from 'wagmi';
import { useTranslation } from 'react-i18next';

// IPFS CREATE HOST
const auth = 'Basic ' + Buffer.from(appSettings.IPFSProjectID + ':' + appSettings.IPFSSecret).toString('base64');
const ipfsClient = require('ipfs-http-client');
const ipfs = ipfsClient.create({
    host: 'ipfs.infura.io',
    port: 5001,
    protocol: 'https',
    headers: {
        authorization: auth,
    },
});

function UpdateDocsForm({ shopId, closeModal, docs, shopTitle }) {
    const { contract, setTransactionLoading } = useApp();
    const { shopContractAbi, loadAllShops, loadAllProducts } = useShop();
    const [shopDocs, setShopDocs] = useState({});
    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    /* --------------------------------------------- 
              UPDATe SHOP DOCS FUNCTION
     --------------------------------------------- */
    const { write: web3UpdateDocs } = useContractWrite({
        address: contract?.address,
        abi: shopContractAbi,
        functionName: 'addDocumentToShop',
        onSuccess() {
            emailjs
                .send(
                    appSettings.mailJServiceKey,
                    appSettings.mailJSVerifyTemplateID,
                    { ...shopDocs },
                    appSettings.mailJSPublicKey
                )
                .then(
                    (result) => {
                        toast.success(`${t('mailSent')}`);
                    },
                    (error) => {
                        console.log(error);
                        toast.error(`${t('toastError')}`);
                    }
                );
            closeModal();
            toast.success(`${t('reviewAdded')}`);
            setTransactionLoading(false);
            loadAllShops(contract);
            loadAllProducts(contract);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error(`${t('toastError')}`);
        },
    });

    /* --------------------------------------------- 
          VALIDATE TWITTER URL
    --------------------------------------------- */
    const validateTwitterUrl = (value) => {
        const regex = /^https?:\/\/(?:www\.)?twitter\.com\/(?:#!\/)?(\w+)\/?$/i;
        if (!regex.test(value)) {
            return t('twitterProfilePattern');
        }
    };

    /* --------------------------------------------- 
          VALIDATE PDF FILE
    --------------------------------------------- */
    const validatePdfFile = (file) => {
        if (file?.length > 0 && file[0]?.type !== 'application/pdf') {
            return t('certificatePattern');
        }
    };

    /* --------------------------------------------- 
          SUBMIT PRODUCT REVIEW FUNCTION
    --------------------------------------------- */
    async function handleRatingSubmit(data) {
        setTransactionLoading(true);

        const ipfsCertificate =
            data?.certificate?.length > 0 ? await ipfs.add(data?.certificate[0]) : docs?.certificate;

        if (ipfsCertificate) {
            setShopDocs({
                from_name: appSettings?.brandName,
                shop_name: shopTitle,
                company_name: data?.company_name,
                company_address: data?.company_address,
                phone_number: data?.phone_number,
                twitter_profile: data?.twitter_profile,
                email: data?.email,
                certificate:
                    data?.certificate?.length > 0
                        ? `https://${appSettings.IPFSGatewaySubdomain}.infura-ipfs.io/ipfs/${ipfsCertificate?.path}`
                        : docs?.certificate,
                reply_to: appSettings?.shopVerifyMail,
            });

            web3UpdateDocs({
                recklesslySetUnpreparedArgs: [
                    shopId,
                    [
                        data?.company_name,
                        data?.company_address,
                        data?.phone_number,
                        data?.email,
                        data?.certificate?.length > 0
                            ? `https://${appSettings.IPFSGatewaySubdomain}.infura-ipfs.io/ipfs/${ipfsCertificate?.path}`
                            : docs?.certificate,
                        data?.twitter_profile,
                    ],
                ],
            });
        }
    }

    return (
        <>
            <header className='text-center mb-4'>
                <h3>{t('updateVerificationDocs')}</h3>
                <p className='text-muted'>{t('updateVerificationDocsSubheading')}</p>
            </header>

            <form className='row g-3' onSubmit={handleSubmit(handleRatingSubmit)}>
                {/* COMPANY NAME */}
                <div className='col-12'>
                    <label className='form-label'>{t('companyName')}</label>
                    <input
                        type='text'
                        placeholder={t('companyNamePlaceholder')}
                        name='company_name'
                        className={`form-control ${errors.company_name ? 'is-invalid' : ''}`}
                        {...register('company_name', {
                            value: docs?.companyName,
                            required: {
                                value: true,
                                message: t('companyNameErrMsg'),
                            },
                        })}
                    />
                    {errors.company_name && <span className='invalid-feedback'>{errors.company_name.message}</span>}
                </div>

                {/* COMPANY ADDRESS */}
                <div className='col-12'>
                    <label className='form-label'>{t('companyAddress')}</label>
                    <textarea
                        type='text'
                        placeholder={t('companyAddress')}
                        name='company_address'
                        className={`form-control ${errors.company_address ? 'is-invalid' : ''}`}
                        {...register('company_address', {
                            value: docs?.companyAddress,
                            required: {
                                value: true,
                                message: 'Please add your company address',
                            },
                        })}
                    ></textarea>
                    {errors.company_address && (
                        <span className='invalid-feedback'>{errors.company_address?.message}</span>
                    )}
                </div>

                {/* PHONE NUMBER */}
                <div className='col-lg-6'>
                    <label className='form-label' htmlFor='phoneNumber'>
                        {t('phoneNumber')}
                    </label>
                    <input
                        type='tel'
                        className={`form-control ${errors.phone_number ? 'is-invalid' : ''}`}
                        id='phoneNumber'
                        placeholder={t('verifiyPhoneNumberPlaceholder')}
                        name='phone_number'
                        {...register('phone_number', {
                            value: docs?.phoneNumber,
                            required: {
                                value: true,
                                message: t('verifyPhoneNumberErrMsg'),
                            },
                            pattern: {
                                value: /^\+(?:[0-9] ?){6,14}[0-9]$/,
                                message: t('verifyPhoneNumberPattern'),
                            },
                        })}
                    />
                    {errors.phone_number && <span className='invalid-feedback'>{errors.phone_number?.message}</span>}
                </div>

                {/* EMAIL ADDRESS */}
                <div className='col-lg-6'>
                    <label className='form-label' htmlFor='email'>
                        {t('userMail')}
                    </label>
                    <input
                        type='email'
                        className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                        id='email'
                        placeholder={t('userMailPlaceholder')}
                        name='email'
                        {...register('email', {
                            value: docs?.email,
                            required: {
                                value: true,
                                message: t('userMailErrMsg'),
                            },
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: t('shopMailPattern'),
                            },
                        })}
                    />
                    {errors.email && <span className='invalid-feedback'>{errors.email?.message}</span>}
                </div>

                {/* TWITTER PROFILE */}
                <div className='col-12'>
                    <label className='form-label'>{t('twitterProfile')}</label>
                    <input
                        type='text'
                        placeholder={t('twitterProfilePlaceholder')}
                        name='twitter_profile'
                        className={`form-control ${errors.twitter_profile ? 'is-invalid' : ''}`}
                        {...register('twitter_profile', {
                            value: docs?.twitterProfile,
                            required: {
                                value: true,
                                message: t('twitterProfileErrMsg'),
                            },
                            validate: validateTwitterUrl,
                        })}
                    />
                    {errors.twitter_profile && (
                        <span className='invalid-feedback'>{errors.twitter_profile?.message}</span>
                    )}
                </div>

                <div className='col-lg-12'>
                    <label className='form-label' htmlFor='certificate'>
                        {t('companyCertificate')}
                    </label>
                    <input
                        type='file'
                        className={`form-control ${errors.certificate ? 'is-invalid' : ''}`}
                        id='certificate'
                        accept='.pdf'
                        name='certificate'
                        {...register('certificate', {
                            required: {
                                value: false,
                            },
                            validate: validatePdfFile,
                        })}
                    />
                    <div className='bg-gray-850 px-3 py-2 rounded mt-2'>
                        <a
                            href={`${docs?.certificate}`}
                            target='_blank'
                            rel='noopener noreferrer'
                            className='text-reset text-white text-sm'
                        >
                            {truncateStart(docs?.certificate, 40)}
                        </a>
                    </div>
                    {errors.certificate && <span className='invalid-feedback'>{errors.certificate?.message}</span>}
                </div>

                <div className=' col-12'>
                    <button className='btn btn-primary' type='submit'>
                        <IoDocumentAttachSharp className='me-2 mb-1' size={20} />
                        {t('editYourDocs')}
                    </button>
                </div>
            </form>
        </>
    );
}

export default UpdateDocsForm;
