import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import FetchingDataLoader from '../../components/general/FetchingDataLoader';
import NotFound from '../../components/NotFound';
import useShop from '../../hooks/useShop';
import ShopBanner from './ShopBanner';
import ShopProducts from './ShopProducts';
import Select from 'react-select';
import ShopDigitalProducts from './ShopDigitalProducts';

function ShopSinglePage() {
    const { allShops, allProducts, digitalFiles, fetchingAllShops } = useShop();
    const [productsType, setProductType] = useState({ label: 'Physical Products', value: 'Physical Products' });
    const { slug } = useParams();

    const currentShop = useMemo(() => {
        return allShops?.filter((shop) => shop?.shopSlug === slug)[0];
    }, [slug, allShops]);

    const currentShopId = useMemo(() => {
        return allShops?.filter((shop) => shop?.shopSlug === slug)[0]?.shopId;
    }, [slug, allShops]);

    const shopProducts = useMemo(() => {
        return allProducts?.filter((product) => product?.productShopId === currentShopId);
    }, [allProducts, currentShopId]);

    const shopFiles = useMemo(() => {
        return digitalFiles?.filter((product) => product?.productShopId === currentShopId);
    }, [digitalFiles, currentShopId]);

    console.log(productsType);

    return (
        <>
            {fetchingAllShops ? (
                <FetchingDataLoader />
            ) : currentShop ? (
                <>
                    <ShopBanner {...currentShop} />
                    {/* {shopProducts && <ShopProducts allProducts={shopProducts} />} */}
                    {shopProducts && productsType?.label === 'Physical Products' && (
                        <ShopProducts allProducts={shopProducts}>
                            <header className='mb-5'>
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <h2>Shop Physical Products</h2>
                                        <p className='text-muted'></p>
                                        <div className='w-auto d-inline-block'>
                                            <Select
                                                options={[
                                                    { label: 'Digital Files', value: 'Digital Files' },
                                                    { label: 'Physical Products', value: 'Physical Products' },
                                                ]}
                                                id='category'
                                                className={`border-0 shadow-sm`}
                                                classNamePrefix='select'
                                                placeholder={productsType}
                                                defaultValue={productsType}
                                                onChange={setProductType}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </header>
                        </ShopProducts>
                    )}
                    {shopProducts && productsType?.label === 'Digital Files' && (
                        <ShopDigitalProducts allProducts={shopFiles}>
                            <header className='mb-5'>
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <h2>Shop Digital Files</h2>
                                        <p className='text-muted'></p>
                                        <div className='w-auto d-inline-block'>
                                            <Select
                                                options={[
                                                    { label: 'Digital Files', value: 'Digital Files' },
                                                    { label: 'Physical Products', value: 'Physical Products' },
                                                ]}
                                                id='category'
                                                className={`border-0 shadow-sm`}
                                                classNamePrefix='select'
                                                placeholder={productsType}
                                                defaultValue={productsType}
                                                onChange={setProductType}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </header>
                        </ShopDigitalProducts>
                    )}
                </>
            ) : (
                <NotFound />
            )}
        </>
    );
}

export default ShopSinglePage;
