import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { englishTranslation } from '../translations/en';
import { frenchTranslation } from '../translations/fr';
import { italianTranslation } from '../translations/it';
import { chineseTranslation } from '../translations/zh';
import { spanishTranslation } from '../translations/es';
import { russianTranslation } from '../translations/ru';
import { indianTranslation } from '../translations/hi';
import { polishTranslation } from '../translations/po';

i18n.use(initReactI18next).init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
    },
    resources: {
        po: {
            translation: {
                ...polishTranslation,
            },
        },
        en: {
            translation: {
                ...englishTranslation,
            },
        },
        fr: {
            translation: {
                ...frenchTranslation,
            },
        },
        it: {
            translation: {
                ...italianTranslation,
            },
        },
        zh: {
            translation: {
                ...chineseTranslation,
            },
        },
        es: {
            translation: {
                ...spanishTranslation,
            },
        },
        ru: {
            translation: {
                ...russianTranslation,
            },
        },
        hi: {
            translation: {
                ...indianTranslation,
            },
        },
    },
});

export default i18n;
