import React from 'react';
import { Link } from 'react-router-dom';
import { languages } from '../../helpers/constants';
import useApp from '../../hooks/useApp';
import { FaGlobeAfrica } from 'react-icons/fa';

function SwitchLanguage() {
    const { lang, switchLanguage } = useApp();

    return (
        <>
            <div className='dropdown'>
                <Link
                    className='nav-link dropdown-toggle no-caret d-flex align-items-center text-reset text-sm fw-normal text-capitalize px-2'
                    to='/'
                    role='button'
                    data-bs-toggle='dropdown'
                    data-bs-target='#languageDropdown'
                    aria-expanded='false'
                >
                    <FaGlobeAfrica className='me-2' />
                    {lang?.key}
                </Link>
                <ul className='dropdown-menu' id='languageDropdown' style={{ minWidth: '10rem' }}>
                    {languages?.map((language, index) => {
                        return (
                            <li key={index}>
                                <button
                                    type='button'
                                    className={`dropdown-item rounded text-sm ${
                                        language.code === lang.code ? 'active' : ''
                                    }`}
                                    onClick={() => {
                                        switchLanguage({ code: language?.code, key: language?.key });
                                    }}
                                >
                                    {language.key}
                                </button>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </>
    );
}

export default SwitchLanguage;
