import React, { useState, useContext } from 'react';
import Select from 'react-select';
import Web3Context from '../../providers/web3-context';
import Web3 from 'web3';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { appSettings } from '../../helpers/settings';
import ConnectWalletHander from '../../components/general/ConnectWalletHandler';
import UploadProgress from '../../components/general/UploadProgress';
import { toast } from 'react-toastify';
import { useContractWrite } from 'wagmi';
import { useTranslation } from 'react-i18next';
import useDf from '../../hooks/useDf';
import useShop from '../../hooks/useShop';
import useApp from '../../hooks/useApp';

// IPFS CREATE HOST
const auth = 'Basic ' + Buffer.from(appSettings.IPFSProjectID + ':' + appSettings.IPFSSecret).toString('base64');
const ipfsClient = require('ipfs-http-client');
const ipfs = ipfsClient.create({
    host: 'ipfs.infura.io',
    port: 5001,
    protocol: 'https',
    headers: {
        authorization: auth,
    },
});

function CreateDigitalProductForm({ targetShop }) {
    const [category, setCategory] = useState({ label: 'eBooks', translationKey: 'ebooks', value: 'eBooks' });
    const [file, setFile] = useState('');
    const [screenshot, setScreenshot] = useState('');
    const [thumbnail, setThumbnail] = useState('');
    const [type, setType] = useState('');
    const [compatibility, setCombatibility] = useState([]);
    const web3Ctx = useContext(Web3Context);
    const { dfContract, dfAbi } = useDf();
    const { setTransactionLoading, setUploadingProgress, uploadingProgress } = useApp();
    const { loadDigitalFiles } = useShop();
    const navigate = useNavigate();
    const [submitted, setSubmitted] = useState(false);
    const [fileUploadProgress, setFileUploadProgress] = useState(0);
    const [thumbnailUploadProgress, setThumbnailUploadProgress] = useState(0);
    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    // CATCH INPUT VIDEO ----------------------------------
    const onSelectFile = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setFile(undefined);
            return;
        }
        setFile(e.target.files[0]);
        setType(e.target.files[0].type);
    };

    // CATCH INPUT VIDEO ----------------------------------
    const onSelectThumbnail = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setFile(undefined);
            return;
        }
        setThumbnail(e.target.files[0]);
    };

    // CATCH INPUT PREVIEW ----------------------------------
    const onSelectScreenshot = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setScreenshot(undefined);
            return;
        }
        setScreenshot(e.target.files);
    };

    function handleMediaInputsSubmit() {
        setSubmitted(true);
    }

    /* --------------------------------------------- 
              UPLOAD FILE FUNCTION
     --------------------------------------------- */
    const { write: web3UploadFile } = useContractWrite({
        address: dfContract?.address,
        abi: dfAbi,
        functionName: 'uploadFile',
        onSuccess() {
            setTimeout(() => {
                setTransactionLoading(false);
                loadDigitalFiles(dfContract, dfAbi);
                navigate('/account');
                toast.success(t('fileUploaded'));
            }, 5000);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setUploadingProgress(false);
            setTransactionLoading(false);
            toast.error(t('err'));
        },
    });

    // SUBMIT MINT FORM ----------------------------------
    function handleFormSubmit(data) {
        if (file !== '' && screenshot !== '' && thumbnail !== '') {
            setUploadingProgress(true);
        }
        async function uploadDigitalFiles() {
            try {
                if (file !== '' && screenshot !== '' && compatibility?.length !== 0) {
                    const fileProgress = function (data) {
                        let percent = Math.floor((Number(data) * 100) / file.size);
                        setFileUploadProgress((prev) => percent);
                    };
                    const thumbnailProgress = function (data) {
                        let percent = Math.floor((Number(data) * 100) / file.size);
                        setThumbnailUploadProgress((prev) => percent);
                    };
                    let ipfsScreenshots = [];
                    let ipfsScreenshotHashes = [];
                    for (let i = 0; i < screenshot.length; i++) {
                        const ipfsFile = screenshot[i];
                        const results = await ipfs.add(ipfsFile);
                        ipfsScreenshots.push(results?.path);
                    }
                    ipfsScreenshotHashes = ipfsScreenshots?.map(
                        (hash) => `https://${appSettings.IPFSGatewaySubdomain}.infura-ipfs.io/ipfs/${hash}`
                    );
                    const ipfsFile = await ipfs.add(file, { progress: fileProgress });
                    const ipfsThumbnail = await ipfs.add(thumbnail, { progress: thumbnailProgress });
                    if (ipfsFile && ipfsScreenshotHashes?.length > 0 && ipfsThumbnail) {
                        setUploadingProgress(false);
                        web3UploadFile({
                            recklesslySetUnpreparedArgs: [
                                Number(targetShop),
                                [
                                    data.product_name,
                                    data.description,
                                    category.value,
                                    type,
                                    `https://${appSettings.IPFSGatewaySubdomain}.infura-ipfs.io/ipfs/${ipfsFile.path}`,
                                    ipfsScreenshotHashes,
                                    compatibility?.map((os) => os?.value),
                                    `https://${appSettings.IPFSGatewaySubdomain}.infura-ipfs.io/ipfs/${ipfsThumbnail.path}`,
                                ],
                                Web3.utils.toWei(data.price.toString(), 'ether'),
                            ],
                        });
                    } else {
                        setUploadingProgress(false);
                        setTransactionLoading(false);
                    }
                }
            } catch (error) {
                setUploadingProgress(false);
                setTransactionLoading(false);
                toast.error(error?.message.includes('413') ? t('filesTooLarge') : error?.message);
                console.log(error);
            }
        }
        uploadDigitalFiles();
    }

    return (
        <>
            {uploadingProgress && <UploadProgress file={fileUploadProgress} screenshot={thumbnailUploadProgress} />}
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <div className='row gy-3'>
                    <div className='col-12'>
                        <label className='form-label'>{t('productName')}</label>
                        <input
                            type='text'
                            className={`form-control ${errors.product_name ? 'is-invalid' : ''}`}
                            placeholder={t('productNamePlaceholder')}
                            {...register('product_name', {
                                required: {
                                    value: true,
                                    message: t('productNameErrMsg'),
                                },
                                minLength: {
                                    value: 3,
                                    message: t('productNameMinLengthMsg'),
                                },
                            })}
                        />
                        {errors.product_name && <span className='invalid-feedback'>{errors.product_name.message}</span>}
                    </div>
                    <div className='col-12'>
                        <label className='form-label'>{t('productDescription')}</label>
                        <textarea
                            rows='4'
                            className={`form-control ${errors.description ? 'is-invalid' : ''}`}
                            placeholder={t('productDescriptionErrMsg')}
                            {...register('description', {
                                required: {
                                    value: true,
                                    message: t('productDescriptionErrMsg'),
                                },
                                minLength: {
                                    value: 30,
                                    message: t('productDescriptionMinLenght'),
                                },
                                maxLength: {
                                    value: 300,
                                    message: t('productDescriptionMaxLenght'),
                                },
                            })}
                        />
                        {errors.description && <span className='invalid-feedback'>{errors.description.message}</span>}
                    </div>
                    <div className='col-12'>
                        <label className='form-label'>{t('category')}</label>
                        <Select
                            options={appSettings.categoryOptions?.map((ct) => ({
                                label: t(`${ct?.translationKey}`),
                                value: ct?.value,
                            }))}
                            className='border-0 shadow-sm'
                            classNamePrefix='select'
                            placeholder={t('categoryPlaceholder')}
                            onChange={setCategory}
                            isSearchable={false}
                            defaultValue={category}
                        />
                    </div>
                    <div className='col-12'>
                        <label className='form-label'>{t('compatibleWith')}</label>
                        <Select
                            options={appSettings.compatibilityOptions}
                            className={`shadow-sm ${
                                submitted && compatibility?.length === 0 ? 'is-invalid invalid-select' : ''
                            }`}
                            classNamePrefix='select'
                            placeholder={t('compatibleWithPlaceholder')}
                            onChange={setCombatibility}
                            isSearchable={false}
                            defaultValue={compatibility}
                            isMulti={true}
                        />
                        {submitted && compatibility?.length === 0 && (
                            <span className='invalid-feedback'>{t('compatibleWithErrorMsg')}</span>
                        )}
                    </div>
                    <div className='col-12'>
                        <label className='form-label'>{t('price')}</label>
                        <input
                            type='number'
                            min='0'
                            step='0.0001'
                            className={`form-control ${errors.price ? 'is-invalid' : ''}`}
                            placeholder={`${t('pricePlaceholder')} USD`}
                            {...register('price', { required: true })}
                        />
                        {errors.price && <span className='invalid-feedback'>{t('priceErrorMsg')}</span>}
                    </div>
                    <div className='col-12'>
                        <label className='form-label'>{t('uploadFile')}</label>
                        <input
                            type='file'
                            className={`form-control ${submitted && file === '' ? 'is-invalid' : ''}`}
                            placeholder={t('uploadFile')}
                            onChange={onSelectFile}
                            accept='.zip, .rar'
                        />
                        {submitted && file === '' && (
                            <span className='invalid-feedback'>{t('uploadFileErrorMsg')}</span>
                        )}
                    </div>
                    <div className='col-12'>
                        <label className='form-label'>{t('uploadFileThumb')}</label>
                        <input
                            type='file'
                            className={`form-control ${submitted && thumbnail === '' ? 'is-invalid' : ''}`}
                            placeholder={t('uploadFileThumb')}
                            onChange={onSelectThumbnail}
                            accept='.png, .jpg, .jpeg'
                        />
                        {submitted && thumbnail === '' && (
                            <span className='invalid-feedback'>{t('uploadFileThumbErrorMsg')}</span>
                        )}
                    </div>
                    <div className='col-12'>
                        <label className='form-label'>{t('uploadFileScreenshots')}</label>
                        <input
                            type='file'
                            className={`form-control ${submitted && screenshot === '' ? 'is-invalid' : ''}`}
                            placeholder={t('uploadFileScreenshots')}
                            onChange={onSelectScreenshot}
                            accept='.jpg, .png, jpeg'
                            multiple={true}
                        />
                        {submitted && screenshot === '' && (
                            <span className='invalid-feedback'>{t('uploadFileScreenshotsErrorMsg')}</span>
                        )}
                    </div>

                    <div className='col-12'>
                        {web3Ctx.account ? (
                            <button type='submit' className='btn btn-primary px-5' onClick={handleMediaInputsSubmit}>
                                {t('uploadFile')}
                            </button>
                        ) : (
                            <ConnectWalletHander />
                        )}
                    </div>
                </div>
            </form>
        </>
    );
}

export default CreateDigitalProductForm;
