import React, { useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { RiUser2Line } from 'react-icons/ri';
import { BsCart3 } from 'react-icons/bs';
import { BiHomeSmile } from 'react-icons/bi';
import { MdOutlineLocalShipping } from 'react-icons/md';
import { RiMoneyDollarCircleLine } from 'react-icons/ri';
import Popup from './Popup';
import useUser from '../../hooks/useUser';
import useOrder from '../../hooks/useOrder';
import useWeb3 from '../../hooks/useWeb3';
import { useTranslation } from 'react-i18next';
import { switchNetwork } from '@wagmi/core';
import { appSettings } from '../../helpers/settings';
import ConnectWalletHander from './ConnectWalletHandler';

function MobileNav() {
    const { t } = useTranslation();
    const { userInfo } = useUser();
    const { account } = useWeb3();
    const { allOrders } = useOrder();
    const [connectWalletPopup, setConnectWalletPopup] = useState(false);

    /* --------------------------------------------- 
          CHAHNGE NETWORK HANDLER
    --------------------------------------------- */
    async function changChain() {
        // eslint-disable-next-line no-unused-vars
        const network = await switchNetwork({
            chainId: appSettings.networkId,
        });
    }

    /* --------------------------------------------- 
          GET PENDING ORDERS FOR VENDOR
    --------------------------------------------- */
    const vendorPendingOrders = useMemo(() => {
        return allOrders?.filter((order) => order?.orderVendor === account && order?.orderStatus === 'pending');
    }, [allOrders, account]);

    /* --------------------------------------------- 
          GET PENDING ORDERS FOR BUYER
    --------------------------------------------- */
    const buyerPendingOrders = useMemo(() => {
        return allOrders?.filter((order) => order?.orderBuyerAddress === account && order?.orderStatus === 'shipped');
    }, [allOrders, account]);

    /* -------------------------------------------------------- 
          CALUCALTE THE ORDERS LENGHT FOR BOTH TYPES OF USERS
    -------------------------------------------------------- */
    const totalPendingOrders = useMemo(() => {
        if (userInfo?.is_vendor && vendorPendingOrders?.length > 0) {
            return vendorPendingOrders?.length;
        } else if (!userInfo?.is_vendor && buyerPendingOrders?.length > 0) {
            return buyerPendingOrders?.length;
        } else {
            return 0;
        }
    }, [userInfo, vendorPendingOrders, buyerPendingOrders]);

    return (
        <>
            <div className='mobile-nav'>
                <div className='mobile-nav-holder'>
                    <div className='row row-cols-5 g-0'>
                        <div className='col'>
                            <NavLink className='mobile-nav-link' to='/'>
                                <BiHomeSmile size='1.4rem' className='mb-1' />
                                <p className='mb-0'>{t('home')}</p>
                            </NavLink>
                        </div>
                        <div className='col'>
                            <NavLink className='mobile-nav-link' to='/products'>
                                <BsCart3 size='1.4rem' className='mb-1' />
                                <p className='mb-0'>{t('products')}</p>
                            </NavLink>
                        </div>
                        {account ? (
                            <div className='col'>
                                <NavLink className='mobile-nav-link' to='/account/orders'>
                                    <div className='position-relative d-inline-block'>
                                        <MdOutlineLocalShipping size='1.4rem' className='mb-1' />
                                        <p className='mb-0'>{t('orders')}</p>
                                        <div className='nav-badge'>{totalPendingOrders}</div>
                                    </div>
                                </NavLink>
                            </div>
                        ) : (
                            <div className='col'>
                                <div className='mobile-nav-link' onClick={() => setConnectWalletPopup(true)}>
                                    <div className='position-relative d-inline-block'>
                                        <MdOutlineLocalShipping size='1.4rem' className='mb-1' />
                                        <p className='mb-0'>{t('orders')}</p>
                                        <div className='nav-badge'>{totalPendingOrders}</div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {account ? (
                            <div className='col'>
                                <NavLink className='mobile-nav-link' to='/account/profits'>
                                    <RiMoneyDollarCircleLine size='1.4rem' className='mb-1' />
                                    <p className='mb-0'>{t('profits')}</p>
                                </NavLink>
                            </div>
                        ) : (
                            <div className='col'>
                                <div className='mobile-nav-link' onClick={() => setConnectWalletPopup(true)}>
                                    <RiMoneyDollarCircleLine size='1.4rem' className='mb-1' />
                                    <p className='mb-0'>{t('profits')}</p>
                                </div>
                            </div>
                        )}
                        {account ? (
                            <div className='col'>
                                {userInfo?.is_vendor ? (
                                    <NavLink className='mobile-nav-link' to='/account/shops'>
                                        <RiUser2Line size='1.4rem' className='mb-1' />
                                        <p className='mb-0'>{t('me')}</p>
                                    </NavLink>
                                ) : (
                                    <NavLink className='mobile-nav-link' to='/account/purchases'>
                                        <RiUser2Line size='1.4rem' className='mb-1' />
                                        <p className='mb-0'>{t('me')}</p>
                                    </NavLink>
                                )}
                            </div>
                        ) : (
                            <div className='col'>
                                <div className='mobile-nav-link' onClick={() => setConnectWalletPopup(true)}>
                                    <RiUser2Line size='1.4rem' className='mb-1' />
                                    <p className='mb-0'>{t('me')}</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {connectWalletPopup && !account && (
                <Popup closeModal={() => setConnectWalletPopup(false)}>
                    <div className={`card-body text-center`}>
                        <h2 className='h6 mb-0 ms-2'>
                            {t('appRunOn')}{' '}
                            <span className='text-orange orange text-backline'>{appSettings.activeNetworkName}</span>
                        </h2>
                        <p className='text-muted text-sm'>{t('switchNetwork')}</p>
                        {account ? (
                            <button className='btn btn-primary switch-btn' onClick={changChain}>
                                <img src='/wcbrand.svg' alt='wc' className='me-2' width='20' />
                                {t('switchNetwork')}
                            </button>
                        ) : (
                            <ConnectWalletHander />
                        )}
                    </div>
                </Popup>
            )}
        </>
    );
}

export default MobileNav;
