import React, { useEffect } from 'react';
import PageBanner from '../../components/general/PageBanner';
import { appSettings } from '../../helpers/settings';
import SignUpForm from './SignupForm';
import useUser from '../../hooks/useUser';
import UnAuthorized from '../../components/UnAuthorized';
import useMailer from '../../components/general/Mailer';
import { useTranslation } from 'react-i18next';

function SignUpPage() {
    const { userInfo } = useUser();
    const { sendEmail } = useMailer();
    const { t } = useTranslation();

    /* --------------------------------------------- 
          SEND VERIFICATION EMAIL ON USER REGISTER
    --------------------------------------------- */
    useEffect(() => {
        if (userInfo?.verification_code && !userInfo?.is_verified) {
            sendEmail(
                appSettings?.brandName,
                userInfo?.full_name,
                userInfo?.verification_code,
                userInfo?.email_address
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo?.verification_code]);

    return (
        <>
            {userInfo?.full_name ? (
                <UnAuthorized />
            ) : (
                <>
                    <PageBanner heading='Create an Account' />
                    <section className='py-5'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-9 mx-auto'>
                                    <div className='card'>
                                        <div className='card-body p-sm-5'>
                                            <h2 className='text-center mb-4'>{t('createAccountSubheading')}</h2>
                                            <SignUpForm />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            )}
        </>
    );
}

export default SignUpPage;
