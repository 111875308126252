import React, { useState } from 'react';
import { FiPercent } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { useContractWrite } from 'wagmi';

// HOOKS
import useApp from '../../hooks/useApp';
import { useTranslation } from 'react-i18next';
import useShop from '../../hooks/useShop';
import Web3 from 'web3';

function SetShopVerifyPrice() {
    const { shopVerifyCost, membershipContract, membershipAbi, stToken, loadShopVerifyCost } = useShop();
    const { setTransactionLoading } = useApp();
    const [shopPrice, setShopPrice] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const { t } = useTranslation();

    /* --------------------------------------------- 
              TRANSFER OWNERSHIP FUNCTION
     --------------------------------------------- */
    const { write: setShopVerifyCost } = useContractWrite({
        address: membershipContract?.address,
        abi: membershipAbi,
        functionName: 'setGoldShopCost',
        onSuccess() {
            setTimeout(() => {
                setTransactionLoading(false);
                setSubmitted(false);
                setShopPrice(0);
                loadShopVerifyCost(membershipContract, membershipAbi);
                toast.success(`${t('shopVerifyToast')}`);
            }, 5000);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error(`${t('toastError')}`);
        },
    });

    function setShopOpenCostHandler(e) {
        e.preventDefault();
        setSubmitted(true);
        if (shopPrice !== '' && shopPrice) {
            const formattedPrice = Web3.utils.toWei(shopPrice.toString(), 'ether');
            setShopVerifyCost({
                recklesslySetUnpreparedArgs: [formattedPrice],
            });
        }
    }

    return (
        <div className='card shadow-lg mb-0' data-aos='fade-up' data-aos-delay='200'>
            <div className='card-body p-lg-5'>
                <div className='d-flex mb-5'>
                    <div className='stats-icon solid-turquoise'>
                        <FiPercent size='1.4rem' />
                    </div>
                    <div className='ms-3'>
                        <h2 className='mb-0 h4'>{t('setShopVerificationPrice')}</h2>
                        <p className='text-muted fw-normal mb-0'>
                            {t('currentPriceIs')}{' '}
                            <strong className='fw-bold text-primary'>
                                {shopVerifyCost} {stToken?.symbol}
                            </strong>
                        </p>
                    </div>
                </div>

                <form onSubmit={setShopOpenCostHandler}>
                    <div className='row'>
                        <div className='col-lg-7'>
                            <div className='d-flex align-items-end'>
                                <div className='me-2 w-100'>
                                    <label className='form-label' htmlFor='openShopCost'>
                                        {t('verifyShopPrice')}
                                    </label>
                                    <input
                                        type='number'
                                        id='openShopCost'
                                        className={`form-control ${submitted && shopPrice === '' ? 'is-invalid' : ''}`}
                                        placeholder={t('shopVerifyPlaceholder')}
                                        value={shopPrice}
                                        onChange={(e) => setShopPrice(e.target.value)}
                                    />
                                </div>
                                <button className='btn btn-primary flex-shrink-0' type='submit'>
                                    {t('setShopVerificationPrice')}
                                </button>
                            </div>
                            {submitted && shopPrice === '' && (
                                <div className='invalid-feedback d-block'>{t('shopVerifyErrMsg')}</div>
                            )}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default SetShopVerifyPrice;
