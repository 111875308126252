import React, { useReducer } from 'react';
// import { readContract } from '@wagmi/core';
// import { createSlug } from '../helpers/utils';
// import Web3 from 'web3';

import DigitalFilesContext from './digital-files-context';

const defaultDfState = {
    dfContract: null,
    digitalFiles: [],
    dfAbi: null,
};

const dfReducer = (state, action) => {
    if (action.type === 'CONTRACT') {
        return {
            ...state,
            dfContract: action.dfContract,
        };
    }
    if (action.type === 'GET_ABI') {
        return {
            ...state,
            dfAbi: action.dfAbi,
        };
    }

    return defaultDfState;
};

const DigitalFilesProvider = (props) => {
    const [dfState, dispatchDfAction] = useReducer(dfReducer, defaultDfState);

    const getContractAbiHandler = (dfAbi) => {
        dispatchDfAction({ type: 'GET_ABI', dfAbi: dfAbi });
    };

    const loadContractHandler = (dfContract) => {
        dispatchDfAction({ type: 'CONTRACT', dfContract: dfContract });
        return dfContract;
    };

    const digitalFilesContext = {
        dfContract: dfState.dfContract,
        dfAbi: dfState.dfAbi,
        loadDfContract: loadContractHandler,
        loadDfAbi: getContractAbiHandler,
    };

    return <DigitalFilesContext.Provider value={digitalFilesContext}>{props.children}</DigitalFilesContext.Provider>;
};

export default DigitalFilesProvider;
