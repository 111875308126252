import React, { useState } from 'react';
import { RiExchangeFill } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { useContractWrite } from 'wagmi';

// HOOKS
import useShop from '../../hooks/useShop';
import useApp from '../../hooks/useApp';
import { useTranslation } from 'react-i18next';

function TransferOwnership() {
    const { contract, setTransactionLoading } = useApp();
    const { shopContractAbi } = useShop();
    const [newOwner, setNewOwner] = useState('');
    const [successTransfer, setSuccessTransfer] = useState(false);
    const { t } = useTranslation();

    /*** ------------------------------------------------ */
    //      TRANSFER THE OWNER SHIP OF THE CONTRACT
    /*** ------------------------------------------------ */
    const { write: web3TransferOwnership } = useContractWrite({
        address: contract?.address,
        abi: shopContractAbi,
        functionName: 'transferOwnership',
        onSuccess() {
            setTimeout(() => {
                setTransactionLoading(false);
                toast.success(`${t('ownerShipChanged')}`);
                setSuccessTransfer(true);
                window.location.reload();
            }, 5000);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error(`${t('toastError')}`);
        },
    });

    /* --------------------------------------------- 
          TRIGGERING TRANSFER OWNERSHIP FUNCTION
    --------------------------------------------- */
    function transferOwnershipHandler(e) {
        e.preventDefault();
        if (newOwner.length < 42) {
            toast.error(`${t('invalidAddress')}`);
        } else {
            setTransactionLoading(true);
            web3TransferOwnership({
                recklesslySetUnpreparedArgs: [newOwner],
            });
        }
    }

    return successTransfer ? (
        <div className='card shadow-lg mb-0 p-lg-5 text-center'>
            <div className='card-body'>
                <h1>{t('youNoLongerOwnContract')}</h1>
                <p className='lead text-muted'>{t('youNoLongerOwnContractMsg')}</p>
            </div>
        </div>
    ) : (
        <div className='card shadow-lg mb-0' data-aos='fade-up' data-aos-delay='200'>
            <div className='card-body p-lg-5'>
                <div className='d-flex mb-5'>
                    <div className='stats-icon solid-turquoise'>
                        <RiExchangeFill size='1.4rem' />
                    </div>
                    <div className='ms-3'>
                        <h2 className='mb-0 h4'>{t('transferOwnerShip')}</h2>
                        <p className='text-muted fw-normal mb-0'>
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                        </p>
                    </div>
                </div>

                <form onSubmit={transferOwnershipHandler}>
                    <div className='row'>
                        <div className='col-lg-7'>
                            <div className='d-flex align-items-end'>
                                <div className='me-2 w-100'>
                                    <label className='form-label' htmlFor='newOwner'>
                                        {t('newOwner')}
                                    </label>
                                    <input
                                        type='text'
                                        id='newOwner'
                                        className='form-control'
                                        placeholder='Enter the new owner address'
                                        onChange={(e) => setNewOwner(e.target.value)}
                                    />
                                </div>
                                <button className='btn btn-primary' type='submit'>
                                    {t('transfer')}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default TransferOwnership;
