import React, { useRef, useEffect } from 'react';
import Scrollspy from 'react-scrollspy';
import { FiChevronUp } from 'react-icons/fi';

// COMPOENENTS
import PageBanner from '../../components/general/PageBanner';
import { useTranslation } from 'react-i18next';

function TermsPage() {
    const scrollTopBtnRef = useRef();
    const { t } = useTranslation();

    useEffect(() => {
        function fixingList() {
            if (scrollTopBtnRef.current) {
                if (window.scrollY > 1000) {
                    scrollTopBtnRef.current.classList.add('show');
                } else {
                    scrollTopBtnRef.current.classList.remove('show');
                }
            }
        }

        function scrollPageToTop() {
            window.scrollTo({ top: 0 });
        }

        scrollTopBtnRef.current.addEventListener('click', scrollPageToTop);
        window.addEventListener('scroll', fixingList);

        return () => {
            window.removeEventListener('scroll', fixingList);
        };
    }, []);

    return (
        <>
            <PageBanner heading='Terms & Conditions' text='Last updated May 02, 2023'></PageBanner>
            <div id='scrollTopBtn' ref={scrollTopBtnRef}>
                <FiChevronUp />
            </div>

            <section className='py-5'>
                <div className='container py-5'>
                    <div className='row'>
                        <div className='col-lg-10 mx-auto'>
                            <div className='row g-5'>
                                <div className='col-lg-8 order-2 order-lg-1 content'>
                                    <div className='mb-5' id='term0'>
                                        <h2 className='mb-4'>{t('term0Header')}</h2>
                                        <p>{t('term0Msg1')}</p>
                                        <p>
                                        {t('term0Msg2')}
                                        </p>
                                        <p>{t('term0Msg3')}</p>
                                        <p>
                                        {t('term0Msg4')}
                                        </p>
                                        <p>
                                        {t('term0Msg5')}
                                        </p>
                                        <p>
                                        {t('term0Msg6')}
                                        </p>
                                        <p>{t('term0Msg7')}</p>
                                    </div>
                                    <div className='mb-5' id='term1'>
                                        <h2 className='mb-4'>{t('term1Header')}</h2>
                                        <p>
                                        {t('term1Msg1')}
                                        </p>
                                        <p>
                                        {t('term1Msg2')}
                                        </p>
                                    </div>
                                    <div className='mb-5' id='term2'>
                                        <h2 className='mb-4'>{t('term2Header')}</h2>
                                        <p>{t('term2Msg1')}</p>
                                        <p>
                                        {t('term2Msg2')}
                                        </p>
                                        <p>
                                        {t('term2Msg3')}
                                        </p>
                                        <p>
                                        {t('term2Msg5')}
                                        </p>
                                        <p>{t('term2Msg6')}</p>
                                        <p>
                                        {t('term2Msg7')}
                                        </p>
                                        <p>
                                        {t('term2Msg8')}
                                        </p>
                                        <p>
                                        {t('term2Msg9')}
                                        </p>
                                        <p>
                                        {t('term2Msg10')}
                                        </p>
                                        <p>
                                        {t('term2Msg11')}
                                        </p>
                                        <p>{t('term2Msg12')}</p>
                                        <p>
                                        {t('term2Msg13')}
                                        </p>
                                        <p>
                                        {t('term2Msg14')}
                                        </p>
                                        <p>
                                        {t('term2Msg15')}
                                        </p>
                                        <p>
                                        {t('term2Msg16')}
                                        </p>
                                        <p>
                                        {t('term2Msg17')}
                                        </p>
                                        <p>
                                        {t('term2Msg18')}
                                        </p>
                                        <p>
                                        {t('term2Msg19')}
                                        </p>
                                        <p>
                                        {t('term2Msg20')}
                                        </p>
                                    </div>
                                    <div className='mb-5' id='term3'>
                                        <h2 className='mb-4'>{t('term3Header')}</h2>
                                        <p>
                                        {t('term3Msg1')}
                                        </p>
                                        <p>
                                        {t('term3Msg2')}
                                        </p>
                                    </div>
                                    <div className='mb-5' id='term4'>
                                        <h2 className='mb-4'>{t('term4Header')}</h2>
                                        <p>
                                        {t('term4Msg1')}
                                        </p>
                                    </div>
                                    <div className='mb-5' id='term5'>
                                        <h2 className='mb-4'>{t('term5Header')}</h2>
                                        <p>
                                        {t('term5Msg1')}
                                        </p>
                                    </div>
                                    <div className='mb-5' id='term6'>
                                        <h2 className='mb-4'>{t('term6Header')}</h2>
                                        <p>{t('term6Msg1')}:</p>
                                        <p>
                                        {t('term6Msg2')}
                                        </p>
                                        <p>
                                        {t('term6Msg3')}
                                        </p>
                                        <p>
                                        {t('term6Msg4')}
                                        </p>
                                    </div>
                                    <div className='mb-5' id='term7'>
                                        <h2 className='mb-4'>{t('term7Header')}</h2>
                                        <p>
                                        {t('term7Msg1')}
                                        </p>
                                    </div>
                                    <div className='mb-5' id='term8'>
                                        <h2 className='mb-4'>{t('term8Header')}</h2>
                                        <p>
                                        {t('term8Msg1')}
                                        </p>
                                    </div>
                                    <div className='mb-5' id='term9'>
                                        <h2 className='mb-4'>{t('term9Header')}</h2>
                                        <p>
                                        {t('term9Msg1')}
                                        </p>
                                        <p>{t('term9Msg2')}:</p>
                                        <p>
                                            <li>
                                            {t('term9Msg3')}
                                            </li>
                                            <li>
                                            {t('term9Msg4')}
                                            </li>
                                            <li>
                                            {t('term9Msg5')}
                                            </li>
                                            <li>
                                            {t('term9Msg6')}
                                            </li>
                                            <li>
                                            {t('term9Msg7')}
                                            </li>
                                            <li>
                                            {t('term9Msg8')}
                                            </li>
                                            <li>
                                            {t('term9Msg9')}
                                            </li>
                                            <li>{t('term9Msg10')}</li>
                                            <li>
                                            {t('term9Msg11')}
                                            </li>
                                            <li>
                                            {t('term9Msg12')}
                                            </li>
                                            <li>
                                            {t('term9Msg13')}
                                            </li>
                                            <li>
                                            {t('term9Msg14')}
                                            </li>
                                            <li>
                                            {t('term9Msg15')}
                                            </li>
                                            <li>
                                            {t('term9Msg16')}
                                            </li>
                                            <li>
                                            {t('term9Msg17')}
                                            </li>
                                            <li>
                                            {t('term9Msg18')}
                                            </li>
                                            <li>
                                            {t('term9Msg19')}
                                            </li>
                                            <li>
                                            {t('term9Msg20')}
                                            </li>
                                            <li>
                                            {t('term9Msg21')}
                                            </li>
                                            <li>{t('term9Msg22')}
                                            </li>
                                            <li>{t('term9Msg23')}
                                            </li>
                                            <li>{t('term9Msg24')}
                                            </li>
                                            <li>{t('term9Msg25')}</li>
                                            <li>{t('term9Msg26')}</li>
                                        </p>
                                    </div>
                                    <div className='mb-5' id='term10'>
                                        <h2 className='mb-4'>{t('term10Header')}</h2>
                                        <p>
                                        {t('term10Msg1')}
                                        </p>
                                        <p>
                                            <li>
                                                {t('term10Msg2')}
                                            </li>
                                            <li>
                                                {t('term10Msg3')}
                                            </li>
                                            <li>
                                                {t('term10Msg4')}
                                            </li>
                                            <li>
                                                {t('term10Msg5')}</li>
                                            <li>
                                                {t('term10Msg6')}
                                            </li>
                                            <li>
                                                {t('term10Msg7')}
                                            </li>
                                            <li>
                                                {t('term10Msg8')}
                                            </li>
                                            <li>
                                                {t('term10Msg9')}
                                            </li>
                                            <li>
                                                {t('term10Msg10')}
                                            </li>
                                            <li>
                                                {t('term10Msg11')}
                                            </li>
                                            <li>
                                                {t('term10Msg12')}
                                            </li>
                                            <li>
                                                {t('term10Msg13')}
                                            </li>
                                            <li>
                                                {t('term10Msg14')}
                                            </li>
                                            {t('term10Msg15')}
                                        </p>
                                    </div>
                                    <div className='mb-5' id='term11'>
                                        <h2 className='mb-4'>{t('term11Header')}</h2>
                                        <p>
                                            {t('term11Msg1')}
                                        </p>
                                        <p>
                                            {t('term11Msg2')}
                                        </p>
                                        <p>
                                            {t('term11Msg3')}
                                        </p>
                                        <p>
                                        {t('term11Msg4')}
                                        </p>
                                    </div>
                                    <div className='mb-5' id='term12'>
                                        <h2 className='mb-4'>{t('sortByterm12HeaderPrice')}</h2>
                                        <p>
                                            {t('term12Msg1')}
                                        </p>
                                        <p>
                                            {t('term12Msg2')}
                                        </p>
                                    </div>
                                    <div className='mb-5' id='term13'>
                                        <h2 className='mb-4'>{t('term13Header')}</h2>
                                        <p>{t('term13Msg1')}</p>
                                        <p>
                                        {t('term13Msg2')}
                                        </p>
                                        <p>{t('term13Msg3')}</p>
                                        <p>
                                        {t('term13Msg4')}
                                        </p>
                                    </div>
                                    <div className='mb-5' id='term14'>
                                        <h2 className='mb-4'>{t('term14Header')}</h2>
                                        <p>
                                        {t('term14Msg1')}
                                        </p>
                                    </div>
                                    <div className='mb-5' id='term15'>
                                        <h2 className='mb-4'>{t('term15Header')}</h2>
                                        <p>
                                        {t('term15Msg1')}
                                        </p>
                                    </div>
                                    <div className='mb-5' id='term16'>
                                        <h2 className='mb-4'>{t('term16Header')}</h2>
                                        <p>
                                        {t('term16Msg1')}
                                        </p>
                                    </div>
                                    <div className='mb-5' id='term17'>
                                        <h2 className='mb-4'>{t('term17Header')}</h2>
                                        <p>
                                        {t('term17Msg1')}
                                        </p>
                                    </div>
                                    <div className='mb-5' id='term18'>
                                        <h2 className='mb-4'>{t('term18Header')}</h2>
                                        <p>
                                        {t('term18Msg1')}
                                        </p>
                                        <p>
                                        {t('term18Msg2')}
                                        </p>
                                    </div>
                                    <div className='mb-5' id='term19'>
                                        <h2 className='mb-4'>MODIFICATIONS AND INTERRUPTIONS</h2>
                                        <p>
                                        {t('term19Header')}
                                        </p>
                                        <p>
                                        {t('term19Msg1')}
                                        </p>
                                    </div>
                                    <div className='mb-5' id='term20'>
                                        <h2 className='mb-4'>{t('term20Header')}</h2>
                                        <p>
                                        {t('term20Msg1')}
                                        </p>
                                    </div>
                                    <div className='mb-5' id='term21'>
                                        <h2 className='mb-4'>{t('term21Header')}</h2>
                                        <p>{t('term21Msg1')}</p>
                                        <p>
                                        {t('term21Msg2')}
                                        </p>
                                        <p>{t('term21Msg3')}</p>
                                        <p>
                                        {t('term21Msg4')}
                                        </p>
                                        <p>
                                            {t('term21Msg5')}</p>
                                        <p>
                                            {t('term21Msg6')}
                                        </p>
                                        <p>
                                            {t('term21Msg7')}</p>
                                        <p>
                                            {t('term21Msg8')}
                                        </p>
                                    </div>
                                    <div className='mb-5' id='term22'>
                                        <h2 className='mb-4'>{t('term22Header')}</h2>
                                        <p>
                                        {t('term22Msg1')}
                                        </p>
                                    </div>
                                    <div className='mb-5' id='term23'>
                                        <h2 className='mb-4'>{t('term23Header')}</h2>
                                        <p>
                                        {t('term23Msg1')}
                                        </p>
                                    </div>
                                    <div className='mb-5' id='term24'>
                                        <h2 className='mb-4'>{t('term24Header')}</h2>
                                        <p>
                                        {t('term24Msg1')}
                                        </p>
                                    </div>
                                    <div className='mb-5' id='term25'>
                                        <h2 className='mb-4'>{t('term25Header')}</h2>
                                        <p>
                                        {t('term25Msg1')}
                                        </p>
                                    </div>
                                    <div className='mb-5' id='term26'>
                                        <h2 className='mb-4'>{t('term26Header')}</h2>
                                        <p>
                                        {t('term26Msg1')}
                                        </p>
                                    </div>
                                    <div className='mb-5' id='term27'>
                                        <h2 className='mb-4'>
                                        {t('term27Header')}
                                        </h2>
                                        <p>
                                        {t('term27Msg1')}
                                        </p>
                                    </div>
                                    <div className='mb-5' id='term28'>
                                        <h2 className='mb-4'>{t('term28Header')}</h2>
                                        <p>
                                        {t('term28Msg1')}
                                        </p>
                                    </div>
                                    <div className='mb-5' id='term29'>
                                        <h2 className='mb-4'>{t('term29Header')}</h2>
                                        <p>
                                        {t('term29Msg1')}
                                        </p>
                                    </div>
                                    <div className='mb-5' id='term30'>
                                        <h2 className='mb-4'>{t('term30Header')}</h2>
                                        <p>
                                        {t('term30Msg1')}
                                        </p>
                                        <p>{t('term30Msg2')}</p>
                                        <p>__________</p>
                                        <p>Italy</p>
                                        <p>official@difi.market</p>
                                    </div>
                                </div>
                                <div className='col-lg-4 position-relative order-1 order-lg-2'>
                                    <div>
                                        <h4>Table of Content</h4>
                                        <Scrollspy
                                            items={['term1', 'term2', 'term3', 'term4', 'term5', 'term6']}
                                            currentClassName='active'
                                            style={{ listStyle: 'none' }}
                                            offset={-150}
                                        >
                                            <li className='mb-2'>
                                                <a href='#term1' className='terms-link text-sm'>
                                                {t('term1Header')}
                                                </a>
                                            </li>
                                            <li className='mb-2'>
                                                <a href='#term2' className='terms-link text-sm'>
                                                {t('term2Header')}
                                                </a>
                                            </li>
                                            <li className='mb-2'>
                                                <a href='#term3' className='terms-link text-sm'>
                                                {t('term3Header')}
                                                </a>
                                            </li>
                                            <li className='mb-2'>
                                                <a href='#term4' className='terms-link text-sm'>
                                                {t('term4Header')}
                                                </a>
                                            </li>
                                            <li className='mb-2'>
                                                <a href='#term5' className='terms-link text-sm'>
                                                {t('term5Header')}
                                                </a>
                                            </li>
                                            <li className='mb-2'>
                                                <a href='#term6' className='terms-link text-sm'>
                                                {t('term6Header')}
                                                </a>
                                            </li>
                                            <li className='mb-2'>
                                                <a href='#term7' className='terms-link text-sm'>
                                                {t('term7Header')}
                                                </a>
                                            </li>
                                            <li className='mb-2'>
                                                <a href='#term8' className='terms-link text-sm'>
                                                {t('term8Header')}
                                                </a>
                                            </li>
                                            <li className='mb-2'>
                                                <a href='#term9' className='terms-link text-sm'>
                                                {t('term9Header')}
                                                </a>
                                            </li>
                                            <li className='mb-2'>
                                                <a href='#term10' className='terms-link text-sm'>
                                                {t('term10Header')}
                                                </a>
                                            </li>
                                            <li className='mb-2'>
                                                <a href='#term11' className='terms-link text-sm'>
                                                {t('term11Header')}
                                                </a>
                                            </li>
                                            <li className='mb-2'>
                                                <a href='#term12' className='terms-link text-sm'>
                                                {t('term12Header')}
                                                </a>
                                            </li>
                                            <li className='mb-2'>
                                                <a href='#term13' className='terms-link text-sm'>
                                                {t('term13Header')}
                                                </a>
                                            </li>
                                            <li className='mb-2'>
                                                <a href='#term14' className='terms-link text-sm'>
                                                {t('term14Header')}
                                                </a>
                                            </li>
                                            <li className='mb-2'>
                                                <a href='#term15' className='terms-link text-sm'>
                                                {t('term15Header')}
                                                </a>
                                            </li>
                                            <li className='mb-2'>
                                                <a href='#term16' className='terms-link text-sm'>
                                                {t('term16Header')}
                                                </a>
                                            </li>
                                            <li className='mb-2'>
                                                <a href='#term17' className='terms-link text-sm'>
                                                {t('term17Header')}
                                                </a>
                                            </li>
                                            <li className='mb-2'>
                                                <a href='#term18' className='terms-link text-sm'>
                                                {t('term18Header')}
                                                </a>
                                            </li>
                                            <li className='mb-2'>
                                                <a href='#term19' className='terms-link text-sm'>
                                                MODIFICATIONS AND INTERRUPTIONS
                                                </a>
                                            </li>
                                            <li className='mb-2'>
                                                <a href='#term20' className='terms-link text-sm'>
                                                {t('term20Header')}
                                                </a>
                                            </li>
                                            <li className='mb-2'>
                                                <a href='#term21' className='terms-link text-sm'>
                                                {t('term21Header')}
                                                </a>
                                            </li>
                                            <li className='mb-2'>
                                                <a href='#term22' className='terms-link text-sm'>
                                                {t('term22Header')}
                                                </a>
                                            </li>
                                            <li className='mb-2'>
                                                <a href='#term23' className='terms-link text-sm'>
                                                {t('term23Header')}
                                                </a>
                                            </li>
                                            <li className='mb-2'>
                                                <a href='#term24' className='terms-link text-sm'>
                                                {t('term24Header')}
                                                </a>
                                            </li>
                                            <li className='mb-2'>
                                                <a href='#term25' className='terms-link text-sm'>
                                                {t('term25Header')}
                                                </a>
                                            </li>
                                            <li className='mb-2'>
                                                <a href='#term26' className='terms-link text-sm'>
                                                {t('term26Header')}
                                                </a>
                                            </li>
                                            <li className='mb-2'>
                                                <a href='#term27' className='terms-link text-sm'>
                                                {t('term27Header')}
                                                </a>
                                            </li>
                                            <li className='mb-2'>
                                                <a href='#term28' className='terms-link text-sm'>
                                                {t('term28Header')}
                                                </a>
                                            </li>
                                            <li className='mb-2'>
                                                <a href='#term29' className='terms-link text-sm'>
                                                {t('term29Header')}
                                                </a>
                                            </li>
                                            <li className='mb-2'>
                                                <a href='#term30' className='terms-link text-sm'>
                                                {t('term30Header')}
                                                </a>
                                            </li>
                                        </Scrollspy>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default TermsPage;
