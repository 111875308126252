import React, { useState } from 'react';
import { toast } from 'react-toastify';

// Hooks
import useShop from '../../hooks/useShop';
import useDf from '../../hooks/useDf';
// import useWeb3 from '../../hooks/useWeb3';
import useApp from '../../hooks/useApp';

// COMPONENTS
import PageBanner from '../../components/general/PageBanner';
import AllProductsTable from './AllProductsTable';
import AllShopsTable from './AllShopsTable';
import AllUsersTable from './AllUsersTable';
import AllSellersTable from './AllSellersTable';
import ShopVerifyRequestsTable from './ShopVerifyRequestsTable';
import Popup from '../../components/general/Popup';
import AllOrdersTable from './AllOrdersTable';
import AllOpenDisputes from './AllOpenDisputes';
import AllReslovedDisputes from './AllReslovedDisputes';
import { useContractWrite } from 'wagmi';
import TransferOwnership from './TransferOwnership';
import SetCommissionPrice from './SetCommissionPrice';
import { useTranslation } from 'react-i18next';
import SetPromotionPrice from './SetPromotionPrice';
import SetShopCreationPrice from './SetShopCreationCost';
import SetGoldCheckCost from './SetGoldCheckCost';
import SetShopVerifyPrice from './SetShopVerifyCost';
import AllDigitalFilesTable from './AllDigitalFiles';

function AdminPage() {
    const { contract, setTransactionLoading } = useApp();
    const { shopContractAbi, loadAllProducts, loadAllShops, loadDigitalFiles } = useShop();
    const { dfContract, dfAbi } = useDf();
    const [blockedProductsIds, setBlockedProductsIds] = useState([]);
    const [blockedDfProductsIds, setBlockedDfProductsIds] = useState([]);
    const [blockedShopsIds, setBlockedShopsIds] = useState([]);
    const { t } = useTranslation();

    const [docsRequested, setDocsRequested] = useState(false);
    const [targetDocs, setTargetDocs] = useState(null);

    function handleViewDocs(docs) {
        setDocsRequested(true);
        setTargetDocs(docs);
    }

    /* --------------------------------------------- 
              BLOCK PRODUCT FUNCTION
     --------------------------------------------- */
    const { write: web3BlockProduct } = useContractWrite({
        address: contract?.address,
        abi: shopContractAbi,
        functionName: 'blockProducts',
        onSuccess() {
            setTimeout(() => {
                setTransactionLoading(false);
                loadAllProducts(contract);
                loadAllShops(contract);
                setBlockedProductsIds([]);
                toast.success(`${t('youBlockedProducts')}`);
            }, 5000);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error(`${t('toastError')}`);
        },
    });

    /* --------------------------------------------- 
              BLOCK DIGITAL PRODUCT FUNCTION
     --------------------------------------------- */
    const { write: web3BlockDigitalProduct } = useContractWrite({
        address: dfContract?.address,
        abi: dfAbi,
        functionName: 'blockFiles',
        onSuccess() {
            setTimeout(() => {
                setTransactionLoading(false);
                loadDigitalFiles(dfContract, dfAbi);
                loadAllShops(contract);
                setBlockedDfProductsIds([]);
                toast.success(`${t('youBlockedProducts')}`);
            }, 5000);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error(`${t('toastError')}`);
        },
    });

    /*** --- BLOCK DIGITAL PRODUCT TRIGGER --- */
    function blockDigitalProductsHandler() {
        web3BlockDigitalProduct({
            recklesslySetUnpreparedArgs: [blockedDfProductsIds],
        });
    }

    /*** --- BLOCK PRODUCT TRIGGER --- */
    function blockProductsHandler() {
        web3BlockProduct({
            recklesslySetUnpreparedArgs: [blockedProductsIds],
        });
    }

    /* --------------------------------------------- 
              BLOCK SHOP FUNCTION
     --------------------------------------------- */
    const { write: web3BlockShop } = useContractWrite({
        address: contract?.address,
        abi: shopContractAbi,
        functionName: 'blockShop',
        onSuccess() {
            setTimeout(() => {
                setTransactionLoading(false);
                loadAllShops(contract);
                setBlockedShopsIds([]);
                toast.success(`${t('youBlockedShops')}`);
            }, 5000);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error(`${t('toastError')}`);
        },
    });

    /*** --- BLOCK SHOP TRIGGER ---- */
    function blockShopHandler() {
        web3BlockShop({
            recklesslySetUnpreparedArgs: [blockedShopsIds],
        });
    }

    /* --------------------------------------------- 
              VERIFY SHOP FUNCTION
     --------------------------------------------- */
    const { write: web3VerifyShop } = useContractWrite({
        address: contract?.address,
        abi: shopContractAbi,
        functionName: 'verifyShop',
        onSuccess() {
            setTimeout(() => {
                setTransactionLoading(false);
                loadAllShops(contract);
                toast.success(`${t('youVerifiedShops')}`);
            }, 5000);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error(`${t('toastError')}`);
        },
    });

    /*** ----- VERIFY SHOPS TRIGGER ---- */
    function verifyShopsHandler(id) {
        web3VerifyShop({
            recklesslySetUnpreparedArgs: [id],
        });
    }

    return (
        <>
            <PageBanner heading={t('adminPanel')} text={t('adminPanelPageText')}></PageBanner>
            <section className='py-5'>
                <div className='container py-5'>
                    <div className='mb-5'>
                        <SetGoldCheckCost />
                    </div>
                    <div className='mb-5'>
                        <SetShopCreationPrice />
                    </div>
                    <div className='mb-5'>
                        <SetShopVerifyPrice />
                    </div>
                    <div className='mb-5'>
                        <SetCommissionPrice />
                    </div>
                    <div className='mb-5'>
                        <SetPromotionPrice />
                    </div>
                    <div className='mb-5'>
                        <TransferOwnership />
                    </div>
                    <div className='mb-5'>
                        <ShopVerifyRequestsTable verify={verifyShopsHandler} handleViewDocs={handleViewDocs} />
                    </div>
                    <div className='mb-5'>
                        <AllProductsTable setIds={setBlockedProductsIds} />
                        {blockedProductsIds.length > 0 && (
                            <button className='btn-primary btn w-100 mt-3' onClick={blockProductsHandler}>
                                {t('blockSelected')}
                            </button>
                        )}
                    </div>
                    <div className='mb-5'>
                        <AllDigitalFilesTable setIds={setBlockedDfProductsIds} />
                        {blockedDfProductsIds?.length > 0 && (
                            <button className='btn-primary btn w-100 mt-3' onClick={blockDigitalProductsHandler}>
                                {t('blockSelected')}
                            </button>
                        )}
                    </div>
                    <div className='mb-5'>
                        <AllShopsTable setIds={setBlockedShopsIds} />
                        {blockedShopsIds.length > 0 && (
                            <button className='btn-primary btn w-100 mt-3' onClick={blockShopHandler}>
                                {t('blockSelected')}
                            </button>
                        )}
                    </div>
                    <div className='mb-5'>
                        <AllOrdersTable />
                    </div>
                    <div className='mb-5'>
                        <AllUsersTable />
                    </div>
                    <div className='mb-5'>
                        <AllSellersTable />
                    </div>
                    <div className='mb-5'>
                        <AllOpenDisputes />
                    </div>
                    <div className='mb-5'>
                        <AllReslovedDisputes />
                    </div>
                </div>
            </section>

            {docsRequested && (
                <Popup closeModal={() => setDocsRequested(false)}>
                    <header className='mb-4 text-center'>
                        <h2 className='h1'>{t('shopDocs')}</h2>
                    </header>
                    <div className='row g-2'>
                        <div className='col-lg-6'>
                            <div className='p-3 rounded bg-gray-850'>
                                <h6 className='mb-1'>{t('companyName')}</h6>
                                <p className='mb-0 text-sm'>{targetDocs?.companyName}</p>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='p-3 rounded bg-gray-850'>
                                <h6 className='mb-1'>{t('companyAddress')}</h6>
                                <p className='mb-0 text-sm'>{targetDocs?.companyAddress}</p>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='p-3 rounded bg-gray-850'>
                                <h6 className='mb-1'>{t('phoneNumber')}</h6>
                                <p className='mb-0 text-sm'>{targetDocs?.phoneNumber}</p>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='p-3 rounded bg-gray-850'>
                                <h6 className='mb-1'>{t('emailAddress')}</h6>
                                <p className='mb-0 text-sm'>{targetDocs?.email}</p>
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <div className='p-3 rounded bg-gray-850'>
                                <h6 className='mb-1'>{t('twitterProfile')}</h6>
                                <p className='mb-0 text-sm'>
                                    <a href={`${targetDocs?.twitterProfile}`} target='_blank' rel='noopener noreferrer'>
                                        {targetDocs?.twitterProfile}
                                    </a>
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <div className='p-3 rounded bg-gray-850'>
                                <h6 className='mb-1'>{t('companyCertificate')}</h6>
                                <p className='mb-0 text-sm'>
                                    <a href={`${targetDocs?.certificate}`} target='_blank' rel='noopener noreferrer'>
                                        {targetDocs?.certificate}
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </Popup>
            )}
        </>
    );
}

export default AdminPage;
